import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserDraft, fetchUserDraft } from '../../../store/features/draft/draftSlice'
import MyDraftPost from '../../../components/MyQueue/myDraft/MyDraftPost'
import { addAIPost, addDraftPostId, addOriginalUrl, changeConnectionType, changePostType, clearPostState } from '../../../store/features/post/postSlice'
import { useNavigate } from 'react-router-dom'
import PreviewPost from '../../../components/PostIdea/PreviewPost/PreviewPost'
import { useState } from 'react'
import { getApi } from '../../../api/methods'
import { ColorRing } from 'react-loader-spinner'
import InfiniteScroll from 'react-infinite-scroll-component';
import { IMAGE_URL } from '../../../api/API'

const MyDraftContainer = () => {
    const user = useSelector(state=>state.user)
    const drafts = useSelector(state=>state.drafts)
    const post = useSelector(state=>state.post)
    const { isDark } = useSelector(state=>state.common)
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [dataTotalLength, setDataTotalLength] = useState(0);
    const [ searchItem, setSearchItem ] = useState('')
    const [ filterItem, setFilterItem ] = useState([])
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
  
    // useEffect(()=>{
    //   dispatch(isLoading(true))
    //   dispatch(fetchUserDraft(user?.userData?.linkedinId))
    //   dispatch(isLoading(false))
    // },[])
  
    // edit the draft post so redirect to post-idea page of composer
    const editPost = (item) => {
      const { postId, postContent, connectionType, postType, originalUrl } = item
      dispatch(addAIPost(postContent))
      dispatch(changeConnectionType(connectionType))
      dispatch(addDraftPostId(postId))
      dispatch(changePostType(postType))
      dispatch(addOriginalUrl(originalUrl))
      navigate('/ai-content')
    }
  
    // preview the draft post
    const previewPost = (item) => {
      const { postId, postContent, connectionType, postType, originalUrl } = item
      dispatch(addAIPost(postContent))
      dispatch(changeConnectionType(connectionType))
      dispatch(addDraftPostId(postId))
      dispatch(changePostType(postType))
      dispatch(addOriginalUrl(originalUrl))
    }

    // delete the draft post
    const deleteDraftConfirm = (id) => {
        const confirmation = confirm('Are you sure?');
        if(confirmation){
            dispatch(deleteUserDraft(id))
            setData([])
            setPage(1)
            fetchData()
        } else {
            return
        }
    } 

    // useEffect(()=>{
    //     dispatch(fetchUserDraft(user?.userData?.linkedinId))
    // },[user?.userData?.linkedinId])

    // fetch all the draft posts of accoring pages of 12 items
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await getApi(`draft/draft-posts/${user?.userData?.linkedinId}?page=${page}`);
        const newData = response.data;

        setData((prevData) => [...prevData, ...newData]);
        setIsLoading(false)
        setDataTotalLength(response.totalLength)
        if (newData?.length === 0) {
          setHasMore(false);
          return
        }
        setPage(page + 1);
      } catch (error) {
        setIsLoading(false)
        console.error(error);
      }
    };

    // here stop the infinite scrolling logic implemented here
    useEffect(() => {
      fetchData();
      if(dataTotalLength > 0 && data?.length > 0 && dataTotalLength === data?.length){
        setHasMore(false);
      }
    }, [page]);
  
    // console.log({hasMore,isLoading,dataTotalLength,data});
    return (
      <>
        <div className="row" >
          <div className={ "col-md-12"} style={{overflowY: "scroll", maxHeight:"70vh"}}>

          {
            data?.length === 0 && <h3 className='d-flex justify-content-center align-items-center w-100  mt-5'>No post found!</h3>
          }

          {/* InfiniteScroll implemented here */}
          <InfiniteScroll
              dataLength={data?.length}
              next={fetchData}
              hasMore={hasMore}
              loader={ 
                <div className='d-flex justify-content-center p-3'>
                    {
                      isDark 
                      ? <img className='loading-logo' src={`${IMAGE_URL}/images/short_logo_dark.svg`}/>
                      : <img className='loading-logo' src={`${IMAGE_URL}/images/short_logo_white.svg`}/>
                    }
                    
                  </div>
              }
            >
              <div className='darft-card-containers'>
              {
                   data?.map((draft, i) => (
                    <MyDraftPost key={i} index={i} data={draft} editPost={editPost} previewPost={previewPost} deleteDraftConfirm={deleteDraftConfirm} />
                    ))
                }
              </div>
        </InfiniteScroll>
              {/* {
                data.map((draft,i)=>{
                  return <MyDraftPost key={i} index={i} data={draft} editPost={editPost} previewPost={previewPost} deleteDraftConfirm={deleteDraftConfirm} />
                })
              }
              {
          hasMore && <div className='d-flex justify-content-center' >
            {
              isLoading ? <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['rgba(138, 81, 255, 0.70)', 'rgba(57, 178, 255, 0.70)']}
            /> :
              <i className="fa-solid fa-angles-down" onClick={() => setPage(page + 1)} style={ isDark ? {color:'#000'} : {color:'#fff'} }></i>
            }
            </div>
          } */}
          </div>
          {/* {
            post.postContent && <div className="col-xxl-4 col-lg-5 col-md-6 mt-4">
                <PreviewPost isPreview={true} />
              </div>
          } */}
        </div>
      </>
    )
}

export default MyDraftContainer