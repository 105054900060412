import axios from "axios"

// // local backend API endpoint
// export const BASE_URL = "http://localhost:9090/api/"
// export const IMAGE_URL = "http://localhost:5173"
// export const IMAGE_URL_BACKEND = "http://localhost:9090"
// export const apibase='http://localhost:9090'

// Global backend API endpoint
export const BASE_URL = "https://devapp.growero.io/api/"
export const IMAGE_URL = "https://dev.growero.io"
export const IMAGE_URL_BACKEND = "https://growero-staging.s3.ap-south-1.amazonaws.com"
export const apibase='https://devapp.growero.io'

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
})
