import React, { useEffect, useState } from 'react'
import './style.css'
import DefineTopics from './DefineTopics'
import DescribeYourSelf from './DescribeYourSelf'
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../api/methods'
import { useDispatch } from 'react-redux';
import { addTopics, fetchUserInfo } from '../../store/features/user/userSlice';
import { isLoading } from '../../store/features/common/commonSlice'
import { IMAGE_URL } from '../../api/API';
import { toast } from 'react-toastify';

const First = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isSetNext, setIsSetNext] = useState(false)
  const [getUserTopics, setUserTpoics] = useState({
    loggeduserId: '',
    topics: [],
    userPromtAIProfession: "",
    language: "",
    timeZone: "",
    userAIPromtTopics: null,
    timeSlote: [ "11:00", "16:00" ]
  })
  // console.log('checkdata', getUserTopics);

  // handle the error part of the input boxes
  const handleSaveInfo = () => {
    const errors = {};  // Use a switch case to validate each field
    switch (true) {
      case !getUserTopics.userPromtAIProfession.trim():
        errors.userPromtAIProfession = "Please enter the topics of interest.";
        break;
      case !getUserTopics.language.trim():
        errors.language = "Language is required";
        break;
      case !getUserTopics.userAIPromtTopics.trim():
          errors.timeZone = "User descriptions are required";
          break;
      // case !getUserTopics.timeZone.trim():
      //   errors.timeZone = "Time Zone is required";
      //   break;
      default:
        // No validation errors
        break;
    }

    // If there are validation errors, show an alert and return
    if (Object.keys(errors)?.length > 0) {
      for (const key in errors) {
        toast.error(errors[key]);
      }
      return;
    }
    
    // save the configuration to user database
    postApi('user/create-user-choosen-topics',getUserTopics)
    .then((res)=>{
      // console.log({res});
      dispatch(isLoading(true))
      // dispatch(fetchUserInfo())
      toast.success('Congratulations, account setup complete!')
      navigate('/dashboard')
      dispatch(isLoading(false))


    }).catch((error)=>{
      console.warn('Error in create-user-choosen-topics:',error);
      navigate('/dashboard')
    })
    
    
  }

  // which component will render decide here
  const handleCompo = () => {
    setIsSetNext(!isSetNext)
  }

  return (
    <>
      <div className="popup-container ">
        <img className='mt-1 img-popup-container' src={`${IMAGE_URL}/images/logo.svg`}  height={40} width={250} alt="" />

        {isSetNext ? <DescribeYourSelf click={handleCompo} setUserTpoics={setUserTpoics} getUserTopics={getUserTopics} handleSaveInfo={handleSaveInfo} /> : <DefineTopics click={handleCompo} getUserTopics={getUserTopics} setUserTpoics={setUserTpoics} />}

      </div>
    </>
  )
}

export default First