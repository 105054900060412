import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postState, POST_GENERATION_TYPES } from "./initialState";
import { getApi, postApi } from "../../../api/methods";

const postSlice = createSlice({
  name: "post",
  initialState: postState,
  reducers: {
    // qhen AI generate post according to user input options that response of message is store
    addAIPost: (state, action) => {
      return { ...state, postContent: action.payload };
    },
    // change connection type of share post
    changeConnectionType: (state, action) => {
      return { ...state, connectionType: action.payload };
    },
    //
    changePostSelection: (state, action) => {
      return { ...state, postSelection: action.payload };
    },
    // create a draft post id for saving to draft automatically
    addDraftPostId: (state, action) => {
      return { ...state, draftPostId: action.payload };
    },
    // manupulate the post type like short/ long etc for linkedin body format
    changePostType: (state, action) => {
      return { ...state, postType: action.payload };
    },
    // add external url link to post
    addOriginalUrl: (state, action) => {
      return { ...state, originalUrl: action.payload };
    },
    // add description of media
    addMediaDis: (state, action) => {
      return { ...state, mediaDis: [...state.mediaDis,action.payload] };
    },
    // add title of media
    addMediaTitle: (state, action) => {
      return { ...state, mediaTitle: [...state.mediaTitle,action.payload] };
    },
    // here add media asset that is return from linkedin upload file
    addMedia: (state, action) => {
      return { ...state, media: [...state.media,action.payload] };
    },
    // here all the post stae clear and assign the innitial post
    clearPostState: (state, action) => {
      return postState;
    },
    // here set for preview of the post
    addPostForPreview: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPostGenerationType: (state, action) => {
      return { ...state, postGenerationType: action.payload };
    },
    removeMedia:(state,action)=>{
      state.media.splice(action.payload,1)
    },
    removeOriginalUrl: (state, action) => {
      state.originalUrl.splice(action.payload, 1);
    },
    removeMediaDis: (state, action) => {
      state.mediaDis.splice(action.payload, 1);
    },

    removeMediaTitle: (state, action) => {
      state.mediaTitle.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addMedia,
  addMediaTitle,
  setPostGenerationType,
  addMediaDis,
  addAIPost,
  changeConnectionType,
  changePostSelection,
  addDraftPostId,
  changePostType,
  addOriginalUrl,
  clearPostState,
  addPostForPreview,
  removeMedia,
  removeOriginalUrl,
  removeMediaDis,
  removeMediaTitle, 
} = postSlice.actions;
export default postSlice.reducer;
