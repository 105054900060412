import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPreviewPost,togglePreview } from '../../../store/features/previewPost/previewPostSlice'


const MyDraftPost = (props) => {
  const { isDark } = useSelector(state=>state.common)
  const previewPost = useSelector(state=>state.previewPost)
  const dispatch = useDispatch()

  return (
    <>
        <div className={`queue-post-items ${isDark ? 'normal-bg-color' : 'darkMode queue-text-color-dark'}`}>
            <div className="queue-post-left">
                <div><b>{props?.index + 1}.</b></div> 
                <div className='queue-time'>{[props?.data?.updatedAt?.split('T')[0].split('-')[2],props?.data?.updatedAt?.split('T')[0].split('-')[1],props?.data?.updatedAt?.split('T')[0].split('-')[0]].join('-')}</div>
                <div className='queue-time'>{new Date(props?.data.updatedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</div>
                <div className='queue-content'>{props?.data.postContent?.length > 100 ? props?.data.postContent?.slice(0,100) : props?.data.postContent }</div>
            </div>
            <div className="queue-post-right">
                <i className="fa-regular fa-eye" onClick={()=>{ dispatch(addPreviewPost(props.data)); dispatch(togglePreview(true))}}></i>
                <i className="fa-solid fa-pencil" onClick={()=>props.editPost(props.data)}></i>
                <i className="fa-regular fa-trash-can" style={{color: "#b80a24"}} onClick={()=>props.deleteDraftConfirm(props.data.postId)}></i>
            </div>
        </div>
    </>
  )
}

export default MyDraftPost