import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteApi, postApi } from "../../api/methods";
import { isLoading } from "../../store/features/common/commonSlice";
import { addPostForPreview } from "../../store/features/post/postSlice";
import { useLocation, useNavigate } from "react-router-dom";
import "./PostCard.css";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../api/API";
import {
  addEvergreenData,
  fetchUserEverGreen,
} from "../../store/features/evergreen/evergreenSlice";
import {
  addBookmarkData,
  fetchUserBookmark,
} from "../../store/features/bookmark/bookmarkSlice";
import {
  addPreviewPost,
  togglePreview,
} from "../../store/features/previewPost/previewPostSlice";
import convertCodeToText from "../../utility/convertCodeToText";
import axios from "axios";
import { fetchUserOTGById } from "../../store/features/otg/otgSlice";
import { addDiscoverData } from "../../store/features/discover/discoverSlice";
import { v4 as uuidv4 } from "uuid";
import { POST_GENERATION_TYPES } from "../../store/features/post/initialState";
import { setPostGenerationType } from "../../store/features/post/postSlice";
const PostCard = (props) => {
  const [discoverImage, setDiscoverImage] = useState(
    props.data?.discoverData?.profileImage
      ? props.data?.discoverData?.profileImage
      : props.data?.profileImage
      ? props.data.profileImage
      : `${IMAGE_URL}/images/Icons/avatar.jpg`
  );

  const location = useLocation();
  let pathName = location.pathname;
  // console.log({props});
  const user = useSelector((state) => state.user);
  const previewPost = useSelector((state) => state.previewPost);
  const { isDark } = useSelector((state) => state.common);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post);
  const [modalData, setModalData] = useState(null);
  const [url, setUrl] = useState("");
  const userInfo = user?.userData;
  const [imageError, setImageError] = useState(false);
  const [evergreenStatus, setEvergreenStatus] = useState(
    props.data?.Evergreen
      ? true
      : pathName === "/evergreen" || pathName === "/evergreen/"
      ? true
      : false
  );
  const [bookmarkStatus, setBookmarkStatus] = useState(
    props.data?.Bookmark
      ? true
      : pathName === "/bookmarks" || pathName === "/bookmarks/"
      ? true
      : false
  );

  // Function to handle evergreen status toggle
  // here create a new evergreen post and evergreen empty symbol to fill with color symbol
  useEffect(() => {
    console.log('props:', props);
    if (props.postype === 'ARTICLE') {
      console.log('Setting URL:', props.data.image);
      setUrl(props.image);
    }
  }, [props.postType, props.image]);

  useEffect(() => {
    if (url !== '') {
      fetchMetaData();
    }
    console.log('This is useEffect, url:', url);
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url: url,
      });
      setModalData(response.data);
      console.log('This is fetchMetaData, response.data:', response.data[0]);
      console.log(url);
    } catch (error) {
      console.error('Error fetching metadata:', error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: '', type: 'png' }] },
      });
    }
  };

  const onEvergreenHnadler = async () => {
    // Toggle the evergreen status
    setEvergreenStatus(!evergreenStatus);

    // Check if the post is already marked as evergreen
    if (evergreenStatus) {
      // If marked as evergreen, delete the evergreen mark
      onDeleteHnadler({ evergreenMark: true });
      dispatch(
        fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 })
      );
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new evergreen post
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName
            ? `discover_${uuidv4()}`
            : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
        };

        // Make a request to create the evergreen post
        const response = await postApi("evergreen/create", postBody);
        toast.success("Saved as evergreen!");
        setEvergreenStatus(true);
        dispatch(
          fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 })
        );
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error.response.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setEvergreenStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  // Function to handle bookmark status toggle
  // here create a new bookmark post and bookmark empty symbol to fill with color symbol
  const onBookmarkHnadler = async () => {
    // Toggle the bookmark status
    setBookmarkStatus(!bookmarkStatus);

    // Check if the post is already bookmarked
    if (bookmarkStatus) {
      // If bookmarked, delete the bookmark
      onDeleteHnadler({ bookmarkMark: true });
      dispatch(
        fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 })
      );
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new bookmark
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName
            ? `discover_${uuidv4()}`
            : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
          discoverData: props.data.userName ? props.data : null,
        };

        // Make a request to create the bookmark
        const response = await postApi(
          "book-marks/bookmark-post-create",
          postBody
        );
        toast.success("Saved in Bookmark!");
        setBookmarkStatus(true);
        dispatch(
          fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 })
        );

        if (postBody.postId.split("_")[0] === "discover") {
          // dispatch(addDiscoverData({ data:[], isNew:true }))
        }
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error?.response?.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setBookmarkStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  const onComposeHandler = () => {
    console.log("props.data.postId");
    console.log(props.data.postId);
    console.log("props.data.postId");
    if (!props.data.postId.startsWith("otg_")) {

      console.log("discover post");
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.DISCOVER));
    } else {
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.ON_THE_GO));
    }
    dispatch(addPostForPreview(props.data));
    // deleteApi(`schedule/delete-schedule-post/${props.data._id}`);
    navigate("/ai-content");
  };

  // here if user click evergreen hightlight symbol then evergreen post will be deleted
  // here if user click bookmark hightlight symbol then bookmark post will be deleted
  const onDeleteHnadler = async (mark = {}) => {
    // console.log(props.data, props.isEvergreen, props.isBookmark );
    // if(props?.data?.postId?.split('_')[0] === 'otg' || props?.data?.postId?.split('_')[0] === 'discover'){
    //     toast.info('Please unbookmark from Bookmarks section!')
    //     if( bookmarkStatus ){
    //         setBookmarkStatus(true)
    //     }
    //     if( evergreenStatus ){
    //         setEvergreenStatus(true)
    //     }
    //     return
    // }

    try {
      if (
        bookmarkStatus &&
        (pathName === "/bookmarks" || pathName === "/bookmarks/")
      ) {
        const confirmDelete = confirm("Are you sure?");

        if (bookmarkStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("book-marks/bookmark-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted in Bookmark.");
          props?.setData(response.data);
          setBookmarkStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
          if (props.data.postId.split("_")[0] === "discover") {
            // dispatch(addDiscoverData({ data:[], isNew:true }))
          }
        }
      } else {
        if (bookmarkStatus && mark.bookmarkMark) {
          toast.info("Please unbookmark from Bookmarks section");
          setBookmarkStatus(true);
        }
      }

      if (
        evergreenStatus &&
        (pathName === "/evergreen" || pathName === "/evergreen/")
      ) {
        const confirmDelete = confirm("Are you sure?");

        if (evergreenStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("evergreen/evergreen-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted from Evergreen!");
          props?.setData(response.data);
          setEvergreenStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
        }
      } else {
        if (evergreenStatus && mark.evergreenMark) {
          toast.info("Please remove from Evergreens section");
          setEvergreenStatus(true);
        }
      }
    } catch (error) {
      toast.info("Something went wrong!");
      dispatch(isLoading(false));
      console.warn(error);
    }
  };

  // preview post in global panel
  const previewPostCardHandler = () => {
    // console.log(props.data);
    dispatch(addPreviewPost(props.data));
    dispatch(togglePreview(true));
  };

  // view profile of discover posts only
  const viewProfileHandler = () => {
    let url;
    if (props.data.platform) {
      url = "http://www.twitter.com";
    }

    const urlLink = `${url}/${props.data.userName}`;

    if (props.data?.userName) {
      window.open(urlLink, "_blank").focus();
    }
  };

  useEffect(() => {}, [bookmarkStatus, evergreenStatus]);
  // console.log(props.data);

  // manupulate date and time for post
  let date;
  let time;

  if (props?.data?.date_time) {
    const currentDate = new Date(`${props?.data?.date_time}.000Z`);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (props?.data?.createdAt) {
    const currentDate = new Date(`${props?.data?.createdAt}`);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // date format manupulate
  if (date) {
    if (date?.split("/")[0] === date?.split("/")[0]) {
      const initialDate = date?.split("/");
      // console.log({initialDate});
      date = [initialDate[1], initialDate[0], initialDate[2]]?.join("-");
    } else {
      const initialDate = date?.split("/");
      // console.log({initialDate});
      date = [initialDate[0], initialDate[1], initialDate[2]]?.join("-");
    }
  }

  useEffect(() => {}, [bookmarkStatus, evergreenStatus]);

  //   useEffect(()=>{

  //     if(props.data?.profileImage){
  //         axios.get(props.data?.profileImage)
  //             .then(response=>{
  //                 if (response.status === 200) {
  //                     setDiscoverImage(props.data?.profileImage)
  //                   }
  //             })
  //             .catch(error=>{

  //             })
  //       }

  //   },[props.data?.profileImage])
  // console.log(props.data.originalUrl);

  return (
    <div
      className={`${
        isDark ? "bg-white" : "bg-[#1E1E1E]"
      } m-2 gradientBorderFinal p-3 w-full`}
      key={props.i}
    >
      <div className="w-full">
        <div className="flex max-sm:flex-col justify-between w-full">
          <div
            className="flex gap-2 items-start"
            onClick={viewProfileHandler}
            style={props.data.platform && { cursor: "pointer" }}
          >
            {props.data?.discoverData?.profileImage ? (
              <img
                src={
                  imageError
                    ? `${IMAGE_URL}/images/Icons/avatar.jpg`
                    : props.data?.discoverData?.profileImage ||
                      props.data.profileImage
                }
                onError={() => setImageError(true)}
                className="user-logo me-1 avatar-img"
                style={{ borderRadius: "50%" }}
              />
            ) : props.data.profileImage ? (
              <img
                src={
                  imageError
                    ? `${IMAGE_URL}/images/Icons/avatar.jpg`
                    : props.data?.discoverData?.profileImage ||
                      props.data.profileImage
                }
                onError={() => setImageError(true)}
                className="user-logo me-1 avatar-img"
                style={{ borderRadius: "50%" }}
              />
            ) : user?.userPhoto?.length > 0 ? (
              <img
                src={user?.userPhoto}
                className="user-logo avatar-img"
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                src={`${IMAGE_URL}/images/Icons/avatar.jpg`}
                className="user-logo me-1 avatar-img"
                style={{ borderRadius: "50%" }}
              />
            )}
            <div className="flex flex-col items-start text-sm">
              {props.data.discoverData ? (
                <p style={{ fontSize: "12px" }}>
                  {props?.data?.discoverData?.name?.length > 30
                    ? `${props?.data?.discoverData?.name?.substring(0, 30)}...`
                    : props?.data?.discoverData?.displayName}
                </p>
              ) : props.data?.displayName ? (
                <p style={{ fontSize: "12px" }}>
                  {props?.data?.name?.length > 30
                    ? `${props?.data?.name?.substring(0, 30)}...`
                    : props?.data?.displayName}
                </p>
              ) : (
                <p style={{ fontSize: "12px" }}>
                  {userInfo?.userName?.length > 30
                    ? `${userInfo?.userName?.substring(0, 30)}...`
                    : userInfo?.userName}
                </p>
              )}
              {props?.data?.discoverData ? null : props?.data?.designation
                  ?.length > 0 ? (
                <span style={{ color: "grey", fontSize: "12px" }}>
                  {props?.data?.designation?.length > 30
                    ? props?.data?.designation?.slice(0, 30) + "..."
                    : props?.data?.designation}
                </span>
              ) : (
                <span style={{ color: "gray", fontSize: "12px" }}>
                  {user.userData?.localizedHeadline?.length > 30
                    ? user.userData?.localizedHeadline?.slice(0, 30) + "..."
                    : user.userData?.localizedHeadline}
                </span>
              )}

              {/* TWITTER FOR REMOVING HEADLINE */}
              <span style={{ fontSize: "12px" }}>
                {props.data.platform ? props.data.date_time : `${date} ${time}`}
              </span>
            </div>
          </div>
          <div className="flex max-sm:pl-14 items-start">
            <div className="flex items-center gap-2">
              {
                <span
                  className="gradient-button-new p-1 px-2 text-xs rounded-md text-white"
                  style={{}}
                >
                  {props?.data?.platform ||
                    (props?.data?.postId?.split("_")[0] === "otg"
                      ? "OTG"
                      : props?.data?.postId?.split("_")[0] === "schedule"
                      ? "Scheduled"
                      : props?.data?.postId?.split("_")[0] === "ai"
                      ? "AI Post"
                      : props?.data?.postId?.split("_")[0] === "discover"
                      ? "Discover"
                      : props?.data?.postId?.split("_")[0] === "custom"
                      ? "Custom"
                      : props?.data?.postId?.split("_")[0]
                      ? props?.data?.postId
                          ?.split("_")[0]
                          .charAt(0)
                          .toUpperCase() +
                        props?.data?.postId?.split("_")[0].slice(1)
                      : "Unknown")}
                </span>
              }
              {props?.postURL && (
                <a
                  className="discover-url"
                  href={props?.postURL}
                  target="_blank"
                >
                  <i
                    className="fa-solid fa-link"
                    title="URL"
                    style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                  ></i>
                </a>
              )}

              {props.isDelete && (
                <i
                  className="fa-regular fa-trash-can cursor-pointer"
                  onClick={onDeleteHnadler}
                  title="Delete"
                ></i>
              )}
              {props.isEvergreen &&
                (evergreenStatus ? (
                  <i
                    className="fa-solid fa-star cursor-pointer"
                    style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                    onClick={onEvergreenHnadler}
                    title="Evergreen"
                  ></i>
                ) : (
                  <i
                    className="fa-regular fa-star cursor-pointer"
                    onClick={onEvergreenHnadler}
                    style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                    title="Evergreen"
                  ></i>
                ))}
              {props.isBookmark &&
                (bookmarkStatus ? (
                  <i
                    className="fa-solid fa-bookmark cursor-pointer"
                    style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                    onClick={onBookmarkHnadler}
                    title="Bookmark"
                  ></i>
                ) : (
                  <i
                    className="fa-regular fa-bookmark cursor-pointer"
                    style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                    onClick={onBookmarkHnadler}
                    title="Bookmark"
                  ></i>
                ))}
              <i
                className="fa-regular fa-pen-to-square cursor-pointer"
                title="Repurpose"
                style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                onClick={onComposeHandler}
              ></i>
            </div>
          </div>
        </div>
        <div className="">
          {pathName === "/on-the-go" ? (
            <p className="my-3">{convertCodeToText(props?.des)}</p>
          ) : (
            <p className="my-3">
              {props.image ? (
                <>
                  {convertCodeToText(props?.des)
                    .substring(0, 300)
                    .split("\n")
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}{" "}
                  <span
                    style={{
                      height: "auto",
                      overflowWrap: "break-word",
                      whiteSpace: "pre-line",
                      color: "rgb(0, 123, 255)",
                      cursor: "pointer",
                    }}
                    onClick={() => previewPostCardHandler(props.data)}
                  >
                    ...read more
                  </span>
                </>
              ) : convertCodeToText(props?.des)?.length > 400 ? (
                <>
                  {convertCodeToText(props?.des)
                    .substring(0, 400)
                    .split("\n")
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}{" "}
                  {convertCodeToText(props?.des)?.length > 400 && (
                    <span
                      style={{
                        height: "auto",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-line",
                        color: "rgb(0, 123, 255)",
                        cursor: "pointer",
                      }}
                      onClick={() => previewPostCardHandler(props.data)}
                    >
                      ...read more
                    </span>
                  )}
                </>
              ) : (
                convertCodeToText(props?.des)
                  .substring(0, 400)
                  .split("\n")
                  .map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))
              )}{" "}
            </p>
          )}

          {/* {props.image ? <img src={`${IMAGE_URL_BACKEND}/uploads/${props.image}`} className='w-100' /> : ''} */}
          {props.data.mediaDis && props.postype === "IMAGE" && (
            <div className="bg-[#1e1e1e] border-r-2"> 
            <div 
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginBottom: "30px",
              }}
            >
              {props.data.mediaDis.length === 1 ? (
                // Single image case
                <div  style={{ width: "100%", position: "relative" }}>
                  <img
                    src={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis[0]}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                    alt="Preview Image 1"
                  />
                </div>
              ) : props.data.mediaDis.length === 2 ? (
                // Two images case
                <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                  {props.data.mediaDis.map((url, index) => (
                    <div
                      key={index}
                      style={{ flex: 1, position: "relative" }}
                    >
                      <img
                        src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        alt={`Preview Image ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                // Three or more images case
                <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                  <div style={{ flex: 2, position: "relative" }}>
                    <img
                      src={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis[0]}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      alt="Preview Image 1"
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {props.data.mediaDis.slice(1, 4).map((url, index) => (
                      <div
                        key={index + 1}
                        style={{ flex: 1, position: "relative" }}
                      >
                        <img
                          src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                          alt={`Preview Image ${index + 2}`}
                        />
                        {index === 2 && props.data.mediaDis.length > 4 && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0,0,0,0.5)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              fontSize: "24px",
                              fontWeight: "bold",
                              borderRadius: "10px",
                            }}
                          >
                            +{props.data.mediaDis.length - 4}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            </div>
          )}
          {props.image && props.postype === "ARTICLE" && modalData && (
            <div
              className="card mb-10"
              style={{ margin: "10px 10px 30px 10px", position: "relative" }}
            >
              <p style={{ margin: "4% 4% 8% 3%" }}>
                <a
                  href={props.image}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {props.image}
                </a>{" "}
                <img
                  src={modalData.data.ogImage[0].url}
                  className="card-img-top"
                  style={{ margin: "5% 5% 5% 5%", height: "20%", width: "90%" }}
                  alt="Preview Image"
                />
              </p>
            </div>

          )}
          {props.image && props.postype === "VIDEO" && (
            <video
              src={`${IMAGE_URL_BACKEND}/uploads/${props.image}`}
              controls
            />
          )}
        </div>

        <div className="flex justify-between pt-2">
          {props.data.discoverData ? (
            <>
              <span className="">
                <i
                  className="fa fa-thumbs-up"
                  aria-hidden="true"
                  style={{ color: "#4267B2" }}
                ></i>{" "}
                {props.data.discoverData.likeCount || 0}
              </span>
              <span className="flex gap-2 items-center justify-center">
                <p className="flex gap-1 items-center justify-center">
                  {props.data.discoverData.commentCount || 0}{" "}
                  <i className="fa-regular fa-comment"></i>{" "}
                </p>
                <p className="flex gap-1 items-center justify-center">
                  {props.data.discoverData.repostCount || 0}{" "}
                  <i className="fa-solid fa-retweet"></i>
                </p>
              </span>
            </>
          ) : (
            <>
              <span className="">
                <i
                  className="fa fa-thumbs-up p-2"
                  aria-hidden="true"
                  style={{ color: "#4267B2" }}
                ></i>{" "}
                {props.data.likeCount || 0}
              </span>
              <span className="flex gap-2 items-center justify-center">
                <p className="flex gap-1 items-center justify-center">
                  {props.data.commentCount || 0}{" "}
                  <i className="fa-regular fa-comment"></i>{" "}
                </p>
                <p className="flex gap-1 items-center justify-center">
                  {props.data.repostCount || 0}{" "}
                  <i className="fa-solid fa-retweet"></i>
                </p>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostCard;
