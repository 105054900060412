import { useSelector, useDispatch } from "react-redux";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import {
  reorderData,
  deleteSlideData,
} from "../../../store/features/carousel/action/adjustAction";
import deleteSlideImg from "../../../../src/assets/deleteSlide.svg";
import disabledDeleteImg from "../../../../src/assets/disabledDelete.svg";
import dragIcon from "../../../../src/assets/dragImg.svg";
import disableDragImg from "../../../../src/assets/disableDrag.svg";

function OrderBar() {
  const dispatch = useDispatch();
  const list = useSelector((s) => s.adjustReducer.slides);
  console.log(list);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    dispatch(reorderData(sourceIndex, destinationIndex));
  };

  const handleDeleteSlide = (index) => {
    dispatch(deleteSlideData(index));
  };

  return (
    <>
      <div className="py-6 px-3 flex flex-col gap-3">
        <p className="font-bold">Order</p>
        <div className="flex flex-col w-full gap-2">
          <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
            <Droppable droppableId="slideList">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="w-full flex flex-col gap-3"
                >
                  {list &&
                    list.map((e, ind) => (
                      <Draggable
                        draggableId={String(e.id)}
                        index={ind}
                        key={e.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="border-[1px] flex justify-between items-center border-gray-200 w-[290px] p-2 rounded-lg"
                            style={{
                              ...(provided.draggableProps?.style || {}),
                              opacity: snapshot.isDragging ? 0.5 : 1,
                            }}
                          >
                            <div className="flex gap-3 items-center">
                              <div {...provided.dragHandleProps}>
                                {ind == 0 || ind == list.length - 1 ? (
                                  <></>
                                ) : (
                                  <img src={dragIcon} alt="Drag" />
                                )}
                              </div>
                              <p className="flex gap-3">
                                {ind == 0 ? (
                                  <p className="ml-[22px]">Intro Slide</p>
                                ) : ind == list.length - 1 ? (
                                  <p className="ml-[22px]">Outro Slide</p>
                                ) : (
                                  e.title
                                )}
                              </p>
                            </div>
                            {ind == 0 || ind == list.length - 1 ? (
                              <img
                                src={disabledDeleteImg}
                                className="cursor-not-allowed"
                                alt="Disabled"
                              />
                            ) : (
                              <img
                                src={deleteSlideImg}
                                key={ind}
                                alt="Delete"
                                className="cursor-pointer"
                                onClick={() => handleDeleteSlide(e.id)}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
}

export default OrderBar;
