import React, { useEffect, useState } from 'react'
import './style.css'
import General from '../../components/MyAccount/General';
// import Integration from '../../components/MyAccount/Integration';
import Billing from '../../components/MyAccount/Billing';
import TeamPermissions from '../../components/MyAccount/TeamPermissions';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserInfo, fetchUserTopics } from '../../store/features/user/userSlice';
import AIConfiguration from '../../components/MyAccount/AIConfiguration';

const MyAccount = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const tabNo = searchParams.get('invite')
  const aiConfig = searchParams.get('aiConfig')
  const dispatch = useDispatch()
  const { isDark, layoutShift } = useSelector((state) => state.common)
  const user = useSelector((state) => state.user);

  const [planName, setPlanName] = useState('Free-tier');
  const [facebookId, setFacebookId] = useState(null);
  const filterdata = ['General', 'AI Configuration', 'Billing', 'Team & Permissions']
  // const filterdata = ['General', 'AI Configuration', 'Integration', 'Billing', 'Team & Permissions']

  const [selectedItem, setSelectedItem] = useState(filterdata[tabNo] || filterdata[aiConfig] || filterdata[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    setSelectedItem(filterdata[tabNo] || filterdata[aiConfig] || filterdata[0]);
  }, [tabNo]);

  const getPlanName = async () => {
    try {
      const transactionData = await getApi(
        `transaction/get-subscription-by-id/${user.userData.teamId}`
      );
      setPlanName(transactionData[0].plan_Name);
    } catch (error) {
      console.error("Error fetching plan name:", error);
    }
  }

  const getFacebookId = async () => {
    try {
      const fbUserData = await getApi(`facebook/get-facebook-user?userId=${user.userData.userId}`);
      // console.log(`fbUserData ${fbUserData}`)
      // console.log(fbUserData.data.facebookId)
      // console.log(`fbUserData ${fbUserData}`)
      setFacebookId(fbUserData.data.facebookId);
    } catch (error) {
      console.error("Error fetching Facebook ID:", error);
    }
  }

  useEffect(() => {
    getPlanName();
    getFacebookId();
  }, [user.userData])

  useEffect(() => {
    if (user?.userData?.linkedinId) {
      dispatch(fetchUserInfo(user.userData.linkedinId))
    }
  }, [user?.userData?.linkedinId, dispatch])

  let componentToRender;

  switch (selectedItem) {
    case 'General':
      componentToRender = <General user={user} />;
      break;
    // case 'Integration':
    //   componentToRender = <Integration userId={userId} facebookId={facebookId} />;
    //   break;
    case 'Billing':
      componentToRender = <Billing user={user} />;
      break;
    case 'Team & Permissions':
      componentToRender = <TeamPermissions user={user} />;
      break;
    case 'AI Configuration':
      componentToRender = <AIConfiguration />;
      break;
    default:
      componentToRender = null;
      break;
  }

  return (  
    <>
      <div className={`  py-12 ${layoutShift ? 'pl-24 pr-20' : 'pl-[40px] pr-[40px] xs:px-20'}`}>
        <div className="row">
          <div className="col-12 mb-4">
            <div className='account-item'>
              {filterdata?.map((item, index) => (
                <span
                  key={index}
                  className={`${selectedItem === item ? `isActive-list ${isDark ? 'isActive-list-light' : 'isActive-list-dark'}` : ''} `}
                  onClick={() => handleItemClick(item)}
                  style={{padding:0, paddingBottom:'0.5rem', marginRight:'1.2rem'}}
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
          <div className="col-12">
            {componentToRender}
          </div>
        </div>
      </div>
    </>
  )
}

export default MyAccount