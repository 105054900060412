import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboard } from "./initialState";
import { getApi } from "../../../../api/methods";

// Define async thunks with unique action types
// Get all users for admin
export const fetchTotalUser = createAsyncThunk('dashboard/fetchTotalUser', async (_, thunkAPI) => {
    try {
        const response = await getApi('user/get-all-user');
        return response.data;
    } catch (error) {
        throw error;
    }
});

// Get all published posts for admin
export const fetchTotalPost = createAsyncThunk('dashboard/fetchTotalPost', async (_, thunkAPI) => {
    try {
        const response = await getApi('published/get-all-published-post');
        return response.data;
    } catch (error) {
        throw error;
    }
});

// Get all AI generated posts for admin
export const fetchTotalAIPost = createAsyncThunk('dashboard/fetchTotalAIPost', async (_, thunkAPI) => {
    try {
        const response = await getApi('ai-post/get-all-ai-post');
        return response.data;
    } catch (error) {
        throw error;
    }
});

// get total buy plans transaction details by all users for admin
export const fetchTotalEarning = createAsyncThunk('dashboard/fetchTotaEarning', async (_, thunkAPI) => {
    try {
        const response = await getApi('transaction/get-all-earning');
        return response.data;
    } catch (error) {
        throw error;
    }
});

// fetch all the category of OTG generation
export const fetchOTGCategory = createAsyncThunk('dashboard/fetchotgCategory', async (_, thunkAPI) => {
    try {
        const response = await getApi('otg-category/get-post-category');
        return response.data;
    } catch (error) {
        throw error;
    }
});
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboard,
    reducers: {
        // Your reducer functions if needed
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTotalUser.fulfilled, (state, action) => {
            console.log('User/Dashboard success');
            return { ...state, ToatlUserData: [...action.payload] };
        });
        builder.addCase(fetchTotalUser.rejected, (state, action) => {
            console.warn('User/Dashboard error');
            return { ...state, error: action.error.message };
        });

        builder.addCase(fetchTotalPost.fulfilled, (state, action) => {
            console.log('User/Dashboard Post success');
            return { ...state, ToatlPostData: [...action.payload] };
        });
        builder.addCase(fetchTotalPost.rejected, (state, action) => {
            console.warn('User/Dashboard Post error');
            return { ...state, error: action.error.message };
        });

        builder.addCase(fetchTotalAIPost.fulfilled, (state, action) => {
            console.log('User/Dashboard AI Post success');
            return { ...state, ToatlAIPostData: [...action.payload] };
        });
        builder.addCase(fetchTotalAIPost.rejected, (state, action) => {
            console.warn('User/Dashboard AI Post error');
            return { ...state, error: action.error.message };
        });
        builder.addCase(fetchTotalEarning.fulfilled, (state, action) => {
            console.log('User/Dashboard Earning success');
            return { ...state, ToatlEarningData: [...action.payload] };
        });
        builder.addCase(fetchTotalEarning.rejected, (state, action) => {
            console.warn('User/Dashboard Earning error');
            return { ...state, error: action.error.message };
        });
        builder.addCase(fetchOTGCategory.fulfilled, (state, action) => {
            console.log('User/Dashboard Otg Category success');
            return { ...state, ToatlotgCategoryData: [...action.payload] };
        });
        builder.addCase(fetchOTGCategory.rejected, (state, action) => {
            console.warn('User/Dashboard Otg Category error');
            return { ...state, error: action.error.message };
        });
    },
});

export default dashboardSlice.reducer;
