import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import { BASE_URL, IMAGE_URL } from '../../api/API';
import { Eye, EyeOff } from 'lucide-react';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [passwordCriteria, setPasswordCriteria] = useState({
    lengthCriteria: false,
    upperCaseCriteria: false,
    lowerCaseCriteria: false,
    numberCriteria: false,
    specialCharCriteria: false,
  });

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}userprofile/register`, {
        email,
        password
      });
      const message  = response.data;
      setSuccessMessage('Registration successful. Please verify your email before login.');
      setEmail('');
      setPassword('');
      setError('');
      setPasswordStrength('');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError('User already exists');
      } else {
        setError('Registration failed. Please try again.');
      }
      console.error('Registration error:', error);
    }
  };

  const calculatePasswordStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

    setPasswordCriteria({
      lengthCriteria,
      upperCaseCriteria,
      lowerCaseCriteria,
      numberCriteria,
      specialCharCriteria,
    });

    let strength = '';
    if (lengthCriteria && upperCaseCriteria && lowerCaseCriteria && numberCriteria && specialCharCriteria) {
      strength = 'Strong';
    } else if (lengthCriteria && (upperCaseCriteria || lowerCaseCriteria) && (numberCriteria || specialCharCriteria)) {
      strength = 'Moderate';
    } else {
      strength = 'Weak';
    }

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculatePasswordStrength(newPassword);
  };

  const allCriteriaMet = passwordCriteria.lengthCriteria &&
                        passwordCriteria.upperCaseCriteria &&
                        passwordCriteria.lowerCaseCriteria &&
                        passwordCriteria.numberCriteria &&
                        passwordCriteria.specialCharCriteria;

  return (
    <div className='register-container'>
      <div className="register-box  max-md:pl-10 max-md:pr-10">
        <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
        <h3>Create an Account</h3>
        <form onSubmit={handleRegister}>
          {successMessage ? (
            <>
              <div className="alert alert-success mt-3">{successMessage}</div>
            </>
          ) : (
            <>
              <div className="form-group md:mt-20   ">
                <input
                  type="email"
                  className="form-control "
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  className='absolute right-3 top-2 cursor-pointer'
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <EyeOff />}
                </span>
              </div>
              {password && (
                <div className="bg-white p-4 rounded-xl">
                  <p className='flex content-start text-black text-sm'>Your password must contain:</p>
                  <ul className='text-xs text-gray-600 space-y-1'>
                    <li className={`flex items-center ${passwordCriteria.lengthCriteria ? 'text-green-500' : 'text-gray-500'}`}>
                      At least 8 characters
                    </li>
                    <li className={`flex items-center ${passwordCriteria.upperCaseCriteria ? 'text-green-500' : 'text-gray-500'}`}>
                      Upper case letters (A-Z)
                    </li>
                    <li className={`flex items-center ${passwordCriteria.lowerCaseCriteria ? 'text-green-500' : 'text-gray-500'}`}>
                      Lower case letters (a-z)
                    </li>
                    <li className={`flex items-center ${passwordCriteria.numberCriteria ? 'text-green-500' : 'text-gray-500'}`}>
                      Numbers (0-9)
                    </li>
                    <li className={`flex items-center ${passwordCriteria.specialCharCriteria ? 'text-green-500' : 'text-gray-500'}`}>
                      Special characters (e.g. !@#$%^&*)
                    </li>
                  </ul>
                </div>
              )}
              <button 
                type="submit" 
                className="btn btn-primary grediant-button mt-5" 
                style={{ width: "100%" }} 
                disabled={!allCriteriaMet}
              >
                Sign Up
              </button>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </>
          )}
        </form>
        {!successMessage && (
          <p className="mt-3 max-md:text-[15px]">Already have an account? <a href="/login">Sign In</a></p>
        )}
      </div>
    </div>
  );
};

export default Register;
