import {
  colorPickerSet,
  headShotColorSet,
  headShotSize,
  introOutroHeadShot,
  putHeadShot,
} from "../../../store/features/carousel/action/headShotAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontSizeImg from "../../../../src/assets/fontSize.svg";
import fontSizeWhiteImg from "../../../../src/assets/fontSizeWhite.svg";
import { Switch } from "../../../components/ui/switch";

function HeadShotBar() {
  const {
    headShot,
    introOutro,
    imgSize,
    nameSize,
    handleSize,
    headShotColor,
    colorPicker,
  } = useSelector((s) => s.headShotReducer);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(headShot);
    const introHead = document.getElementById("introOutro");
    if (introHead) {
      introHead.disabled = !headShot;
    }
    if (!headShot) dispatch(introOutroHeadShot(false));
  }, [headShot]);

  useEffect(() => {
    const colorInp = document.getElementById("colorInp");
    const colorPick = document.getElementById("colorPicker");
    if (colorInp && colorPick) {
      colorInp.disabled = !colorPicker;
      colorPick.disabled = !colorPicker;
    }
    // if(!colorPicker){
    // dispatch(headShotColorSet('#22543d'));
    // }
  }, [colorPicker]);

  const handleHeadShotSize = (imageSize, namesSize, handlesSize) => {
    const data = {
      imgSize: imageSize,
      nameSize: namesSize,
      handleSize: handlesSize,
    };
    console.log(data);
    dispatch(headShotSize(data));
  };

  const handleHeadShot = (data) => {
    dispatch(putHeadShot(data));
  };

  const handleIntroOutro = (data) => {
    dispatch(introOutroHeadShot(data));
  };

  const handleHeadShotColor = (data) => {
    dispatch(headShotColorSet(data));
  };
  const handleColorPicker = (data) => {
    dispatch(colorPickerSet(data));
  };
  return (
    <>
      <div className="py-6 px-3 flex flex-col gap-4">
        <p className="font-bold">Headshot</p>
        <div className="flex flex-col w-full gap-3">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            CONFIGURATIONS
          </label>
          <div className="flex flex-col gap-4 text-sm font-semibold">
            <div className="flex gap-2 items-center">
              {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={headShot}
                  onChange={()=>handleHeadShot(!headShot)}
                />
                <span class="slider round"></span>
              </label> */}
              <Switch
                checked={headShot}
                onCheckedChange={() => handleHeadShot(!headShot)}
                className="bg-blue-500 data-[state=checked]:bg-blue-500"
              />
              <p>Show Headshot</p>
            </div>
            <div className="flex gap-2 items-center">
              {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={introOutro}
                  onChange={()=>handleIntroOutro(!introOutro)}
                  id='introOutro'
                />
                <span class="slider round"></span>
              </label> */}
              <Switch
                checked={introOutro}
                onCheckedChange={() => handleIntroOutro(!introOutro)}
                className="bg-blue-500 data-[state=checked]:bg-blue-500"
                id="introOutro"
              />
              <p style={{ color: isDark ? "black" : "white" }}>
                Only Intro and Outro slide
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 border-[1px] border-gray-200 rounded-lg w-[290px] px-2 pt-3 h-[128px]">
          <div className="flex items-center justify-between rounded-lg px-1">
            <p className="text-sm font-semibold">Custom Colors</p>
            {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={colorPicker}
                  onChange={()=>handleColorPicker(!colorPicker)}
                  id='introOutro'
                />
                <span class="slider round"></span>
              </label> */}
            <Switch
              checked={colorPicker}
              onCheckedChange={() => handleColorPicker(!colorPicker)}
              className="bg-blue-500 data-[state=checked]:bg-blue-500"
              id="introOutro"
            />
          </div>
          <div className="relative -mb-3 mt-2">
            <input
              type="text"
              className={` w-full border rounded-md px-3 py-2 text-sm`}
              value={headShotColor}
              onChange={(e) => handleHeadShotColor(e.target.value)}
              id="colorInp"
              placeholder="Enter Hex Code"
              style={{
                background: "transparent",
                color: isDark ? "black" : "white",
              }}
            ></input>
            <div className="relative -top-[32px] left-[200px]">
              <input
                type="color"
                id="colorPicker"
                className="h-[20px] mt-1 cursor-pointer"
                value={headShotColor}
                onChange={(e) => handleHeadShotColor(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold">HeadShot Size</p>
          <div className="flex gap-3">
            <div
              className={`border-[1px] tooltipClass rounded-md ${
                imgSize == "36px" && nameSize == "sm" && handleSize == "xs"
                  ? isDark
                    ? "border-black"
                    : "border-gray-200"
                  : isDark
                  ? "border-gray-200"
                  : "border-black"
              }  p-2 flex items-center justify-center cursor-pointer`}
              title="small"
              onClick={() => handleHeadShotSize("36px", "sm", "xs")}
            >
              <img
                src={isDark ? fontSizeImg : fontSizeWhiteImg}
                width="16px"
              ></img>
            </div>
            <div
              className={`border-[1px] tooltipClass rounded-md ${
                imgSize == "39px" && nameSize == "base" && handleSize == "sm"
                  ? isDark
                    ? "border-black"
                    : "border-gray-200"
                  : isDark
                  ? "border-gray-200"
                  : "border-black"
              }  p-2 flex items-center justify-center cursor-pointer`}
              title="medium"
              onClick={() => handleHeadShotSize("39px", "base", "sm")}
            >
              <img
                src={isDark ? fontSizeImg : fontSizeWhiteImg}
                width="18px"
              ></img>
            </div>
            <div
              className={`border-[1px] tooltipClass rounded-md ${
                imgSize == "41px" && nameSize == "lg" && handleSize == "base"
                  ? isDark
                    ? "border-black"
                    : "border-gray-200"
                  : isDark
                  ? "border-gray-200"
                  : "border-black"
              } p-2 flex items-center justify-center cursor-pointer`}
              title="large"
              onClick={() => handleHeadShotSize("41px", "lg", "base")}
            >
              <img
                src={isDark ? fontSizeImg : fontSizeWhiteImg}
                width="20px"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeadShotBar;
