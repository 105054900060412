import { templates } from "../../../../lib/templates";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  slides: [
    {
      id: 1,
      title: "Your amazing Catchy Title Goes Right Here!",
      subtitle: "Your amazing subtitles goes here",
      description: "Your amazing description goes here",
      hasImageUpload: false,
      swipe: true,
      bookmark: false,
      showSubtitle: true,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    },
    {
      id: 2,
      title: "Section Title",
      description: "Put your content here",
      hasImageUpload: false,
      swipe: false,
      bookmark: false,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    },
    {
      id: 3,
      title: "Section Title",
      description: "Put your content here",
      hasImageUpload: false,
      swipe: false,
      bookmark: false,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    },
    {
      id: 4,
      title: "Section Title",
      description: "Put your content here",
      hasImageUpload: false,
      swipe: false,
      bookmark: false,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    },
    {
      id: 5,
      title: "Your amazing ending note goes here!",
      subtitle: "Your amazing subtitles goes here",
      swipe: false,
      hasImageUpload: false,
      bookmark: true,
      showSubtitle: true,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    },
  ],
};

const adjustReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADJUST_SUBTITLE": {
      const { index, subtitle } = action.payload;
      const updatedSlides = state.slides.map((slide, i) => {
        if (i === index) {
          return {
            ...slide,
            showSubtitle: subtitle,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "RESET_SLIDES":
      return {
        ...state,
        slides: initialState.slides
      }
    case "ADJUST_TITLE": {
      const { index, title } = action.payload;
      const updatedSlides = state.slides.map((slide, i) => {
        if (i === index) {
          return {
            ...slide,
            showTitle: title,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "SET_ACTIVE_CAROUSEL": {
      return {
        ...state,
        slides: action.payload.slides,
        activeCarousel: action.payload
      };
    }
    case "ADJUST_DESC": {
      const { index, desc } = action.payload;
      const updatedSlides = state.slides.map((slide, i) => {
        if (i === index) {
          return {
            ...slide,
            showDesc: desc,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "ADJUST_IMAGE": {
      const { index, image } = action.payload;
      const updatedSlides = state.slides.map((slide, i) => {
        if (i === index) {
          return {
            ...slide,
            hasImageUpload: image,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "ADD_SLIDE": {
      const { index, slide } = action.payload;
      const updatedSlides = [
        ...state.slides.slice(0, index + 1),
        slide,
        ...state.slides.slice(index + 1),
      ];
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "DUPLICATE_SLIDE": {
      const { index, slide } = action.payload;
      const newSlideId = uuidv4();
      const duplicatedSlide = { ...slide, id: newSlideId };
      const updatedSlides = [
        ...state.slides.slice(0, index + 1),
        duplicatedSlide,
        ...state.slides.slice(index + 1),
      ];
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "DELETE_SLIDE": {
      const idToDelete = action.payload;
      const indexToDelete = state.slides.findIndex(
        (slide) => slide.id === idToDelete
      );
      if (indexToDelete !== -1) {
        const newSlides = [
          ...state.slides.slice(0, indexToDelete),
          ...state.slides.slice(indexToDelete + 1),
        ];
        return { ...state, slides: newSlides };
      } else {
        return state;
      }
    }
    case "BACKGROUND_IMAGE": {
      const { id, backgroundImage } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, backGroundImg: backgroundImage, bgAllSet: false };
        } else {
          return { ...slide, bgAllSet: false };
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "BACKGROUND_ALL_IMAGE": {
      const { bgImage, opacity } = action.payload;
      console.log(bgImage);
      const updatedSlides = state.slides.map((slide) => {
        slide.prevBg = slide.backGroundImg;
        return {
          ...slide,
          backGroundImg: bgImage,
          bgImgOpacity: opacity,
          bgAllSet: true,
        };
      });
      return { ...state, slides: updatedSlides };
    }
    case "BACKGROUND_ALL_SET": {
      const { id, bgAllSet } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, bgAllSet: bgAllSet };
        } else {
          return slide;
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "REVERT_BACKGROUND_IMAGE": {
      const updatedSlides = state.slides.map((slide) => {
        return { ...slide, backGroundImg: slide.prevBg, bgAllSet: false };
      });
      return { ...state, slides: updatedSlides };
    }
    case "BACKGROUND_IMAGE_OPACITY": {
      const { id, opacity } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            bgImgOpacity: opacity,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "BACKGROUND_IMAGE_SIZE": {
      const { id, size } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            bgImgSize: size,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "BACKGROUND_IMAGE_POSITION": {
      const { id, position } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            bgImgPosition: position,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "BACKGROUND_IMAGE_REMOVE": {
      const id = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            backGroundImg: "",
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "IMAGE_SET": {
      const { id, img } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, img: img };
        } else {
          return slide;
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "IMAGE_SIZE": {
      const { id, size } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            imgSize: size,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "IMAGE_POSITION": {
      const { id, position } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return {
            ...slide,
            imgPosition: position,
          };
        } else {
          return slide;
        }
      });
      return {
        ...state,
        slides: updatedSlides,
      };
    }
    case "REORDER_SLIDES": {
      const { sourceIndex, destinationIndex } = action.payload;
      console.log(sourceIndex, destinationIndex);
      if (
        sourceIndex === 0 ||
        destinationIndex === 0 ||
        sourceIndex === state.slides.length - 1 ||
        destinationIndex === state.slides.length - 1
      ) {
        return state;
      }

      const newSlide = [...state.slides];
      const [reorderedItem] = newSlide.splice(sourceIndex, 1);
      newSlide.splice(destinationIndex, 0, reorderedItem);

      return {
        ...state,
        slides: newSlide,
      };
    }
    case "CHANGE_SUBTITLE": {
      const { id, subtitle } = action.payload;
      console.log(subtitle);
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, subtitle: subtitle };
        } else {
          return slide;
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "CHANGE_TITLE": {
      const { id, title } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, title: title };
        } else {
          return slide;
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "CHANGE_DESC": {
      const { id, desc } = action.payload;
      const updatedSlides = state.slides.map((slide) => {
        if (slide.id === id) {
          return { ...slide, description: desc };
        } else {
          return slide;
        }
      });
      return { ...state, slides: updatedSlides };
    }
    case "RESET_ADJUST": {
      return initialState;
    }
    case "AI_TOPIC": {
      console.log(action.payload.data.carousel.slides);
      if (action.payload.data) {
        const slidesWithIds = action.payload.data.carousel.slides.map(
          (slide) => ({
            ...slide,
            id: uuidv4(),
          })
        );
        return {
          ...state,
          slides: slidesWithIds,
        };
      } else {
        return state;
      }
    }
    case "AI_TEXT": {
      console.log(action.payload.data.carousel.slides);
      if (action.payload.data) {
        const slidesWithIds = action.payload.data.carousel.slides.map(
          (slide) => ({
            ...slide,
            id: uuidv4(),
          })
        );
        return {
          ...state,
          slides: slidesWithIds,
        };
      } else {
        return state;
      }
    }
    case "AI_ARTICLE": {
      console.log(action.payload.data.carousel.slides);
      if (action.payload.data) {
        const slidesWithIds = action.payload.data.carousel.slides.map(
          (slide) => ({
            ...slide,
            id: uuidv4(),
          })
        );
        return {
          ...state,
          slides: slidesWithIds,
        };
      } else {
        return state;
      }
    }
    case "redux-persist/REHYDRATE": {
      return { ...state, ...action.payload.adjustReducer };
    }
    default:
      return state;
  }
};

export default adjustReducer;
