export const layoutChange = (data) => {
  return (dispatch) => {
    dispatch({ type: "LAYOUT_CHANGE", payload: { data } });
  };
};

export const patternChange = (data) => {
  return (dispatch) => {
    dispatch({ type: "PATTERN_CHANGE", payload: { data } });
  };
};

export const bgOpacityChange = (data) => {
  return (dispatch) => {
    dispatch({ type: "OPACITY_BG_CHANGE", payload: { data } });
  };
};

export const bgColorChange = (data) => {
  return (dispatch) => {
    dispatch({ type: "BG_COLOR_CHANGE", payload: { data } });
  };
};

export const boxColorChange = (data) => {
  return (dispatch) => {
    dispatch({ type: "BOX_COLOR_CHANGE", payload: { data } });
  };
};

export const bgColorPickerSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "BACKGROUND_COLORPICKER", payload: { data } });
  };
};

export const setAspectRatio = (data) => {
  return (dispatch) => {
    dispatch({ type: "ASPECT_RATIO", payload: { data } });
  };
};
export const resetCanava = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_CANVA",
    });
  };
};

export const TemplateCanava = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CANVA",
      payload: id,
    });
  };
};