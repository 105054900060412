import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { templates } from "../../../lib/templates";
import { TemplateCanava } from "../../../store/features/carousel/action/canvaAction";
import { TemplateHeadshot } from "../../../store/features/carousel/action/headShotAction";
import { TemplateSettings } from "../../../store/features/carousel/action/settingsAction";
import { templateText } from "../../../store/features/carousel/action/textAction";
import { changeActiveTemplate } from "../../../store/features/common/commonSlice";

function TemplateBar() {
  const dispatch = useDispatch();
  const { isDark, defaultTemplate} = useSelector(state => state.common);
  const imageMap = {
    1: "/images/Template1.jpg",
    2: "/images/Template2.jpg",
    3: "/images/Template3.jpg",
    4: "/images/Template4.jpg",
    5: "/images/Template5.jpg",
    6: "/images/Template6.jpg",
    7: "/images/Template7.jpg",
    8: "/images/Template8.jpg",
    9: "/images/Template9.jpg",
    10: "/images/Template10.jpg",
    11: "/images/Template11.jpg",
    12: "/images/Template12.jpg",
    13: "/images/Template13.jpg",
    14: "/images/Template14.jpg",
    15: "/images/Template15.jpg",
    16: "/images/Template16.jpg",
  };

  function handleTemplate(id) {
    dispatch(changeActiveTemplate(id));
    dispatch(TemplateCanava(id));
    dispatch(TemplateHeadshot(id));
    dispatch(TemplateSettings(id));
    dispatch(templateText(id));
  }
  return (
    <>
      <div className="pt-6 pb-36 px-3 flex flex-col gap-3">
        <p className="font-bold" style={{ color: isDark ? "black" : "white" }}>
          Templates
        </p>
        {templates.map((t) => (
          <div
            key={t.id}
            className={`p-[1px] w-[290px] cursor-pointer rounded-lg ${defaultTemplate == t.id && "border-red-200 border-[3px]"}`}
            onClick={() => handleTemplate(t.id)}
          >
            <img src={imageMap[t.id]} className="rounded-lg" />
          </div>
        ))}
      </div>
    </>
  );
}

export default TemplateBar;
