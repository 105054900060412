import React, { useEffect, useState } from 'react'
import FlagSelect from 'react-flags-select';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import TimezoneSelect from 'react-timezone-select';
import { getApi, postApi } from '../../api/methods';
import { isLoading } from '../../store/features/common/commonSlice';
import { addTopics, fetchUserInfo } from '../../store/features/user/userSlice';
import Select from 'react-select';
import { toast } from 'react-toastify';

const AIConfiguration = () => {
  const { isDark } = useSelector(state => state.common)
    const { userData,userChosenTopics } = useSelector(state=>state.user)
    const [getProfess, setGetProfess] = useState( userChosenTopics.userPromtAIProfession || '')
  const [selectedOptions, setSelectedOptions] = useState(userChosenTopics.userAIPromtTopics || '');
  const [selectedCountry, setSelectedCountry] = useState(userChosenTopics.language || '');
  const [ selectedTopics, setSelectedTopics ] = useState(userChosenTopics.topics || [])
  const [isUpdate, setIsUpdate] = useState(false)
  const [getPostCate, setGetPostCate] = useState([])
  const dispatch = useDispatch()
  // console.log({ selectedTopics });

  // customStyles defines custom styles for the dropdown in CreatableSelect library.
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 'unset', // Adjust this value based on your needs
    }),
    menu: (provided, state) => ({
      ...provided,
      height: 'auto', // Set the maximum height of the dropdown
      overflowY: 'auto',
    }),
  };

  // handleSelectChange handles changes in selected options.
  const handleSelectChange = (newValue) => {
    const stringValues = newValue?.map(option => option.value);
    // console.log({stringValues});
    setSelectedOptions(stringValues);
  };

  // handleSelect handles selection of a country and sets isUpdate to true.
  const handleSelect = (country) => {
    setIsUpdate(true);
    setSelectedCountry(country);
  };

  // formatLabel formats the label for a country.
  const formatLabel = (country) => {
    return `${country.flag} ${country.label} (${country.code})`;
  };

  // handleCancelUser resets the form to the previous state.
  const handleCancelUser = () => {
      setIsUpdate(false)
      setGetProfess(userChosenTopics.userPromtAIProfession)
      setSelectedOptions(userChosenTopics.userAIPromtTopics)
      setSelectedCountry(userChosenTopics.language)
      setSelectedTopics(userChosenTopics.topics)
    }

    // handleUpdateUser handles updating the user information.
    const handleUpdateUser = () => {

      if(selectedTopics?.length > 5){
        toast.warning('Choose category upto 5 only!')
        return
      }
      
      // find any error of input feild handler
      const errors = {};  // Use a switch case to validate each field
        switch (true) {
            case !getProfess.trim():
                errors.getProfess = "Please enter the topics of interest.";
                break;
            case !selectedCountry.trim():
                errors.selectedCountry = "Language is required";
                break;
            case !selectedOptions.trim():
                errors.selectedOptions = "User descriptions are required";
                break;
            default:
            // No validation errors
            break;
        }

        // If there are validation errors, show an alert and return
        if (Object.keys(errors)?.length > 0) {
            for (const key in errors) {
                toast.error(errors[key]);
            }
            return;
        }

        const postData = {
            loggeduserId: userData.linkedinId,
            userPromtAIProfession: getProfess,
            userAIPromtTopics: selectedOptions,
            language: selectedCountry,
            topics: selectedTopics
        }
        // console.log({postData});
        
        // save the configuration of user in database
        postApi('user/create-user-choosen-topics',postData)
        .then((res)=>{
            // console.log({res});
            dispatch(isLoading(true))
            // dispatch(fetchUserInfo())
            dispatch(isLoading(false))
            setIsUpdate(false)
        }).catch((error)=>{
            console.warn('Error in create-user-choosen-topics:',error);
        })
        
    }

    // handleSelectCategoryChange handles changes in selected categories.
    const handleSelectCategoryChange = (newValue) => {
        // console.log({newValue});
        const arrayValues = newValue?.map(option => option.value);
        setSelectedTopics(arrayValues);
        dispatch(addTopics(arrayValues))
        setIsUpdate(true)
      };

      // getPostCategory fetches post categories.
      const getPostCategory = () => {
        getApi(`otg-category/get-post-category`)
          .then((res) => {
            setGetPostCate(res.data)
          }).catch((err) => {
            console.warn(err);
          })
      }

      // options is an array of options for the category dropdown.
      const options = getPostCate?.map(item=>{
        return { value: item.name, label: item.name }
      })

      useEffect(() => {
        getPostCategory()
      }, [])

  return (
    <>
      <div className="container-margin-yourSelf mt-2">
            <h3 className='mb-4 text-xl'>Configure your personal AI</h3>
        <div className="row">
            <div className="col-12">
            <p  className='mb-1 flex items-center gap-2' >I am a<i class="fa fa-info-circle" style={{fontSize:'16px' ,color:isDark?'black':'white'}}></i></p>
            <input type="text" className='form-control mb-3' name='getProfess' value={getProfess} onChange={(e) =>{setIsUpdate(true); setGetProfess(e.target.value)}} placeholder='Who are you...' />
            <p className='mb-1 flex items-center gap-2'>I usually post about<i class="fa fa-info-circle" style={{fontSize:'16px' ,color:isDark?'black':'white'}}></i></p>
            <textarea className='form-control' placeholder='Description' value={selectedOptions} onChange={e=>{setIsUpdate(true); setSelectedOptions(e.target.value)}}  />
            <p className='mb-1 mt-2'>Category</p>
            <CreatableSelect
                isMulti
                value={selectedTopics?.map(value => ({ label: value, value }))}
                onChange={handleSelectCategoryChange}
                className='mb-3'
                options={options}
                styles={customStyles}
            />
            <p className='mb-1 mt-2 flex items-center gap-2'>I write in<i class="fa fa-info-circle" style={{fontSize:'16px' ,color:isDark?'black':'white'}}></i></p>
              <FlagSelect
                onSelect={handleSelect}
                selected={selectedCountry}
                showSelectedLabel={true}
                // countries={['US', 'FR', 'ES', 'DE']} // Add more country codes as needed
                countries={['US']} // Add more country codes as needed
                customLabels={{
                  US: 'English (US)',
                  // FR: 'French (FR)',
                  // ES: 'Spanish (ES)',
                  // DE: 'German (DE)',
                }}
                customLabelsCallback={(code) => `Flag ${code}`} // Add custom label format
                formatLabel={formatLabel} // Customize the label format
                className='mb-3 language-input'
                placeholder= 'Select a Language'
                />
            
            {isUpdate &&
                <>
                    <div className='upDate-btn'>
                        <button onClick={handleUpdateUser} >Update</button>
                        <button onClick={handleCancelUser}>Cancel</button>
                    </div>
                </>
            }
          </div>
          
        </div>
      </div>
    </>
  )
}

export default AIConfiguration