import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "../../store/features/common/commonSlice";
import PostCard from "../../components/PostCard/PostCard";
import { fetchUserEverGreen } from "../../store/features/evergreen/evergreenSlice";
import { useState } from "react";
import { getApi } from "../../api/methods";
import { ColorRing } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Evergreen.css";
import PreviewPost from "../../components/PostIdea/PreviewPost/PreviewPost";
import { clearPostState } from "../../store/features/post/postSlice";
import { IMAGE_URL } from "../../api/API";
import { Input } from "../../components/ui/input";
import searchImg from "../../assets/searchImg.svg";
import searchImgWhite from "../../assets/searchImgWhite.svg";

const Evergreen = () => {
  const user = useSelector((state) => state.user);
  const evergreen = useSelector((state) => state.evergreen);
  const post = useSelector((state) => state.post);
  const dispatch = useDispatch();
  const { isDark, loading, layoutShift } = useSelector((state) => state.common);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTotalLength, setDataTotalLength] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filterItem, setFilterItem] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // console.log({evergreen});
  // fetch all evergreen posts of user as per page
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `evergreen/posts/${user?.userData?.linkedinId}?page=${page}`
      );
      const newData = response.data;
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
      setDataTotalLength(response.totalLength);
      if (
        newData?.length === response.totalLength ||
        data?.length === response.totalLength ||
        newData?.length === 0
      ) {
        setHasMore(false);
        return;
      } else {
        setHasMore(true);
      }
      setPage(page + 1);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  //   if(dataTotalLength > 0 && data.length > 0 && dataTotalLength === data.length){
  //     setHasMore(false);
  //   }
  // }, [page]);

  // search functionality
  const searchHandler = () => {
    const filterItems = data.filter((item) =>
      item.postContent?.toLowerCase().includes(searchItem.toLowerCase())
    );

    if (searchItem) {
      setFilterItem(filterItems);
    } else {
      setFilterItem([]);
    }
  };

  useEffect(() => {
    searchHandler();
  }, [searchItem]);

  useEffect(() => {
    // dispatch(clearPostState())
    setHasMore(true);
    if (user.userData.linkedinId) {
      fetchData();
    }
  }, [user.userData.linkedinId]);

  return (
    <div
      className={`flex flex-col gap-4 py-12 ${
        layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"
      }`}
    >
      <div className="flex flex-col gap-4">
        <div className="sm:flex justify-between items-center" 
          style={{paddingLeft:isMobile? '15%': '0%'}}        
        > 
          <h3 className="text-sm sm:text-xl">
            Stay Active even if you are away
          </h3>
          <div className="d-flex sm:justify-content-center align-items-center">
            <Input
              type="text"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              placeholder="Search"
              className="p-3 w-64"
              style={{
                background: "transparent",
                color: isDark ? "black" : "white",
                borderRadius: "6px",
              }}
            />
            <button className="text-white relative -left-6">
              <img src={isDark ? searchImg : searchImgWhite}></img>
            </button>
        </div>
</div>
        {data?.length === 0 && (
          <div className="evergreen-heading">
            <ul>
              <li>
                Evergreen posts will help your account stay active even if you
                are away.
              </li>
              <li>
                All your posts marked as Evergreen will be reposted
                automatically.
              </li>
              <li>
                Empty slots will be used as Evergreen slots, and a random
                evergreen post will be reposted.
              </li>
              <li>
                Feel free to visit History & Bookmarks to enrich your Evergreen
                content.
              </li>
            </ul>
          </div>
        )}

        {dataTotalLength !== null && dataTotalLength === 0 && (
          <h3 className="d-flex justify-content-center align-items-center w-100  mt-5">
            No post found!
          </h3>
        )}
        {/* InfiniteScroll implemented */}
        <InfiniteScroll
          dataLength={data?.length}
          next={fetchData}
          style={{ overflow: "visible" }}
          hasMore={hasMore}
          loader={
            <div className="d-flex justify-content-center p-3">
              {isDark ? (
                <img
                  className="loading-logo"
                  src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                />
              ) : (
                <img
                  className="loading-logo"
                  src={`${IMAGE_URL}/images/short_logo_white.svg`}
                />
              )}
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <div className="masonry-grid w-full -ml-2">
            {filterItem?.length > 0
              ? filterItem?.map((item, i) => (
                  <>
                    <div className=" masonry-grid-item" key={i}>
                      <PostCard
                        key={i}
                        des={item.postContent}
                        image={item.originalUrl}
                        postype={item.postType}
                        data={item}
                        setData={setData}
                        isEvergreen={true}
                        isBookmark={true}
                      />
                    </div>
                  </>
                ))
              : searchItem && (
                  <div
                    className={`flex flex-col gap-4 ${
                      layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"
                    }`}
                  >
                    <h3 className="no-posts text-center my-5">
                      No posts found!
                    </h3>
                  </div>
                )}
            {!searchItem &&
              data?.map((item, i) => (
                <>
                  <div className=" masonry-grid-item" key={i}>
                    <PostCard
                      key={i}
                      des={item.postContent}
                      image={item.originalUrl}
                      postype={item.postType}
                      data={item}
                      setData={setData}
                      isEvergreen={true}
                      isBookmark={true}
                    />
                  </div>
                </>
              ))}
          </div>
        </InfiniteScroll>
      </div>
      {/* {
          hasMore && <div className='d-flex justify-content-center w-100 col-lg-12' >
            {
              isLoading ? <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['rgba(138, 81, 255, 0.70)', 'rgba(57, 178, 255, 0.70)']}
            /> :
            <i className="fa-solid fa-angles-down" onClick={() => setPage(page + 1)} style={ isDark ? {color:'#000'} : {color:'#fff'} }></i>
            }
            </div>
          } */}
      {/* {
      post.postContent && <div className="col-xxl-3 col-lg-4 col-md-5 mt-4">
          <PreviewPost isPreview={true} isDeleteBtnHide={true} />
        </div>
    } */}
    </div>
  );
};

export default Evergreen;
