const data = [
    {
        "icon" : "post_scheduled_large",
        "darkIcon":"post_scheduled_large-white",
        "title" : "Discover",
        "desc" : "Take inspiration from millions of posts and rewrite with AI.",
        "button_name" : "Go Now",
        "path" : '/discover' 
    },
    {
        "icon" : "post_AI_post",
        "darkIcon":"post_AI_post-white",
        "title" : "AI Content",
        "desc" : "Just give a topic and instantly create post ideas using AI.",
        "button_name" : "Create Post",
        "path" : '/ai-content' 
    },
    {
        "icon" : "post_swipe_file",
        "darkIcon":"post_swipe_file-white",
        "title" : "On-The-Go",
        "desc" : "Your interest-based daily dose of AI generated posts.",
        "button_name" : "View Posts",
        "path" : '/on-the-go' 
    }
]

export default data