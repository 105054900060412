import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { otgState } from "./initialState"
import { getApi, postApi } from "../../../api/methods";

const otgSlice = createSlice({
    name: 'OTG',
    initialState : otgState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserOTG.fulfilled, (state,action)=>{
            console.log('user/getOTG success');

            return { ...state, otgData:[...action.payload] }
        })
        builder.addCase(fetchUserOTG.rejected, (state,action)=>{
            console.warn('user/getOTG error');
            return { ...state }
        })
        builder.addCase(fetchUserOTGById.fulfilled, (state,action)=>{
            console.log('user/getOTG by id success');

            return { ...state, otgData:[...action.payload] }
        })
        builder.addCase(fetchUserOTGById.rejected, (state,action)=>{
            console.warn('user/getOTG by id error');
            return { ...state }
        })
    }
})

// create 10 otg posts per day only once
export const fetchUserOTG = createAsyncThunk('user/createOTG',async (_,thunkAPI)=>{
    const { userId } = _
        const bodyPost = { userId }
        const response = await postApi(`otg-post/get-otg-post/`,bodyPost)
        return  response.data
})

// get the same 10 otg posts of the day
export const fetchUserOTGById = createAsyncThunk('user/getOTG',async (_,thunkAPI)=>{
    const { userId } = _
        const response = await getApi(`otg-post/get-otg-by-id/${userId}`)
        return  response.data
})

// export const { display,addName } = otgSlice.actions
export default otgSlice.reducer