export const fontStyle = (data) => {
  return (dispatch) => {
    dispatch({ type: "FONT_STYLE", payload: data });
  };
};

export const fontSize = (data) => {
  return (dispatch) => {
    dispatch({ type: "FONT_SIZE", payload: data });
  };
};

export const textAlignment = (data) => {
  return (dispatch) => {
    dispatch({ type: "TEXT_ALIGN", payload: { data } });
  };
};

export const verticalAlignment = (data) => {
  return (dispatch) => {
    dispatch({ type: "VERTICAL_ALIGN", payload: { data } });
  };
};

export const customPairSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "CUSTOM_PAIR", payload: { data } });
  };
};

export const descColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "DESC_COLOR", payload: { data } });
  };
};

export const titleColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "TITLE_COLOR", payload: { data } });
  };
};

export const subTitleColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "SUBTITLE_COLOR", payload: { data } });
  };
};

export const pageNoColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "PAGE_NO_COLOR", payload: { data } });
  };
};

export const customColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "TEXT_COLORPICKER", payload: { data } });
  };
};

export const resetText = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_TEXT",
    });
  };
};

export const templateText = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_TEXT",
      payload: id,
    });
  };
};