import React, { useEffect, useState } from 'react'
import { getApi, patchApi, postApi } from '../../api/methods'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const Billing = (props) => {
  const userInfo = useSelector(state => state.user.userData)
  const { isDark } = useSelector((state) => state.common);

  // console.log({ userInfo });
  const teamMembers = useSelector(state => state.user.teamMembers)
  // console.log({ userInfo });
  const navigate = useNavigate()
  const [getSubs, setSubs] = useState([])
  const filterActiveTeamMembers = teamMembers.filter((member=>member.ac_status === true))
  const [teamMembersCount, setTeamMembersCount] = useState(filterActiveTeamMembers?.length || 0)
  const [creditBalance, setCreditBalance] = useState(0)
  const getSubActive = getSubs?.find((item) => item.planStatus === true)
  // console.log({ getSubActive });

  // get subsctiption plan of users's team, which plan their team should use
  const getSubscription = async () => {
    await getApi(`transaction/get-subscription-by-id/${userInfo.teamId}`)
      .then((res) => {
        setSubs(res)
      }).catch((err) => {
        console.warn(err);
      })
  }

  // fetch credit point avaliable of team member
  const getCreditBalance = async () => {
    await getApi(`credit/check-credit-point/${userInfo.teamId}`)
      .then((res) => {
        setCreditBalance(res.data.balance)
      }).catch((err) => {
        console.warn(err);
      })
  }

  // for upgrade plan page should be redirect
  const handleUpdateSub = (subscriptionId) => {
    localStorage.setItem('subscriptionId', subscriptionId)
    navigate('/buy-subscription')
  }

  const handleUpdateUserSub = async () => {
    await patchApi('user/user-session', { linkedinId: userInfo?.linkedinId, subscription: false })
  }

  const cancelCreditHistory = async (id) =>{
    try{

            const bodyPost = { 
                "teamId" : userInfo.teamId, 
                "name" : 'Cancel',
                "subscriptionId" : id, 
                "userId" : userInfo.linkedinId, 
                "crpoint" : `0`
            }

        await postApi('credit/create-credit-history',bodyPost)
    }catch (error){
        console.warn('update Credit History', error)
    }
}
  const cancelTeamSubcription = async () =>{
    try{

            const bodyPost = { 
                "teamId" : userInfo.teamId, 
                "subscription" : false
            }

        await patchApi('user/team-subscribed',bodyPost)
        toast.success('Plan Cancelled!')
    }catch (error){
        console.warn('update team subscription cancel:', error)
        toast.error('Plan Cancelled failed!')
    }
}

const handleCancelSubscription = async () => {
  // Confirm cancellation with the user
  const isConfirmed = window.confirm("Are you sure you want to cancel the subscription?");
  if (isConfirmed) {
    // Check if there is an active subscription
    if (getSubActive?.subscriptionId) {
      // Send a request to cancel the subscription
      await patchApi('transaction/update-subscription', { subscriptionId: getSubActive?.subscriptionId })
        .then((res) => {
          // Update user session to reflect the subscription cancellation
          handleUpdateUserSub();
          // Cancel credit history for the subscription
          cancelCreditHistory(getSubActive?.subscriptionId);
          // Cancel team subscription
          cancelTeamSubscription();
          // Redirect to the accounts page with a specific invite code
          window.location.href = '/my-accounts?invite=3';
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  }
};
  useEffect(() => {
    getSubscription()
    getCreditBalance()
  }, [])
  // console.log('userinfosa', userInfo.teamId);
  // console.log({ getSubActive });
  return (
    <>
      <div className='mb-3'>
        <h3 className='text-sm sm:text-xl mb-2'>Email to send invoices :       <strong>{userInfo.email}</strong>
        </h3>
        <p className='text-xs sm:text-lg'>All invoices will be send to the email address specified below</p>
      </div>
      <div className={`${isDark ? 'invoice-mail' : 'invoice-mail-dark'} pl-0`}>
      </div>
        {/* <button disabled>Save</button> */}

      <div className='mt-3 mb-1 flex max-md:flex-col max-md:items-start items-center gap-1' >
        <h3 className='text-md max-xs:left-[20px] sm:text-xl pr-4 whitespace-nowrap'>Your Plan:</h3>
        { userInfo.subscription ?       //getSubActive !== undefined  // old condition
          <div className={isDark ? 'plan-container':'plan-container-dark'} >
            <div className={` ${isDark ? 'plan-header':'plan-header-dark'}`}>
              <div className='d-flex justify-content-between m-5 mt-0 mb-0'>
                <p className='mb-0'><strong>{getSubActive?.plan_Name}</strong></p>
                <p className='mb-0'>INR {getSubActive?.pay_amnt}/{getSubActive?.plan_type}</p>
              </div>
            </div>
            <div className="max-md:text-[10px] plan-body ">

              <div className='plan-body-item'>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Expiry</h3>
                  <p>{getSubActive?.endDate}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Member Since</h3>
                  <p>{getSubActive?.startDate}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Credit Available</h3>
                  <p>{creditBalance}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Active Team Member</h3>
                  <p>{teamMembersCount}</p>
                </div>

              </div>
              <div className={isDark ?'plan-body-item':'plan-body-item-dark'}>
                <h3 className='m-3 font-bold'>BENEFITS</h3>
                <ul className='m-3'>
                  <li>Upto {getSubActive?.No_Ac} team members</li>
                  <li>{getSubActive?.credits} Credits/month</li>
                  <li>AI Post Generation</li>
                  <li>On-The-Go</li>
                  <li>On-The-Go</li>
                  <li>Discover posts from similar interests</li>
                </ul>
              </div>



            </div>
            { 
              userInfo.teamPrimary &&
              <div className={isDark ? 'plan-btn':'plan-btn-dark'}>
                <button onClick={() => handleUpdateSub(getSubActive?.subscriptionId)} >Upgrade</button>
              </div>
            }
          </div>
          : <>
            <h2 className='py-3 text-center text-xl'>Free-Tier</h2>
            <div className='d-flex flex-column justify-content-center ml-10 w-[400px]'>
            <button className='btn btn-outline-primary w-full' onClick={() => handleUpdateSub(getSubActive?.subscriptionId)} >Buy Now</button>
          </div>
          </>
        }

      </div>
      {
        userInfo.teamPrimary && userInfo.subscription &&
        <div className={isDark ? 'plan-footer':'plan-footer-dark'}>
          <button className={`${isDark? 'text-white border ':'text-white border'} max-md:text-[10px]`} onClick={handleCancelSubscription} style={{visibility: getSubActive === undefined && 'hidden', backgroundColor:'red',borderColor:'red'}} >Cancel Subscription</button>
        </div>
      }
    </>
  )
}

export default Billing