const initialState = {
  recents: [],
  images: "",
  keyWord: "",
};

const imageUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_IMAGE": {
      const newImage = action.payload;
      return {
        ...state,
        recents: Array.isArray(state.recents)
          ? [...state.recents, newImage]
          : [newImage],
      };
    }
    case "DELETE_RECENT_IMAGE": {
      return {
        ...state,
        recents: state.recents.filter((img) => img !== action.payload),
      };
    }
    case "CLEAR_IMAGES":
      return {
        ...state,
        images: [],
      };
    case "PIXABAY_IMAGE": {
      if (action.payload.data.hits) {
        return { images: action.payload.data.hits, keyWord: "Pixabay" };
      }
    }
    case "PEXELS_IMAGE": {
      if (action.payload.data.photos) {
        return { images: action.payload.data.photos, keyWord: "Pexels" };
      }
    }
    case "UNSPLASH_IMAGE": {
      if (action.payload.data.results) {
        return { images: action.payload.data.results, keyWord: "Unsplash" };
      }
    }
    default:
      return state;
  }
};

export default imageUploadReducer;
