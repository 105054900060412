import {
  roundNess,
  slideNoAdd,
  slideNoStyleSet,
  isSwipe,
  isBookmark,
  putSwipeText,
  putSwipeIcon,
  customSettingsColorSet,
  swipeColorSet,
  bookmarkColorSet,
} from "../../../store/features/carousel/action/settingsAction";
import cross from "../../../../src/assets/cross.svg";
import swipeArr from "../../../../src/assets/swipeArr.svg";
import rightArr from "../../../../src/assets/rightArr.svg";
import circle from "../../../../src/assets/circle.svg";
import thunder from "../../../../src/assets/thunder.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../components/ui/input";
import { Switch } from "../../../components/ui/switch";

function SettingsBar() {
  const [swipeText, setSwipeText] = useState("");
  const dispatch = useDispatch();

  const { isDark } = useSelector((state) => state.common);
  const {
    slideNo,
    slideNoStyle,
    round,
    swipe,
    bookmark,
    swipeIcon,
    swipeColor,
    bookmarkColor,
    settingColorPicker,
  } = useSelector((s) => s.settingsReducer);

  useEffect(() => {
    const colorInp1 = document.getElementById("colorInp1");
    const colorPick1 = document.getElementById("colorPicker1");
    const colorInp2 = document.getElementById("colorInp2");
    const colorPick2 = document.getElementById("colorPicker2");
    if (colorInp1 && colorPick1 && colorInp2 && colorPick2) {
      colorInp1.disabled = !settingColorPicker;
      colorPick1.disabled = !settingColorPicker;
      colorInp2.disabled = !settingColorPicker;
      colorPick2.disabled = !settingColorPicker;
    }
    if (!settingColorPicker) {
      dispatch(swipeColorSet(swipeColor));
      dispatch(bookmarkColorSet(bookmarkColor));
    }
  }, [settingColorPicker]);

  useEffect(() => {
    dispatch(putSwipeText(swipeText));
    console.log(swipeText);
  }, [swipeText]);

  const handleSettingColorPicker = (data) => {
    dispatch(customSettingsColorSet(data));
  };
  const handleSwipeColor = (data) => {
    dispatch(swipeColorSet(data));
  };

  const handleBookmarkColor = (data) => {
    dispatch(bookmarkColorSet(data));
  };
  return (
    <>
      <div className="py-6 px-3 flex flex-col gap-4 w-[323px]">
        <p className="font-bold">Settings</p>
        <div className="flex flex-col w-full gap-1">
          {/* <label className="text-sm font-semibold">Slide Numbers</label> */}
          <div className="flex gap-2 items-center">
            {/* <label className="switch">
              <input
                type="checkbox"
                checked={slideNo}
                onChange={() => dispatch(slideNoAdd(!slideNo))}
              />
              <span class="slider round"></span>
            </label> */}
            <Switch
              checked={slideNo}
              onCheckedChange={() => dispatch(slideNoAdd(!slideNo))}
              className="bg-blue-500 data-[state=checked]:bg-blue-500"
            />
            <p className="text-sm font-medium">Slide Numbers</p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold">Slide Numbers Style</p>
          <div className="flex gap-6">
            <div
              className={`text-center border-[1px] ${
                slideNoStyle == "round"
                  ? isDark
                    ? "border-black"
                    : "border-gray-200"
                  : isDark
                  ? "border-gray-200"
                  : "border-black"
              } w-[130px] rounded-lg p-2 cursor-pointer`}
              onClick={() => dispatch(slideNoStyleSet("round"))}
            >
              <span
                className={`${
                  isDark ? "bg-gray-200" : "bg-gray-400"
                } px-[10px] py-1 rounded-full`}
              >
                1
              </span>
            </div>
            <div
              className={`text-center border-[1px] ${
                slideNoStyle == "plain"
                  ? isDark
                    ? "border-black"
                    : "border-gray-200"
                  : isDark
                  ? "border-gray-200"
                  : "border-black"
              } w-[130px] rounded-lg p-2 cursor-pointer`}
              onClick={() => dispatch(slideNoStyleSet("plain"))}
            >
              <p>01</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-semibold">Roundness</p>
          <input
            type="range"
            min="0.1"
            max="1"
            step="0.01"
            value={round}
            onChange={(e) => dispatch(roundNess(parseFloat(e.target.value)))}
            className="w-full cursor-pointer"
          />
        </div>
        {/* <div className="flex flex-col w-full gap-4"> */}
        {/* <label className="text-sm font-semibold">Swipe Settings</label> */}
        {/* <div className="flex flex-col gap-4"> */}
        <div className="flex gap-2 items-center">
          {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={bookmark}
                  onChange={() => dispatch(isBookmark(!bookmark))}
                />
                <span class="slider round"></span>
              </label> */}
          <Switch
            checked={bookmark}
            onCheckedChange={() => dispatch(isBookmark(!bookmark))}
            className="bg-blue-500 data-[state=checked]:bg-blue-500"
          />
          <p className="text-sm font-medium">Bookmark Button</p>
        </div>
        <div className="flex gap-2 items-center">
          {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={swipe}
                  onChange={() => dispatch(isSwipe(!swipe))}
                />
                <span class="slider round"></span>
              </label> */}
          <Switch
            checked={swipe}
            onCheckedChange={() => dispatch(isSwipe(!swipe))}
            className="bg-blue-500 data-[state=checked]:bg-blue-500"
          />
          <p className="text-sm font-medium">Swipe Indicator</p>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div className="flex flex-col w-full gap-2">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            Swipe Indicator Text
          </label>
          <Input
            type="text"
            value={swipeText}
            onChange={(e) => {
              setSwipeText(e.target.value);
            }}
            placeholder="e.g. Swipe To Continue"
            style={{ color: isDark ? "black" : "white" }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold">Swipe Indicator Icon</p>
          <div className="flex justify-between">
            <div
              className={`p-[12px] flex items-center rounded-lg ${
                swipeIcon == "cross" ? "bg-[#49505790]" : "bg-[#495057]"
              } cursor-pointer`}
              onClick={() => dispatch(putSwipeIcon("cross"))}
            >
              <img src={cross}></img>
            </div>
            <div
              className={`p-[12px] flex items-center rounded-lg ${
                swipeIcon == "swipeArr" ? "bg-[#49505790]" : "bg-[#495057]"
              } cursor-pointer`}
              onClick={() => dispatch(putSwipeIcon("swipeArr"))}
            >
              <img src={swipeArr}></img>
            </div>
            <div
              className={` p-[12px] flex items-center rounded-lg ${
                swipeIcon == "rightArr" ? "bg-[#49505790]" : "bg-[#495057]"
              } cursor-pointer`}
              onClick={() => dispatch(putSwipeIcon("rightArr"))}
            >
              <img src={rightArr}></img>
            </div>
            <div
              className={` p-[12px] flex items-center rounded-lg ${
                swipeIcon == "circle" ? "bg-[#49505790]" : "bg-[#495057]"
              } cursor-pointer`}
              onClick={() => dispatch(putSwipeIcon("circle"))}
            >
              <img src={circle}></img>
            </div>
            <div
              className={` p-[12px] flex items-center rounded-lg ${
                swipeIcon == "thunder" ? "bg-[#49505790]" : "bg-[#495057]"
              } cursor-pointer`}
              onClick={() => dispatch(putSwipeIcon("thunder"))}
            >
              <img src={thunder}></img>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col gap-2  mt-2">
          <div className="flex items-center justify-between border-2 border-gray-200 p-2 rounded-lg ">
        <p>Custom Colors</p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={settingColorPicker}
                  onChange={()=>handleSettingColorPicker(!settingColorPicker)}
                />
                <span class="slider round"></span>
              </label>
              </div>
              <div className="flex flex-col">
                    <div className="relative">
                    <label>Swipe Color</label>
                        <input type='text' 
                        value={swipeColor} onChange={(e)=>handleSwipeColor(e.target.value)}
                         id='colorInp1' className="w-[290px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker1' 
                               value={swipeColor} onChange={(e)=>handleSwipeColor(e.target.value)}
                                >
                                  
                                </input>
                            </div>
                    </div>
                    <div className="relative -mt-4">
                    <label>Bookmark Color</label>
                        <input type='text' 
                        value={bookmarkColor} onChange={(e)=>handleBookmarkColor(e.target.value)}
                         id='colorInp2' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker2' 
                              value={bookmarkColor} onChange={(e)=>handleBookmarkColor(e.target.value)}
                                >
                                  
                                </input>
                            </div>
                    </div>
                    </div>
            </div> */}
      </div>
    </>
  );
}

export default SettingsBar;
