export const putHeadShot = (data) => {
  return (dispatch) => {
    dispatch({ type: "HEADSHOT_SET", payload: { data } });
  };
};

export const introOutroHeadShot = (data) => {
  return (dispatch) => {
    dispatch({ type: "HEADSHOT_INTRO_OUTRO", payload: { data } });
  };
};

export const headShotSize = (data) => {
  return (dispatch) => {
    dispatch({ type: "HEADSHOT_SIZE", payload: data });
  };
};

export const headShotColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "HEADSHOT_COLOR", payload: { data } });
  };
};

export const colorPickerSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "HEADSHOT_COLORPICKER", payload: { data } });
  };
};
export const resetHeadshot = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_HEADSHOT",
    });
  };
};

export const TemplateHeadshot = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_HEADSHOT",
      payload: id,
    });
  };
};