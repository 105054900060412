import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const DiscoverDropDown = ({ discover, selectedItem, handleItemClick, common }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (item) => {
    handleItemClick(item);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* Mobile dropdown (visible only on screens <= 768px) */}
      <div className="md:hidden">
        <button
          onClick={toggleDropdown}
          className={`w-full h-10 p-1 flex justify-between items-center ${
            common.isDark ? 'bg-gray-800 text-white' : ' text-black'
          } border rounded-md`}
        >
          <span>{selectedItem?.length ? selectedItem[0] : 'Select category'}</span>
          <ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''} ${common.isDark? ' ':'text-white'}`} />
        </button>
        {isOpen && (
          <div className={`absolute z-10 w-full mt-1 border rounded-md ${
            common.isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
          }`}>
            {discover.category?.map((item, index) => (
              item && (
                <div
                  key={index}
                  className={`p-2 cursor-pointer hover:bg-gray-100 hover:text-gray-900 ${
                    selectedItem?.includes(item) ? 'font-bold' : ''
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </div>
              )
            ))}
          </div>
        )}
      </div>

      {/* Desktop layout (visible only on screens > 768px) */}
      <div className="hidden md:flex md:flex-wrap">
        {discover.category?.map((item, index) => (
          item && (
            <span
              key={index}
              className={`${
                selectedItem?.includes(item)
                  ? `grediant-border discover-isActive-list`
                  : ""
              } ${
                common.isDark
                  ? "discover-isActive-list-light"
                  : "discover-isActive-list-dark"
              } cursor-pointer`}
              onClick={() => handleItemClick(item)}
            >
              {item}
            </span>
          )
        ))}
      </div>
    </div>
  );
};

export default DiscoverDropDown;