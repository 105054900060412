import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import userReducer from "./features/user/userSlice";
import authReducer from "./features/user/authSlice";
import thunk from "redux-thunk";
import commonReducer from "./features/common/commonSlice";
import bookmarkReducer from "./features/bookmark/bookmarkSlice";
import draftReducer from "./features/draft/draftSlice";
import historyReducer from "./features/history/historySlice";
import otgReducer from "./features/otg/otgSlice";
import postReducer from "./features/post/postSlice";
import scheduleDateReducer from "./features/scheduleData/scheduleDataSlice";
import everGreenReducer from "./features/evergreen/evergreenSlice";
import previewPostReducer from "./features/previewPost/previewPostSlice";
import discoverReducer from "./features/discover/discoverSlice";
import mediaReducer from "./features/media/mediaReducer";
import analyticsReducer from "./features/analytics/analyticsReducer";
import textReducer from "./features/carousel/reducer/textReducer";
import adjustReducer from "./features/carousel/reducer/adjustReducer";
import canvaReducer from "./features/carousel/reducer/canvaReducer";
import headShotReducer from "./features/carousel/reducer/headShotReducer";
import imageUploadReducer from "./features/carousel/reducer/imageUploadReducer";
import settingsReducer from "./features/carousel/reducer/settingsReducer";
// admin reducers
import admindashboardReducer from "./features/admin/dashboard/dashboardSlice";
import adminuserReducer from "./features/admin/users/usersSlice";
import generatePostReducer from "./features/generatePost/generatePostSlice";
// import generatePostReducer from "./features/generatePost/generatePostSlice";
import saveCarouselsReducer from "./features/carousel/reducer/saveCarouselsReducer.jsx";
const analyticsPersistConfig = {
  key: "analytics",
  storage,
  whitelist: [
    "totalLikes",
    "totalComments",
    "interactions",
    "firstname",
    "lastname",
    "currentPosition",
    "currentCompanyName",
    "linkedinProfile",
    "location",
    "reaction",
    "dailyPost",
    "post",
    "comments",
    "impression",
    "likesByCompany",
    "likesByRegion",
    "likesByOccupation",
    "commentsByCompany",
    "commentsByRegion",
    "commentsByOccupation",
    "team",
    "userId",
    "postpost",
  ],
};

const persistedAnalyticsReducer = persistReducer(
  analyticsPersistConfig,
  analyticsReducer
);

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  post: postReducer,
  previewPost: previewPostReducer,
  common: commonReducer,
  bookmarks: bookmarkReducer,
  drafts: draftReducer,
  histories: historyReducer,
  otgs: otgReducer,
  scheduleData: scheduleDateReducer,
  evergreen: everGreenReducer,
  adminDashboard: admindashboardReducer,
  adminUser: adminuserReducer,
  discover: discoverReducer,
  media: mediaReducer,
  analytics: persistedAnalyticsReducer,
  textReducer: textReducer,
  canvaReducer: canvaReducer,
  adjustReducer: adjustReducer,
  settingsReducer: settingsReducer,
  headShotReducer: headShotReducer,
  imageUploadReducer: imageUploadReducer,
  generatePostReducer: generatePostReducer,
  // generatePostReducer: generatePostReducer,
  saveCarouselsReducer: saveCarouselsReducer, // Make sure this line is present
});

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [
    "textReducer",
    "canvaReducer",
    "adjustReducer",
    "settingsReducer",
    "headShotReducer",
    "imageUploadReducer",
  ],
};

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedRootReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;
