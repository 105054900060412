import { createSlice } from "@reduxjs/toolkit";
import { generateAiPostState } from "./initialState";

const generatePostSlice = createSlice({
  name: "generatePost",
  initialState: generateAiPostState,
  reducers: {
    addGenerateType: (state, action) => {
      state.generationType = action.payload;
    },
    addGenerateTopic: (state, action) => {
      state.postTopic = action.payload;
    },
    setIsInputDisabled: (state, action) => {
      state.isDisabledInput = action.payload;
    },
    updateAIStateTopic: (state,action)=> {
      state.AIState.AItopic = action.payload;
    },
    updateAIStateAIcatagory: (state,action)=> {
      state.AIState.AIcatagory = action.payload;
    },
    updateAIStateAICarouselNumber: (state,action)=> {
      state.AIState.AICarouselNumber = action.payload;
    },
    updateAIStateAICarouselTitle: (state,action)=> {
      state.AIState.AICarouselTitle = action.payload;
    },
    updateAIStateLength: (state,action)=> {
      state.AIState.AIlength = action.payload;
    },
    addAIPoste: (state, action) => {
      // You might want to store the AI generated post in the state
      state.generatedPost = action.payload;
    },
    changingPostType: (state, action) => {
      state.postType = action.payload;
    },
    setPostGenerateType: (state, action) => {
      state.postGenerationType = action.payload;
    },
    clearGeneratePostState: (state) => {
      return generateAiPostState;
    },
  },
  extraReducers: (builder) => {},
});

export const { addGenerateType, addGenerateTopic,clearGeneratePostState,setIsInputDisabled,updateAIStateTopic,updateAIStateAIcatagory,updateAIStateAICarouselNumber,updateAIStateAICarouselTitle,updateAIStateLength} =
  generatePostSlice.actions;
export default generatePostSlice.reducer;
