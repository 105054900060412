import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, IMAGE_URL } from '../../api/API';
import Cookies from 'universal-cookie';
import './InvitationRegister.css'; // Create this CSS file

const InvitationRegister = () => {
  const cookies = new Cookies();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');

  useEffect(() => {
    if (!cookies.get('email')) {
      console.error('Email cookie not found');
      // Add your redirect logic here
    }
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}userprofile/register-invitation`, 
        { password },
        { withCredentials: true }
      );
      window.location.href = '/authenticatewithlinkedin';
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError('User already exists');
      } else {
        setError('Registration failed. Please try again.');
      }
      console.error('Registration error:', error);
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = '';
    const lengthCriteria = password.length >= 8;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

    if (lengthCriteria && upperCaseCriteria && lowerCaseCriteria && numberCriteria && specialCharCriteria) {
      strength = 'Strong';
    } else if (lengthCriteria && (upperCaseCriteria || lowerCaseCriteria) && (numberCriteria || specialCharCriteria)) {
      strength = 'Moderate';
    } else {
      strength = 'Weak';
    }

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculatePasswordStrength(newPassword);
  };

  return (
    <div className='invitation-register-container'>
      <div className="invitation-register-box">
        <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
        <h3>Set Your Password</h3>
        <form onSubmit={handleRegister}>
          <div className="form-group mt-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          {password && <div className={`password-strength ${passwordStrength.toLowerCase()}`}>{passwordStrength}</div>}
          <button type="submit" className="btn btn-primary gradient-button mt-5" style={{ width: "100%" }}>Set Password</button>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default InvitationRegister;