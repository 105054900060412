import React, { useEffect ,useState} from 'react'
import './Dashboard.css'
import PostSummeryBox from '../../components/Dashboard/PostSummeryBox'
import PostAd from '../../components/Dashboard/PostAd'
import { changeLayoutShift } from "../../store/features/common/commonSlice";
import postAdContents from './content/postAd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getApi } from '../../api/methods';
// import { fetchDashboard } from '../../store/features/user/userSlice';

const Dashboard = () => {
  
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)
  const { isDark } = useSelector((state) => state.common);
  
  const navigate = useNavigate()
  const [dashboardData, setDashboardData] = useState({
    postCreatedCount: 0,
    schedulePostCount: 0,
    draftCount: 0,
    bookmarkCount: 0
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);



  // useEffect(()=>{
  //   dispatch(changeLayoutShift(false));
  //   },[])

  const fetchDashboardData = async () => {
    console.log('Fetching dashboard data...');
    setLoading(true);
    try {
      const response = await getApi(`dashboard/user-dashboard-counter/${user.userData.linkedinId}`);
      // console.log('Dashboard API response:', response);
      if (response && response.data) {
        setDashboardData(response.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(err.message || 'Failed to load dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (user.userData && user.userData.linkedinId) {
      fetchDashboardData();
    }
  }, [user.userData]);
  

  return (
    <div className='container-fluid px-[2vw] lg:px-[5vw] overflow-x-hidden py-10 xl:px-[9vw]'>
      <div className='dashboard-container' style={{padding:0}}>
        <h3 className='head-text mb-3 text-xl'>Here's what's going on</h3>
        <div className='row'>
          <div className="col-12">
            <div className="post-couter-container mb-5">
              <PostSummeryBox icon={isDark ? 'post_created' : 'post_created-white'} name={'Post Created'} count={dashboardData.postCreatedCount || 0}/>
              <PostSummeryBox icon={isDark ? 'post_scheduled_small' : 'post_scheduled_small-white'} name={'Post Scheduled'} count={dashboardData.schedulePostCount || 0} />
              <PostSummeryBox icon={isDark ? 'post_draft' : 'post_draft-white'} name={'Post in Draft'} count={dashboardData.draftCount || 0} />
              <PostSummeryBox icon={isDark ? 'post_bookmark' : 'post_bookmark-white'} name={'Posts Bookmark'} count={dashboardData.bookmarkCount || 0} />
            </div>
          </div>
        </div>
        <h3 className='head-text mt-4 text-xl'>What would you like to do today?</h3>
        <div className='row'>
          <div className="col-12">
            <div className="post-ad-container">
              {
                postAdContents?.map((item, i) => {
                  return (
                    <PostAd key={i}
                      icon={isDark ? item.icon : item.darkIcon}
                      title={item.title}
                      desc={item.desc}
                      button_name={item.button_name}
                      path={item.path} />)
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
