import React from 'react'
import './Loader.css'
import { IMAGE_URL } from '../../api/API'

const Loader = () => {
  return (
    <div className='loading'>
        <img className='loading-logo' src={`${IMAGE_URL}/images/short_logo_white.svg`}/>
    </div>
  )
}

export default Loader