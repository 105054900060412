import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { everGreenState } from "./initialState"
import { getApi } from "../../../api/methods";

const everGreenSlice = createSlice({
    name: 'EverGreen',
    initialState : everGreenState,
    reducers: {
        // currently no use in this project
        addEvergreenData: (state,action) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserEverGreen.fulfilled, (state,action)=>{
            console.log('user/getEverGreen success');

            return { ...state, everGreenData:[...action.payload] }
        })
        builder.addCase(fetchUserEverGreen.rejected, (state,action)=>{
            console.warn('user/getEverGreen error');
            return everGreenState
        })
    }
})

// fetch all evergreen posts of perticular user as per page of 12 items
export const fetchUserEverGreen = createAsyncThunk('user/getEverGreen',async (_,thunkAPI)=>{
    const {userId, page} = _
    const response = await getApi(`evergreen/posts/${userId}?page=${page}`)
    return  response.data
})

export const { addEvergreenData } = everGreenSlice.actions
export default everGreenSlice.reducer