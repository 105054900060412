import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './style.css'
import { IMAGE_URL } from '../../api/API';
import { addPreviewPost, togglePreview } from '../../store/features/previewPost/previewPostSlice';

// import PostCard from '../PostCard/PostCard';

const MonthlyView = (props) => {
  const { user } = useSelector(state => state)
  const userInfo = user?.userData
  // console.log({ userInfo });
  const getMonth = props.allDatesOfMonth
  // console.log({length:getMonth[0].length});
  const getScheduleData = props.scheduledData[0]
  // console.log({ getScheduleData });


  // here logic is date format is comming with "/" like mm/dd/yyyy and we convert it to "-" like yyyy-mm-dd and find all the group posts of that date
  const getScheduleDatabydate = (day) => {
    const initialDate = day.split('/');
    // console.log({initialDate})
    const celenderDate = [initialDate[2], initialDate[0], initialDate[1]].join('-')
    const getscheduledatabydate = getScheduleData?.filter((item) => item.date_time.substring(0, 10) === celenderDate)
    // console.log({ datat: getscheduledatabydate });
    return getscheduledatabydate
  }

  const dispatch = useDispatch()
  const previewPostCardHandler = (item) => {

    // console.log(props.data);
    dispatch(addPreviewPost(item))
    dispatch(togglePreview(true))
}

// here how many number of posts present in this date show or not
function handleScroll() {
  var scrollableDiv = document.getElementById('scrollableDivCalender');
  var elementToHide = document.getElementById('elementToHide');

  // Check if the user has scrolled
  if (scrollableDiv.scrollHeight > 0) {
      // User has scrolled, hide the element
      elementToHide.style.display = 'none';
  } else {
      // User is at the top, show the element
      elementToHide.style.display = 'block';
  }
}

  return (
    <>
      <table className='table table-bordered table-responsive monthly-table overflow-x-auto flex-1'>
        <thead>
          <tr>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>

        <tbody cellPadding={5}>
          {getMonth?.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week?.map((day, dayIndex) => (
                <td key={dayIndex} className={`${getScheduleDatabydate(day)?.length>0?"active-cell":""} ${ day!=="" ?"":"de-active"} ` }  
                 >
                  {day.split('/')[1]}
                
                      <div className='user-item-data p-1' onScroll={()=>handleScroll()} id='scrollableDivCalender'>
                        <p>{getScheduleDatabydate(day)?.map((item, i) => {
                         const dateFormat = new Date(`${item?.date_time}.000Z`)
                          return (
                            <div key={i} className='border p-2 rounded mb-1 user-schedule-data' onClick={()=>previewPostCardHandler(item)} >
                              <div className='calender-item d-flex justify-content-between' >
                              {/* <i className="fa-brands fa-linkedin m-1" style={{color: "#105ada"}}></i> */}
                              <div>
                                {
                                    item.platformPost?.linkedin && <img src={`${IMAGE_URL}/images/socialIcon/linkedin.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.twitter && <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.facebook && <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.instagram && <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                              </div>
                              <div className='d-flex align-items-center'>
                              {item.status === 'PUBLISH' ? (
  <i className="fa-solid fa-check-circle m-1" style={{ color: 'green' }}></i>
) : item.status === 'FAILED' ? (
  <i className="fa-solid fa-exclamation-circle m-1" style={{ color: 'red' }}></i>
) : (
  <i className="fa-regular fa-clock m-1" style={{ color: 'purple' }}></i>
)}


  <p className='ms-0 mb-0' >{dateFormat.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
</div>
                            </div>
                              <p className='m-1' style={{fontSize:"13px"}}>{` ${item.postContent?.substring(0, 18)}${item.postContent?.length>18 ? '...' : '' } `}</p>
                            </div>
                          )
                        })}</p>
                      </div>
                   
                      {
                        getScheduleDatabydate(day)?.length - 2 > 0 && <div className='total-number-post-tag' id='elementToHide'>
                        <p>See {getScheduleDatabydate(day)?.length - 2} more</p>
                      </div>
                      }
                </td>
              ))}
            </tr>
          ))}

        </tbody>
      </table>
      {/* {JSON.stringify(props.allDatesOfMonth)} */}
      {/* <h2>monthly</h2> */}
    </>
  )
}

export default MonthlyView