import React, { useEffect, useRef, useState } from "react";
import "./Aside.css";
import leftsidebarlist from "../../../utility/LeftSidebarList.json";
import leftSidebar from "../../../utility/LeftSidebarButtom.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Subscription from "../subscription/Subscription";
import { IMAGE_URL } from "../../../api/API";
import {
  fetchUserInfo,
  fetchUserNotifications,
} from "../../../store/features/user/userSlice";
import { togglePreview } from "../../../store/features/previewPost/previewPostSlice";
import { changeLayoutShift } from "../../../store/features/common/commonSlice";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";

const Aside = () => {
  const navigate = useNavigate();
  const { layoutShift, isDark } = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const isSubscription = user.userData.subscription;
  const location = useLocation();
  let pathName = location.pathname;
  if (pathName === "/") pathName = "/dashboard";
  const [activeIndexTop, setActiveIndexTop] = useState(pathName);
  const [activeIndexBottom, setActiveIndexBottom] = useState(null);
  const [isShowSubs, setIsShowSubs] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsHamburgerOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    if (isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (isMobile) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (location.pathname === "/carousel" && !isMobile) {
      console.log('hi,this is running')
      dispatch(changeLayoutShift(true));
    } else {
      console.log('hi,  second')
      dispatch(changeLayoutShift(false));
    }
  }, [location.pathname, dispatch]);

  const handleItemClickTop = (index, path) => {
    setActiveIndexTop(index);
    setActiveIndexBottom(null);
    navigate(path);
  };

  const handleItemClickBottom = (index, path) => {
    setActiveIndexTop(null);
    setActiveIndexBottom(index);
    navigate(path);
  };

  useEffect(() => {
    // dispatch(fetchUserInfo());
    dispatch(fetchUserNotifications(user?.userData?.linkedinId));
    dispatch(togglePreview(false));
  }, [pathName, dispatch]);

  const toggleHamburger = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  const iconStyle = {
    color: isDark ? "black" : "white", // 'initial' or your default color
  };

  return (
    <>
      <div
        className="aside-box"
        style={{
          overflowX: "hidden",
          width: isMobile ? " " : layoutShift ? "5%" : "11%",
          position: "fixed",
          backgroundColor: isDark ? "white" : "#1E1E1E",
          margin: 0,
          zIndex: 1000,
        }}
        id="hide-scrollbar"
        ref={sidebarRef}
      >
        <div className={`${layoutShift ? "logo-sec-shift" : "logo-sec"}`}>
          <button
            className={`hamburger-button ${isDark ? "dark-mode" : ""}`}
            onClick={toggleHamburger}
          >
            <HamburgerMenuIcon style={iconStyle} />
          </button>
          {layoutShift ? (
            <>
              {isDark ? (
                <img
                  src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                  className="max-md:hidden img-fluid short-logo"
                  style={{
                    height: "40px",
                    width: "40px",
                    marginLeft: "0.2rem",
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={`${IMAGE_URL}/images/short_logo_white.svg`}
                  className="max-md:hidden img-fluid short-logo"
                  style={{
                    marginTop: "3%",
                    height: "40px",
                    width: "40px",
                    marginLeft: "0.2rem",
                  }}
                  alt=""
                />
              )}
            </>
          ) : (
            <>
              {isDark ? (
                <>
                  <img
                    src={`${IMAGE_URL}/images/logo.svg`}
                    className="max-md:hidden img-fluid"
                    alt=""
                  />
                  <img
                    src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                    className="max-md:hidden md:hidden img-fluid short-logo"
                    style={{
                      height: "40px",
                      width: "40px",
                      marginLeft: "0.2rem",
                    }}
                    alt=""
                  />
                </>
              ) : (
                <>
                  <img
                    src={`${IMAGE_URL}/images/logo.png`}
                    className="max-md:hidden img-fluid"
                    alt=""
                  />
                  <img
                    src={`${IMAGE_URL}/images/short_logo_white.svg`}
                    className="max-md:hidden md:hidden img-fluid short-logo"
                    style={{
                      marginTop: "3%",
                      height: "40px",
                      width: "40px",
                      marginLeft: "0.2rem",
                    }}
                    alt=""
                  />
                </>
              )}
            </>
          )}
        </div>
        <div className="max-md:flex">
          <div
            className={`list-item-container ${isHamburgerOpen ? "open" : ""} max-md:w-[50vw]`}
            id="hide-scrollbar"
          >
            <ul
              type="none"
              className={layoutShift ? "ul-top-collapse" : "ul-top "}
              id="hide-scrollbar"
            >
              {leftsidebarlist.sidebarlist?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleItemClickTop(index, item.path)}
                  className={
                    item.path === pathName || item.path === `${pathName}/`
                      ? "isActive"
                      : ""
                  }
                  id={layoutShift ? "tooltipClass2" : ""}
                  title={item.name}
                >
                  <i
                    className={`${item.icon}`}
                    style={{
                      width: layoutShift ? "50%" : "27%",
                      textAlign: "center",
                    }}
                  ></i>
                  <span className={` ${layoutShift ? "hide" : "show"}`}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
            <ul
              type="none"
              className={`box-shadow-ui ${
                layoutShift ? "ul-buttom-collapse" : "ul-buttom"
              }`}
              id="hide-scrollbar"
            >
              {leftSidebar.bottomSideBar?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleItemClickBottom(index, item.path)}
                  className={activeIndexBottom === index ? "isActive" : ""}
                  id={layoutShift ? "tooltipClass2" : ""}
                  title={item.name}
                >
                  <i
                    className={item.icon}
                    style={{
                      width: layoutShift ? "10%" : "27%",
                      textAlign: "center",
                    }}
                  ></i>
                  <span className={` ${layoutShift ? "hide" : "show"}`}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isShowSubs && (
        <div className="show-subs">
          <div className="subscription-cancel-btn">
            <i
              className="fa-regular fa-circle-xmark text-white fa-2x m-3"
              onClick={() => setIsShowSubs(!isShowSubs)}
            ></i>
          </div>

          <Subscription setIsShowSubs={setIsShowSubs} />
        </div>
      )}
    </>
  );
};

export default Aside;
