// carouselActions.js

export const fetchSavedCarousels = () => async (dispatch) => {
  try {
    console.log('Fetching saved carousels...');
    const response = await fetch('http://localhost:9090/api/carousel/user/123');
    const data = await response.json();
    console.log('Fetched data:', data);
    dispatch({ type: 'FETCH_SAVED_CAROUSELS_SUCCESS', payload: data });
    console.log('Dispatched FETCH_SAVED_CAROUSELS_SUCCESS');
  } catch (error) {
    console.error('Error fetching saved carousels:', error);
    dispatch({ type: 'FETCH_SAVED_CAROUSELS_FAILURE', payload: error.message });
  }
};

export const setActiveCarousel = (carousel) => ({
  type: 'SET_ACTIVE_CAROUSEL',
  payload: carousel,
});