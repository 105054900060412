import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL ,IMAGE_URL} from '../../api/API';
import './ForgetPassword.css'

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${BASE_URL}userprofile/forgotPassword`, { email });
      setSuccess(true);
    } catch (error) {
      setError('Failed to send reset password email. Please try again.');
      console.error('Forget password error:', error);
    }
  };

  return (
    <div className='forget-password-container bottom-[60px] bg-[#f0f1f3]'>
      <div className='login-box'>
      <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
      <h3>An AI-fueled Professional growth awaits you!</h3>
      <h2 className={`${success? 'hidden':'pt-10'}`}>Enter your Email</h2>
      {success ? (
        <p style={{marginTop:5}}>An email has been sent with instructions to reset your password.</p>
      ) : (
        
        <form className='forget-password-box' onSubmit={handleForgetPassword}>
          <div className="form-group max-md:[300px]">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button type="submit" className="btn btn-primary grediant-button  send-email-button mt-2 max-md:w-[300px]">Send Reset Email</button>
        </form>
      )}
      </div>
    </div>
  );
};

export default ForgetPassword;