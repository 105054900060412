import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addMedia,
  addMediaDis,
  addMediaTitle,
  addOriginalUrl,
  changePostType,
  clearPostState,
  removeMedia,
  removeMediaDis,
  removeMediaTitle,
  removeOriginalUrl,
} from "../../../store/features/post/postSlice";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../../api/API";
import "./PreviewPost.css";
import "../../PostCard/PostCard.css";
import PostCard from "../../PostCard/PostCard";

const PreviewPost = (props) => {
  const user = useSelector((state) => state.user);
  console.log(user.userData);
  const post = useSelector((state) => state.post);
  const { isDark } = useSelector((state) => state.common);
  const [modalData, setModalData] = useState(null);
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (post.postType === "ARTICLE") {
      setUrl(post.originalUrl);
    }
  }, [post.postType, post.originalUrl]);

  useEffect(() => {
    if (url !== "") {
      console.log("URL changed, fetching metadata:", url);
      fetchMetaData();
    }
  }, [url]);

  const fetchMetaData = async () => {
    try {
      console.log("Fetching metadata for URL:", url);
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url,
      });
      setModalData(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  const deleteMedia = (url, index) => {
    dispatch(removeMedia(index));
    dispatch(removeMediaDis(index));
    dispatch(removeMediaTitle(index));
  
    const updatedMediaTitle = post.mediaTitle.filter((_, i) => i !== index);
  
    if (updatedMediaTitle.length === 0) {
      dispatch(changePostType("NONE"));
    }
  };

  const handleUploadSuccess = () => {
    console.log("Upload was successful");
    // Perform any necessary actions after successful upload
  };

  return (
    <div className="w-[100%]">
      <div className="w-[100%]">
        <h3>Preview</h3>
        {/* {props.isPreview && <i className="fa-regular fa-eye-slash" style={isDark ? { color: '#000' } : { color: '#fff' }} onClick={() => dispatch(clearPostState())}></i>} */}
      </div>
      <div
        className={`bg-white rounded-sm mt-[30px] w-[100%] p-2 min-h-[200px] flex flex-col justify-between overflow-y-auto max-h-[650px]`}
      >
        <div>
          <div className="flex justify-between w-[100%] items-start">
            <div className="flex items-center">
              <img
                src={
                  user?.userPhoto?.length > 0
                    ? user?.userPhoto
                    : `${IMAGE_URL}/images/Icons/avatar.jpg`
                }
                className="w-9 mr-2 rounded-full"
              />
              {user?.userData?.userName ? (
                <div className={`flex flex-col align-items-start text-xs`}>
                  <b>
                    <p style={{ color: "black" }}>
                      {user?.userData?.userName?.length > 30
                        ? `${user?.userData?.userName.slice(0, 30)}...`
                        : user?.userData?.userName}
                    </p>
                  </b>{" "}
                  <span style={{ color: "black" }}>
                    {user?.userData?.localizedHeadline?.length > 50
                      ? `${user?.userData?.localizedHeadline.slice(0, 50)}...`
                      : user?.userData?.localizedHeadline}{" "}
                    <i className="fa-solid fa-earth-europe"></i>
                  </span>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="mt-[10px]">
              <i
                className="fa-brands fa-linkedin fa-lg"
                style={{ color: "#105ada", width: "20px" }}
              ></i>
            </div>
          </div>
          <div className="card-item-body">
            <p
              className={`m-3  ${isDark ? "" : "dark-para"}`}
              style={{
                height: "auto",
                overflowWrap: "break-word",
                whiteSpace: "pre-line",
              }}
            >
              {post.postContent.split(/#\s*/).map((part, index) => (
                <React.Fragment key={index}>
                  {index != 0 ? "#" : ""}
                  {part}{" "}
                  {index === 0 &&
                    part.trim().length > 0 &&
                    post.postContent.includes("#")}{" "}
                </React.Fragment>
              ))}
            </p>

            <br />

            {post.mediaTitle.length > 0 && post.postType === "IMAGE" && (
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginBottom: "30px",
    }}
  >
    {post.mediaTitle.length === 1 ? (
      // Single image case
      <div style={{ width: "100%", position: "relative" }}>
        <img
          src={`${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle[0]}`}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            borderRadius: "10px",
          }}
          alt="Media 0"
        />
        {!props.isDeleteBtnHide && (
          <i
            className="fa-regular fa-trash-can media-delete"
            title="delete"
            style={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => deleteMedia(post.mediaTitle[0], 0)}
          ></i>
        )}
      </div>
    ) : post.mediaTitle.length === 2 ? (
      // Two images case
      <div style={{ display: "flex", width: "100%", gap: "10px" }}>
        {post.mediaTitle.map((url, index) => (
          <div
            key={index}
            style={{ flex: 1, position: "relative" }}
          >
            <img
              src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              alt={`Media ${index}`}
            />
            {!props.isDeleteBtnHide && (
              <i
                className="fa-regular fa-trash-can media-delete"
                title="delete"
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
                onClick={() => deleteMedia(url,index)}
              ></i>
            )}
          </div>
        ))}
      </div>
    ) : (
      // Three or more images case
      <div style={{ display: "flex", width: "100%", gap: "10px" }}>
        <div style={{ flex: 2, position: "relative" }}> {/* Flex 2 for larger image */}
          <img
            src={`${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle[0]}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
            alt="Media 0"
          />
          {!props.isDeleteBtnHide && (
            <i
              className="fa-regular fa-trash-can media-delete"
              title="delete"
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
              onClick={() => deleteMedia(post.mediaTitle[0], 0)}
            ></i>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {post.mediaTitle.slice(1, 4).map((url, index) => (
            <div
              key={index + 1}
              style={{ flex: 1, position: "relative" }}
            >
              <img
                src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt={`Media ${index + 1}`}
              />
              {!props.isDeleteBtnHide && (
                <i
                  className="fa-regular fa-trash-can media-delete"
                  title="delete"
                  style={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                  }}
                  onClick={() => deleteMedia(url, index + 1)}
                ></i>
              )}
              {index === 2 && post.mediaTitle.length > 4 && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "bold",
                    borderRadius: "10px",
                  }}
                >
                  +{post.mediaTitle.length - 4}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
)}



            {post.originalUrl && post.postType === "ARTICLE" && modalData && (
              <div
                className="card mb-10"
                style={{ margin: "10px 10px 30px 10px", position: "relative" }}
              >
                <a
                  href={post.originalUrl}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {modalData.data &&
                    modalData.data.ogImage &&
                    modalData.data.ogImage[0] &&
                    modalData.data.ogImage[0].url && (
                      <img
                        src={modalData.data.ogImage[0].url}
                        className="card-img-top"
                        style={{
                          margin: "5% 5% 5% 5%",
                          height: "20%",
                          width: "90%",
                        }}
                        alt="Preview Image"
                      />
                    )}
                  <div
                    style={{ backgroundColor: "rgb(237,243,248)" }}
                    className="card-body"
                  >
                    <h6
                      style={{ fontWeight: "bold", marginLeft: "16px" }}
                      className="card-title text-dark ml-10"
                    >
                      {modalData.data.ogTitle}
                    </h6>
                    <p className="card-text" style={{ color: "black" }}>
                      {modalData.data.ogDescription}
                    </p>
                  </div>
                </a>
              </div>
            )}
            {post.mediaTitle && post.postType === "VIDEO" && (
              <div style={{ position: "relative" }}>
                <video
                  src={`${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle}`}
                  style={{
                    margin: "0px 0px 8% 2.5%",
                    height: "20%",
                    width: "100%",
                  }}
                  controls
                />
                {props.isDeleteBtnHide ? (
                  ""
                ) : (
                  <i
                    className="fa-regular fa-trash-can media-delete"
                    title="delete"
                    onClick={() => deleteMedia()}
                  ></i>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <hr />
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center">
              <img src="/images/linkedInLikes.png" className="w-4"></img>
              <img src="/images/Love.png" className="w-4 mt-1"></img>
              <img src="/images/Celebrate.png" className="w-4"></img>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "8px",
              }}
              className=" max-[400px]:gap-2 gap-[20px]"
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                <i
                  className="fa-regular fa-thumbs-up"
                  style={{ transform: "rotateY(204deg)" }}
                ></i>{" "}
                Like
              </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                <i className="fa-regular fa-comment"></i> Comment{" "}
              </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                <i className="fa-solid fa-retweet"></i> Repost
              </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                <i className="fa-solid fa-paper-plane"></i> Send
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPost;
