export const slideNoAdd = (data) => {
  return (dispatch) => {
    dispatch({ type: "SLIDE_NO_ADD", payload: { data } });
  };
};

export const slideNoStyleSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "SLIDE_NO_STYLE", payload: { data } });
  };
};

export const roundNess = (data) => {
  return (dispatch) => {
    dispatch({ type: "ROUNDNESS", payload: { data } });
  };
};

export const isSwipe = (data) => {
  return (dispatch) => {
    dispatch({ type: "SWIPE", payload: { data } });
  };
};

export const isBookmark = (data) => {
  return (dispatch) => {
    dispatch({ type: "BOOKMARK", payload: { data } });
  };
};

export const putSwipeText = (data) => {
  return (dispatch) => {
    dispatch({ type: "SWIPE_TEXT", payload: { data } });
  };
};

export const swipeColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "SWIPE_COLOR", payload: { data } });
  };
};

export const bookmarkColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "BOOKMARK_COLOR", payload: { data } });
  };
};

export const customSettingsColorSet = (data) => {
  return (dispatch) => {
    dispatch({ type: "SETTING_COLORPICKER", payload: { data } });
  };
};

export const resetSettings = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_SETTING",
    });
  };
};

export const TemplateSettings = (id) => {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_SETTING",
      payload: id,
    });
  };
};

export const putSwipeIcon = (data) => {
  return (dispatch) => {
    dispatch({ type: "SWIPE_ICON", payload: { data } });
  };
};