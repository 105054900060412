import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './style.css'
import { IMAGE_URL } from '../../api/API';
import { addPreviewPost, togglePreview } from '../../store/features/previewPost/previewPostSlice';

const WeeklyView = (props) => {
  const { user } = useSelector(state => state)
  const userInfo = user?.userData
  // console.log({ userInfo });
  const getweekList = props.selectedWeek
  // console.log({ getweekList });
  const getScheduleData = props.scheduledData[0]
  // console.log({ getScheduleData });

  // here logic is date format is comming with "/" like mm/dd/yyyy and we convert it to "-" like yyyy-mm-dd and find all the group posts of that date
  const getScheduleDatabydate = (day) => {
    // console.log({day});

    if(getweekList[0]?.split('/')[0] === getweekList[1]?.split('/')[0]){
      // console.log("getweekList")
      // console.log(getScheduleDatabydate)
      // console.log("getweekList")
      const initialDate = day.split('/');
      // console.log({initialDate});
      const celenderDate = [initialDate[2], initialDate[0], initialDate[1]].join('-')
      // console.log({ celenderDate });
      const getscheduledatabydate = getScheduleData?.filter((item) => item.date_time.substring(0, 10) === celenderDate)
      // console.log({ getscheduledatabydate });
      return getscheduledatabydate;
      return day?.split('/')[1]
    } else {
      const initialDate = day.split('/');
      // console.log({initialDate});
      const celenderDate = [initialDate[2], initialDate[1], initialDate[0]].join('-')
      // console.log({ celenderDate });
      const getscheduledatabydate = getScheduleData?.filter((item) => item.date_time.substring(0, 10) === celenderDate)
      // console.log({ getscheduledatabydate });
      return getscheduledatabydate;
      return day?.split('/')[0]
    }
    // const initialDate = day.split('/');
    // // console.log({initialDate});
    // const celenderDate = [initialDate[2], initialDate[1], initialDate[0]].join('-')
    // // console.log({ celenderDate });
    // const getscheduledatabydate = getScheduleData?.filter((item) => item.date_time.substring(0, 10) === celenderDate)
    // // console.log({ getscheduledatabydate });
    // return getscheduledatabydate;
  }

  // here we solve the issue of date
  // sometimes date comes like mm/dd/yyyy or dd/mm/yyyy and we find out one dd for showing in frontend 
  const dateIssueSolve = (day) => {
    if(getweekList[0]?.split('/')[0] === getweekList[1]?.split('/')[0]){
      return day?.split('/')[1]
    } else {
      return day?.split('/')[0]
    }
  }

  const dispatch = useDispatch()

  // show preview of the post
  const previewPostCardHandler = (item) => {
    dispatch(addPreviewPost(item))
    dispatch(togglePreview(true))
}

// here how many number of posts present in this date show or not
function handleScroll() {
  var scrollableDiv = document.getElementById('scrollableDivCalender');
  var elementToHide = document.getElementById('elementToHide');

  // Check if the user has scrolled
  if (scrollableDiv.scrollHeight > 0) {
      // User has scrolled, hide the element
      elementToHide.style.display = 'none';
  } else {
      // User is at the top, show the element
      elementToHide.style.display = 'block';
  }
}

  return (
    <>
      <table className='table table-bordered table-responsive weekly-table'>
        <thead>
          <tr>
            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']?.map((dayName) => (
              <th key={dayName}>{dayName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {getweekList?.map((day, dayIndex) => (
              <td key={dayIndex} className={`${getScheduleDatabydate(day)?.length>0?"active-cell":""} ${ day!=="" ?"":"de-active"} `} >
                {dateIssueSolve(day)}
                <br></br>
                {!getScheduleDatabydate(day) ? "" :
                  <>
                    <div className='user-item-data  p-1' onScroll={()=>handleScroll()} id='scrollableDivCalender'>


                      <p>{getScheduleDatabydate(day)?.map((item, i) => {
                          console.log('Schedule item:', item);  // Add this line to log the item
                        const dateFormat = new Date(`${item?.date_time}.000Z`)
                        return (
                          <div key={i} className='border p-3 rounded mb-1 user-schedule-data' onClick={()=>previewPostCardHandler(item)} >
                            <div className='calender-item d-flex justify-content-between' >
                              {/* <i className="fa-brands fa-linkedin m-1" style={{color: "#105ada"}}></i> */}
                              <div>
                                {
                                    item.platformPost?.linkedin && <img src={`${IMAGE_URL}/images/socialIcon/linkedin.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.twitter && <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.facebook && <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                                {
                                    item.platformPost?.instagram && <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} style={{height:'15px', width:'15px', margin:'1px'}}></img>
                                }
                              </div>
                              <div className='d-flex align-items-center'>
                              {item.status === 'PUBLISH' ? (
  <i className="fa-solid fa-check-circle m-1" style={{ color: 'green' }}></i>
) : item.status === 'FAILED' ? (
  <i className="fa-solid fa-exclamation-circle m-1" style={{ color: 'red' }}></i>
) : (
  <i className="fa-regular fa-clock m-1" style={{ color: 'purple' }}></i>
)}
  <p className='ms-0 mb-0' >{dateFormat.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
</div>
                            </div>
                            <p className='m-1' style={{fontSize:"13px"}}>{`${item.postContent?.substring(0, 18)}${item.postContent?.length>18 ? '...' : '' }`}</p>
                          </div>
                        )
                      })}</p>
                    </div>
                  </>}

                  {
                    getScheduleDatabydate(day)?.length - 7 > 0 && <div className='total-number-post-tag'>
                    <p>See {getScheduleDatabydate(day)?.length - 7} more</p>
                  </div>
                  }
                  
              </td>
            ))}
          </tr>
        </tbody>
      </table>

    </>
  )
}

export default WeeklyView