import React, { useEffect, useState } from 'react';
import '../Login/Login.css'
import { BASE_URL, IMAGE_URL, IMAGE_URL_BACKEND, apibase } from '../../api/API';
import { postApi } from '../../api/methods';
import { toast,ToastContainer } from 'react-toastify';

const VerifyMember = () => {
  const [ inviteEmailText, setInviteEmailText ] = useState('')


      const logoutLinkedin = (url) => {
        // Create a promise to wait for the logout process to complete
        return new Promise((resolve, reject) => {
          const win = window.open(
            url,
            '_blank',
            'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none'
          );
    
          // Wait for a short time (e.g., 3 seconds) to ensure the logout process has completed
          setTimeout(() => {
            win.close();
            resolve();
          }, 3000);
        });
      };
    
      // handle logout from growero website
      const hendellogout = async () => {
        try {
          // Remove the token from localStorage
          localStorage.removeItem('token');
          // Call the logoutLinkedin function to log the user out of LinkedIn
          await logoutLinkedin('https://linkedin.com/m/logout');
          // After successful logout from LinkedIn, redirect to the LinkedIn authentication URL
          const redirectURL = `${apibase}/api/auth/callback/linkedin?isFromAccount=true`;
          // Redirect the user to the LinkedIn authentication URL
          window.location.href = redirectURL;
        } catch (error) {
          console.error('Error during logout:', error);
        }
      };


    const VerifyAcount = async () => {
  // Check if the email field is empty
  if (inviteEmailText?.length === 0) {
    toast.error('Email field needs to be filled.');
  } else {
    // Check if the email is valid
    if (!inviteEmailText.includes('@') || !inviteEmailText.includes('.')) {
      toast.error('Invalid email address.');
    } else {
      // Split the email into username, host, and domain
      const [emailUsername, emailHosting] = inviteEmailText.split('@');
      const [emailHost, emailDomain] = emailHosting.split('.');

      // Check if the email parts meet the minimum length requirements
      if (emailUsername?.length > 3 && emailHost?.length > 3 && emailDomain?.length > 1) {
        try {
          // Send a request to the backend to invite the team member
          const response = await postApi(`invite/get-verify-email`, { email: inviteEmailText });
          const data = response.data;

          // Check if the response email matches the input email
          if (data.email === inviteEmailText) {
            hendellogout(); // Logout the user
          } else {
            toast.error(data.message); // Display error message from the backend
          }
        } catch (error) {
          console.error('Error inviting team member:', error);
          toast.error(error.response.data.message); // Display error message from the backend
        }
      } else {
        toast.error('Invalid email address.');
      }
    }
  }
};
    const img_src = [
      `${IMAGE_URL}/images/loginbgicons/calendar.jpg`
      ,
      `${IMAGE_URL}/images/loginbgicons/discover.jpg`
      ,
      `${IMAGE_URL}/images/loginbgicons/star.jpg`
      ,
      `${IMAGE_URL}/images/loginbgicons/star.jpg`
  
    ]
    const iconList = img_src.flatMap((item) => Array(7).fill(item));

    return (
      <div className='login-container'>
          {iconList?.map((item, index) => {
           return(
            <div className='bg-icon' key={index}>
              <img src={item} alt='bg-icons' />
            </div>
           )
          })}
    
        <div className="login-box">
          <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
          <h3>
            <input className='form-control' type="email" placeholder='Email' name="" style={{border: '1px solid #1292B1'}} value={inviteEmailText} onChange={e=>setInviteEmailText(e.target.value)} required/>
          </h3>
          <button className="btn btn-primary" onClick={VerifyAcount}>Verify</button>
        </div>
        <ToastContainer />
      </div>
    )
}

export default VerifyMember