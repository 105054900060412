import React from 'react';
import QueueItems from '../queueItems/QueueItems';
import { useDispatch, useSelector } from 'react-redux';
import { 
  addAIPost, 
  addDraftPostId, 
  addOriginalUrl, 
  changeConnectionType, 
  changePostSelection, 
  changePostType 
} from '../../../../store/features/post/postSlice';
import { useNavigate } from 'react-router-dom';
import { convertTo12HourFormat } from '../../../../utility/convertTo12HourFormat.js';
import { deleteApi } from '../../../../api/methods.js';
import { deleteMyQueueData } from '../../../../store/features/scheduleData/scheduleDataSlice.js';

const QueueContainer = (props) => {
  const { timeSlote } = useSelector(state => state.user.userChosenTopics); // Array of times like ["11:00", "16:00"]
  const { isDark } = useSelector(state => state.common);
  const { userTimeZone } = useSelector(state => state.user); // Assuming the user's time zone is in the state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  let convertDate = props?.date?.split('-');
  convertDate = convertDate ? [convertDate[2], convertDate[1], convertDate[0]].join('-') : '';

  // Helper function to check if date is valid
  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  // Helper functions for checking today, yesterday, and tomorrow
  const isToday = (date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
           date.getMonth() === today.getMonth() &&
           date.getDate() === today.getDate();
  };

  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getFullYear() === yesterday.getFullYear() &&
           date.getMonth() === yesterday.getMonth() &&
           date.getDate() === yesterday.getDate();
  };

  const isTomorrow = (date) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date.getFullYear() === tomorrow.getFullYear() &&
           date.getMonth() === tomorrow.getMonth() &&
           date.getDate() === tomorrow.getDate();
  };

  // Edit the draft post and redirect to post-idea page of composer
  const editPost = (item) => {
    const { postId, postContent, date_time, connectionType, postType, originalUrl } = item;
    dispatch(addAIPost(postContent));
    dispatch(changeConnectionType(connectionType));
    dispatch(addDraftPostId(postId));
    dispatch(changePostType(postType));
    dispatch(addOriginalUrl(originalUrl));
    if (item.status === 'PENDING') {
      dispatch(changePostSelection('schedule-post'));
    }
    deleteApi(`schedule/delete-schedule-post/${item._id}`);
    dispatch(deleteMyQueueData(item._id));
    navigate('/ai-content');
  };

  // Preview the draft post
  const previewPost = (item) => {
    const { postId, postContent, connectionType, postType, originalUrl } = item;
    dispatch(addAIPost(postContent));
    dispatch(changeConnectionType(connectionType));
    dispatch(addDraftPostId(postId));
    dispatch(changePostType(postType));
    dispatch(addOriginalUrl(originalUrl));
  };

  // Delete the draft post with confirmation
  const deleteQueueConfirm = (id) => {
    const confirmation = window.confirm('Are you sure?');
    if (confirmation) {
      deleteApi(`schedule/delete-schedule-post/${id}`);
      dispatch(deleteMyQueueData(id));
    }
  };

  // Function to format a date/time in the user's selected time zone
  function formatTimeInTimeZone(date, timeZone) {
    if (!isValidDate(date)) return 'Invalid Date'; // Return early if date is invalid

    const options = {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Ensure 12-hour format
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  // Function to combine the selected date and time slot into a valid Date object
  function getCombinedDateTime(date, timeSlot) {
    const [day, month, year] = date.split('-'); // Expecting DD-MM-YYYY format
    const [hours, minutes] = timeSlot.split(':'); // "HH:MM" format

    // Combine the date and time parts using local time zone
    const combinedDateTime = new Date(year, month - 1, day, hours, minutes);

    if (!isValidDate(combinedDateTime)) {
      console.error('Invalid date:', combinedDateTime);
      return new Date(); // Fallback to current date if invalid
    }

    return combinedDateTime;
  }

  // Convert time slots to 24-hour format if necessary
  let timeSlotConvert = timeSlote || [];

  // Combine scheduled posts and empty slots
  const combinedSlots = [
    ...(Array.isArray(props.data) ? props.data : []),
    ...timeSlotConvert.map(slot => ({
      date_time: getCombinedDateTime(convertDate, slot), // Combine date and time
      postContent: 'You have not scheduled any post'
    }))
  ];

  // Sort combined slots
  const sortedSlots = combinedSlots.sort((a, b) => {
    const aTime = new Date(a.date_time);
    const bTime = new Date(b.date_time);
    return aTime - bTime;
  });

  return (
    <div className="queue-post-container">
      <div className="queue-date">
        <p style={isDark ? { color: '#000' } : { color: '#fff' }}>
          {convertDate} {
            props.date && (
              isYesterday(new Date(props.date)) ? ' | Yesterday' :
              isToday(new Date(props.date)) ? ' | Today' :
              isTomorrow(new Date(props.date)) ? ' | Tomorrow' :
              ''
            )
          }
        </p>
      </div>

      {sortedSlots.map((item, i) => {
        const convertDate = new Date(item.date_time);
        const formattedTimeInUserTimeZone = formatTimeInTimeZone(convertDate, userTimeZone); // User's selected time zone
        const actualTime = formattedTimeInUserTimeZone !== 'Invalid Date' ? formattedTimeInUserTimeZone : 'Invalid Time';

        return (
          <QueueItems
            key={i}
            actualTime={actualTime}
            data={item}
            editPost={editPost}
            previewPost={previewPost}
            deleteQueueConfirm={deleteQueueConfirm}
          />
        );
      })}
    </div>
  );
};

export default QueueContainer;