import { templates } from "../../../../lib/templates";

const initialState = {
  slideNo: true,
  slideNoStyle: "round",
  round: "0.5",
  swipe: true,
  bookmark: true,
  swipeText: "",
  swipeIcon: "swipeArr",
  swipeColor: "#006838",
  bookmarkColor: "#006838",
  settingColorPicker: "true",
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SLIDE_NO_ADD": {
      return {
        ...state,
        slideNo: action.payload.data,
      };
    }
    case "SLIDE_NO_STYLE": {
      if (action.payload.data)
        return {
          ...state,
          slideNoStyle: action.payload.data,
        };
    }
    case "ROUNDNESS": {
      if (action.payload.data)
        return {
          ...state,
          round: action.payload.data,
        };
    }
    case "SWIPE": {
      return {
        ...state,
        swipe: action.payload.data,
      };
    }
    case "BOOKMARK": {
      return {
        ...state,
        bookmark: action.payload.data,
      };
    }
    case "SWIPE_TEXT": {
      const { data } = action.payload;
      return {
        ...state,
        swipeText: data || "",
      };
    }
    case "SWIPE_ICON": {
      if (action.payload.data)
        return {
          ...state,
          swipeIcon: action.payload.data,
        };
    }
    case "SWIPE_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          swipeColor: action.payload.data,
        };
      }
    }
    case "BOOKMARK_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          bookmarkColor: action.payload.data,
        };
      }
    }
    case "SETTING_COLORPICKER": {
      return {
        ...state,
        settingColorPicker: action.payload.data,
      };
    }
    case "RESET_SETTING": {
      return initialState;
    }
    case "TEMPLATE_SETTING": {
      const id = action.payload;
      const matchedTemplate = templates.find((template) => template.id === id);
      if (matchedTemplate) {
        return {
          slideNo: matchedTemplate.slideNo,
          slideNoStyle: matchedTemplate.slideNoStyle,
          round: matchedTemplate.round,
          swipe: matchedTemplate.swipe,
          bookmark: matchedTemplate.bookmark,
          swipeText: matchedTemplate.swipeText,
          swipeIcon: matchedTemplate.swipeIcon,
          swipeColor: matchedTemplate.swipeColor,
          bookmarkColor: matchedTemplate.bookmarkColor,
        };
      } else return state;
    }
    default:
      return state;
  }
};

export default settingsReducer;
