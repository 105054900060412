import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAnalyticsData } from "../../store/features/analytics/analyticsAction";

function Table() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const { posts, userId, dateRange } = useSelector((state) => state.analytics);
  const { userData } = useSelector((state) => state.user);
  
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchAnalyticsData(userId, startDate.toISOString(), endDate.toISOString()));
    }
  }, [userId, startDate, endDate, dispatch]);

  const sortPosts = (posts) => {
    if (Array.isArray(posts)) {
      return posts.sort((a, b) => {
        let comparison = 0;
        if (sortColumn === "createdAt") {
          const dateA = new Date(a[sortColumn]);
          const dateB = new Date(b[sortColumn]);
          comparison = sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortColumn === "likes") {
          comparison =
            sortOrder === "asc"
              ? a[sortColumn] - b[sortColumn]
              : b[sortColumn] - a[sortColumn];
        } else if (sortColumn === "comments") {
          comparison =
            sortOrder === "asc"
              ? a[sortColumn] - b[sortColumn]
              : b[sortColumn] - a[sortColumn];
        }
        return comparison;
      });
    } else {
      return [];
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleDateChange = (type, date) => {
    if (type === 'start') {
      setStartDate(new Date(date));
    } else {
      setEndDate(new Date(date));
    }
  };

  const sortedPosts = sortPosts(posts || []);

  return (
    <>
        <table className="table table-dark max-md:text-[10px]" id="table" style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
      <thead>
        <tr
          className=""
          style={{
            backgroundColor: "#FFFFFF00",
            color: isDark ? "black" : "white",
          }}
        >
          <th
            scope="col"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            title="User Profile"
          >
            <img
              src={`${
                isDark ? "/images/profileBlack.svg" : "/images/profile.svg"
              }`}
              className="max-md:min-w-[10px]"
            />
          </th>
          <th
            scope="col"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            title="Post Content"
          >
            <img
              src={`${isDark ? "images/bookBlack.svg" : "images/book.svg"}`}
            />
          </th>
          <th
            scope="col"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            className="cursor-pointer"
            title="Time of Post Creation"
            onClick={() => {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
              setSortColumn("createdAt");
            }}
          >
            <img
              src={`${isDark ? "/images/clockBlack.svg" : "/images/clock.svg"}`}
            />
          </th>
          <th
            scope="col"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            title="Likes Count"
            className="cursor-pointer"
            onClick={() => {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
              setSortColumn("likes");
            }}
          >
            <img
              src={`${isDark ? "/images/likeBlack.svg" : "/images/like.svg"}`}
            />
          </th>
          <th
            scope="col"
            className="cursor-pointer"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            title="Comments Count"
            onClick={() => {
              setSortOrder(sortOrder === "asc" ? "desc" : "asc");
              setSortColumn("comments");
            }}
          >
            <img
              src={`${
                isDark ? "/images/commentBlack.svg" : "/images/comment.svg"
              }`}
            />
          </th>
          <th
            scope="col"
            style={{
              backgroundColor: "#FFFFFF00",
              color: isDark ? "black" : "white",
            }}
            title="Action"
          >
            -
          </th>
        </tr>
      </thead>
          <tbody style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
          {sortedPosts.length > 0 ? (
            sortedPosts.map((post, index) => (
              <tr key={post.postId} style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
                <th scope="row" style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
                  <img src={userData.linkedinProfile || "/images/pp.jpeg"} className="w-6" style={{ borderRadius: "50%" }} alt="Profile" />
                </th>
                <td style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
                  {post.postContent.length > 30 ? `${post.postContent.slice(0, 30)}...` : post.postContent}
                </td>
                <td style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
                  {formatDate(post.createdAt)}
                </td>
                <td style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
  {post.likes !== undefined ? post.likes : 'N/A'}
</td>
<td style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}}>
  {post.comments !== undefined ? post.comments : 'N/A'}
</td>
                <td style={{backgroundColor: "#FFFFFF00", color: isDark ? "black" : "white"}} className="flex gap-2 items-center pb-4">
                  <img
                    className="cursor-pointer"
                    src={hoveredRowIndex === index ? (isDark ? "/images/circleSearchBlack.svg" : "/images/circleSearch.svg") : (isDark ? "/images/searchBlack.svg" : "/images/search.svg")}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => navigate("/postAnalytics", { state: { postId: post.postId } })}
                    style={{ borderRadius: "50%" }}
                    alt="Search"
                  />
                  <a href={post.linkedinPostUrl} target="_blank" rel="noopener noreferrer">
                    <img src="/images/linkedInIcon.svg" className="cursor-pointer max-md:min-w-[15px] min-h-[30px]" alt="LinkedIn" />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{backgroundColor: "#FFFFFF00", textAlign: "center", color: isDark ? "black" : "white"}}>
                No posts found in the selected date range
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default Table;
