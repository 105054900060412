import fontSizeImg from "../../../../src/assets/fontSize.svg";
import fontSizeWhiteImg from "../../../../src/assets/fontSizeWhite.svg";
import centerAlign2 from "../../../../src/assets/centerAlign2.svg";
import rightAlign2 from "../../../../src/assets/rightAlign2.svg";
import centerAlign2White from "../../../../src/assets/centerAlign2White.svg";
import rightAlign2White from "../../../../src/assets/rightAlign2White.svg";
import verticalUp from "../../../../src/assets/verticalUp.svg";
import verticalCenter from "../../../../src/assets/verticalCenter.svg";
import verticalDown from "../../../../src/assets/verticalDown.svg";
import verticalUpWhite from "../../../../src/assets/verticalUpWhite.svg";
import verticalCenterWhite from "../../../../src/assets/verticalCenterWhite.svg";
import verticalDownWhite from "../../../../src/assets/verticalDownWhite.svg";
import leftAlign2 from "../../../../src/assets/leftAlign2.svg";
import leftAlign2White from "../../../../src/assets/leftAlign2White.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "../../../components/ui/switch";
import {
  customColorSet,
  customPairSet,
  descColorSet,
  fontStyle,
  pageNoColorSet,
  subTitleColorSet,
  textAlignment,
  titleColorSet,
  verticalAlignment,
} from "../../../store/features/carousel/action/textAction";
import { fontSize } from "../../../store/features/carousel/action/textAction";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

function TextBar() {
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const {
    titleFontType,
    bodyFontType,
    fontSizeTitle,
    fontSizeDesc,
    textAlign,
    verticalAlign,
    customPair,
    titleColor,
    descColor,
    subTitleColor,
    textColorPicker,
    pageNoColor,
  } = useSelector((state) => state.textReducer);

  useEffect(() => {
    const colorInp1 = document.getElementById("colorInp1");
    const colorPick1 = document.getElementById("colorPicker1");
    const colorInp2 = document.getElementById("colorInp2");
    const colorPick2 = document.getElementById("colorPicker2");
    const colorInp3 = document.getElementById("colorInp3");
    const colorPick3 = document.getElementById("colorPicker3");
    const colorInp4 = document.getElementById("colorInp4");
    const colorPick4 = document.getElementById("colorPicker4");
    if (
      colorInp1 &&
      colorPick1 &&
      colorInp2 &&
      colorPick2 &&
      colorInp3 &&
      colorPick3 &&
      colorInp4 &&
      colorPick4
    ) {
      colorInp1.disabled = !textColorPicker;
      colorPick1.disabled = !textColorPicker;
      colorInp2.disabled = !textColorPicker;
      colorPick2.disabled = !textColorPicker;
      colorInp3.disabled = !textColorPicker;
      colorPick3.disabled = !textColorPicker;
      colorInp4.disabled = !textColorPicker;
      colorPick4.disabled = !textColorPicker;
    }
    if (!textColorPicker) {
      dispatch(titleColorSet(titleColor));
      dispatch(subTitleColorSet(subTitleColor));
      dispatch(descColorSet(descColor));
      dispatch(pageNoColorSet(pageNoColor));
    }
  }, [textColorPicker]);

  const handleFontStyle = (titleFont) => {
    const data = {
      bodyFont: titleFont,
      titleFont: titleFont,
    };
    dispatch(fontStyle(data));
  };

  const handleTitleFontStyle = (titleFont) => {
    const data = {
      bodyFont: bodyFontType,
      titleFont: titleFont,
    };
    dispatch(fontStyle(data));
  };

  const handleBodyFontStyle = (bodyFont) => {
    const data = {
      bodyFont: bodyFont,
      titleFont: titleFontType,
    };
    dispatch(fontStyle(data));
  };

  const handleCustomPair = (data) => {
    dispatch(customPairSet(data));
  };

  const handleFontSize = (subTitleSize, descSize, titleSize) => {
    const data = {
      fontSizeSubtitle: subTitleSize,
      fontSizeTitle: titleSize,
      fontSizeDesc: descSize,
    };
    dispatch(fontSize(data));
  };

  const handleTextAlign = (data) => {
    dispatch(textAlignment(data));
  };

  const handleVerticalAlign = (data) => {
    dispatch(verticalAlignment(data));
  };

  const handleTextColorPicker = (data) => {
    dispatch(customColorSet(data));
  };

  const handleTitleColor = (data) => {
    dispatch(titleColorSet(data));
  };

  const handleSubtitleColor = (data) => {
    dispatch(subTitleColorSet(data));
  };

  const handleDescColor = (data) => {
    dispatch(descColorSet(data));
  };

  const handlePageNoColor = (data) => {
    dispatch(pageNoColorSet(data));
  };

  return (
    <>
      <div className="py-6 px-3 flex flex-col gap-3">
        <p className="font-bold">Text</p>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col w-[290px] gap-1">
            <label
              className="text-sm font-semibold"
              style={{ color: isDark ? "black" : "white" }}
            >
              Fonts Pair
            </label>
            <Select
              className=""
              onValueChange={handleFontStyle}
              disabled={customPair}
              value={titleFontType === bodyFontType ? titleFontType : ""}
            >
              <SelectTrigger
                className={`${isDark ? " text-black" : " text-white"}`}
              >
                <SelectValue placeholder="Fonts Pair" />
              </SelectTrigger>
              <SelectContent
                className={`${
                  isDark ? "bg-white text-black" : "bg-black text-white"
                }`}
              >
                <SelectItem value="Poppins">Poppins</SelectItem>
                <SelectItem value="Cardo">Cardo</SelectItem>
                <SelectItem value="Space Mono">Space Mono</SelectItem>
                <SelectItem value="DM Sans">DM Sans</SelectItem>
                <SelectItem value="Syne">Syne</SelectItem>
                <SelectItem value="Oswald">Oswald</SelectItem>
                <SelectItem value="League Gothic">League Gothic</SelectItem>
                <SelectItem value="Montserrat">Montserrat</SelectItem>
                <SelectItem value="Rubik">Rubik</SelectItem>
                <SelectItem value="Anton">Anton</SelectItem>
                <SelectItem value="Teko">Teko</SelectItem>
                <SelectItem value="Expletus Sans">Expletus Sans</SelectItem>
                <SelectItem value="Bebas">Bebas</SelectItem>
                <SelectItem value="Alfa Slab One">Alfa Slab One</SelectItem>
                <SelectItem value="Archivo Black">Archivo Black</SelectItem>
                <SelectItem value="Della Respira">Della Respira</SelectItem>
                <SelectItem value="Rozha One">Rozha One</SelectItem>
                <SelectItem value="Lora">Lora</SelectItem>
                <SelectItem value="Playfair Display">
                  Playfair Display
                </SelectItem>
                <SelectItem value="Fjalla One">Fjalla One</SelectItem>
                <SelectItem value="Yellowtail">Yellowtail</SelectItem>
                <SelectItem value="Philospher">Philospher</SelectItem>
                <SelectItem value="Ultra">Ultra</SelectItem>
                <SelectItem value="Stint Ultra Expanded">
                  Stint Ultra Expanded
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex gap-2 items-center">
            {/* <label className="switch">
            <input
              type="checkbox"
              checked={customPair}
              onChange={() =>handleCustomPair(!customPair)}
            />
            <span class="slider round"></span>
          </label> */}
            <Switch
              checked={customPair}
              onCheckedChange={() => handleCustomPair(!customPair)}
              className="bg-blue-500 data-[state=checked]:bg-blue-500"
            />
            <p className="text-sm font-semibold">Custom Font Pairing</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm font-semibold">Title Font</p>
            <Select
              onValueChange={handleTitleFontStyle}
              disabled={!customPair}
              value={titleFontType}
            >
              <SelectTrigger
                className={`${isDark ? " text-black" : " text-white"}`}
              >
                <SelectValue placeholder="Fonts Pair" />
              </SelectTrigger>
              <SelectContent
                className={`${
                  isDark ? "bg-white text-black" : "bg-black text-white"
                }`}
              >
                <SelectItem value="Poppins">Poppins</SelectItem>
                <SelectItem value="Cardo">Cardo</SelectItem>
                <SelectItem value="Space Mono">Space Mono</SelectItem>
                <SelectItem value="DM Sans">DM Sans</SelectItem>
                <SelectItem value="Syne">Syne</SelectItem>
                <SelectItem value="Oswald">Oswald</SelectItem>
                <SelectItem value="League Gothic">League Gothic</SelectItem>
                <SelectItem value="Montserrat">Montserrat</SelectItem>
                <SelectItem value="Rubik">Rubik</SelectItem>
                <SelectItem value="Anton">Anton</SelectItem>
                <SelectItem value="Teko">Teko</SelectItem>
                <SelectItem value="Expletus Sans">Expletus Sans</SelectItem>
                <SelectItem value="Bebas">Bebas</SelectItem>
                <SelectItem value="Alfa Slab One">Alfa Slab One</SelectItem>
                <SelectItem value="Archivo Black">Archivo Black</SelectItem>
                <SelectItem value="Della Respira">Della Respira</SelectItem>
                <SelectItem value="Rozha One">Rozha One</SelectItem>
                <SelectItem value="Lora">Lora</SelectItem>
                <SelectItem value="Playfair Display">
                  Playfair Display
                </SelectItem>
                <SelectItem value="Fjalla One">Fjalla One</SelectItem>
                <SelectItem value="Yellowtail">Yellowtail</SelectItem>
                <SelectItem value="Philospher">Philospher</SelectItem>
                <SelectItem value="Ultra">Ultra</SelectItem>
                <SelectItem value="Stint Ultra Expanded">
                  Stint Ultra Expanded
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm font-semibold">Body Font</p>
            <Select
              onValueChange={handleBodyFontStyle}
              disabled={!customPair}
              value={bodyFontType}
            >
              <SelectTrigger
                className={`${isDark ? " text-black" : " text-white"}`}
              >
                <SelectValue placeholder="Fonts Pair" />
              </SelectTrigger>
              <SelectContent
                className={`${
                  isDark ? "bg-white text-black" : "bg-black text-white"
                }`}
              >
                <SelectItem value="Poppins">Poppins</SelectItem>
                <SelectItem value="Cardo">Cardo</SelectItem>
                <SelectItem value="Space Mono">Space Mono</SelectItem>
                <SelectItem value="DM Sans">DM Sans</SelectItem>
                <SelectItem value="Syne">Syne</SelectItem>
                <SelectItem value="Oswald">Oswald</SelectItem>
                <SelectItem value="League Gothic">League Gothic</SelectItem>
                <SelectItem value="Montserrat">Montserrat</SelectItem>
                <SelectItem value="Rubik">Rubik</SelectItem>
                <SelectItem value="Anton">Anton</SelectItem>
                <SelectItem value="Teko">Teko</SelectItem>
                <SelectItem value="Expletus Sans">Expletus Sans</SelectItem>
                <SelectItem value="Bebas">Bebas</SelectItem>
                <SelectItem value="Alfa Slab One">Alfa Slab One</SelectItem>
                <SelectItem value="Archivo Black">Archivo Black</SelectItem>
                <SelectItem value="Della Respira">Della Respira</SelectItem>
                <SelectItem value="Rozha One">Rozha One</SelectItem>
                <SelectItem value="Lora">Lora</SelectItem>
                <SelectItem value="Playfair Display">
                  Playfair Display
                </SelectItem>
                <SelectItem value="Fjalla One">Fjalla One</SelectItem>
                <SelectItem value="Yellowtail">Yellowtail</SelectItem>
                <SelectItem value="Philospher">Philospher</SelectItem>
                <SelectItem value="Ultra">Ultra</SelectItem>
                <SelectItem value="Stint Ultra Expanded">
                  Stint Ultra Expanded
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <p className="text-sm font-semibold"> Font Size</p>
              <div className="flex gap-2">
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    fontSizeTitle == "xl" && fontSizeDesc == "base"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="small"
                  onClick={() => handleFontSize("xs", "base", "xl")}
                >
                  <img
                    src={isDark ? fontSizeImg : fontSizeWhiteImg}
                    width="16px"
                  ></img>
                </div>
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    fontSizeTitle == "2xl" && fontSizeDesc == "lg"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="medium"
                  onClick={() => handleFontSize("sm", "lg", "2xl")}
                >
                  <img
                    src={isDark ? fontSizeImg : fontSizeWhiteImg}
                    width="18px"
                  ></img>
                </div>
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    fontSizeTitle == "3xl" && fontSizeDesc == "xl"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="large"
                  onClick={() => handleFontSize("md", "xl", "3xl")}
                >
                  <img
                    src={isDark ? fontSizeImg : fontSizeWhiteImg}
                    width="20px"
                  ></img>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-sm font-semibold"> Text Align</p>
              <div className="flex gap-2">
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    textAlign == "left"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="Left Align"
                  onClick={() => handleTextAlign("left")}
                >
                  {" "}
                  <img
                    src={isDark ? leftAlign2 : leftAlign2White}
                    width="20px"
                  ></img>
                </div>
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    textAlign == "center"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="Center Align"
                  onClick={() => handleTextAlign("center")}
                >
                  {" "}
                  <img
                    src={isDark ? centerAlign2 : centerAlign2White}
                    width="20px"
                  ></img>
                </div>
                <div
                  className={`border-[1px] tooltipClass rounded-md ${
                    textAlign == "right"
                      ? isDark
                        ? "border-black"
                        : "border-gray-200"
                      : isDark
                      ? "border-gray-200"
                      : "border-black"
                  } p-2 flex items-center justify-center cursor-pointer`}
                  title="Right Align"
                  onClick={() => handleTextAlign("right")}
                >
                  {" "}
                  <img
                    src={isDark ? rightAlign2 : rightAlign2White}
                    width="20px"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm font-semibold"> Vertical Align</p>
            <div className="flex gap-2">
              <div
                className={`border-[1px] tooltipClass rounded-md ${
                  verticalAlign == "start"
                    ? isDark
                      ? "border-black"
                      : "border-gray-200"
                    : isDark
                    ? "border-gray-200"
                    : "border-black"
                } p-2 flex items-center justify-center cursor-pointer`}
                title="Up"
                onClick={() => handleVerticalAlign("start")}
              >
                {" "}
                <img
                  src={isDark ? verticalUp : verticalUpWhite}
                  width="16px"
                ></img>
              </div>
              <div
                className={`border-[1px] tooltipClass rounded-md ${
                  verticalAlign == "center"
                    ? isDark
                      ? "border-black"
                      : "border-gray-200"
                    : isDark
                    ? "border-gray-200"
                    : "border-black"
                } p-2 flex items-center justify-center cursor-pointer`}
                title="Center"
                onClick={() => handleVerticalAlign("center")}
              >
                {" "}
                <img
                  src={isDark ? verticalCenter : verticalCenterWhite}
                  width="16px"
                ></img>
              </div>
              <div
                className={`border-[1px] tooltipClass rounded-md ${
                  verticalAlign == "end"
                    ? isDark
                      ? "border-black"
                      : "border-gray-200"
                    : isDark
                    ? "border-gray-200"
                    : "border-black"
                } p-2 flex items-center justify-center cursor-pointer`}
                title="Down"
                onClick={() => handleVerticalAlign("end")}
              >
                {" "}
                <img
                  src={isDark ? verticalDown : verticalDownWhite}
                  width="16px"
                ></img>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col gap-2  mt-2"> */}
          {/* <div className="flex items-center justify-between border-2 border-gray-200 p-2 rounded-lg ">
        <p>Custom Colors</p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={textColorPicker}
                  onChange={()=>handleTextColorPicker(!textColorPicker)}
                />
                <span class="slider round"></span>
              </label>
              </div>
              <div className="flex flex-col">
              <div className="relative mt-1">
                <label>Title Color</label>
                        <input type='text' 
                        value={titleColor} onChange={(e)=>handleTitleColor(e.target.value)}
                         id='colorInp1' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker1' 
                               value={titleColor} onChange={(e)=>handleTitleColor(e.target.value)}
                                >    
                                </input>
                            </div>
                    </div>
                    <div className="relative -mt-4">
                    <label>Description Color</label>
                        <input type='text' 
                        value={descColor} onChange={(e)=>handleDescColor(e.target.value)}
                         id='colorInp2' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker2' 
                                value={descColor} onChange={(e)=>handleDescColor(e.target.value)}
                                >
                                  
                                </input>
                            </div>
                    </div>
                    <div className="relative -mt-4">
                    <label>Subtitle Color</label>
                        <input type='text' 
                        value={subTitleColor} onChange={(e)=>handleSubtitleColor(e.target.value)}
                         id='colorInp3' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker3' 
                               value={subTitleColor} onChange={(e)=>handleSubtitleColor(e.target.value)}
                                >
                                  
                                </input>
                            </div>
                    </div>
                    <div className="relative -mt-4">
                    <label>Page No. Color</label>
                        <input type='text' 
                        value={pageNoColor} onChange={(e)=>handlePageNoColor(e.target.value)}
                         id='colorInp4' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker4' 
                               value={pageNoColor} onChange={(e)=>handlePageNoColor(e.target.value)}
                                >
                                  
                                </input>
                            </div>
                    </div>
                    </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default TextBar;
