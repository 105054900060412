import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "./initialState";
import { getApi } from "../../../../api/methods";

// fetch all users list for admin
export const fetchUserSubscriptions = createAsyncThunk('user/fetchUserSubscriptions', async (_, thunkAPI) => {
    try {
        const response = await getApi('user/get-all-user');
        return response.data;
    } catch (error) {
        throw error;
    }
});

const userSlice = createSlice({
    name: 'User',
    initialState: User,
    reducers: {
        // Your reducer functions if needed
    },

})

export default userSlice.reducer;