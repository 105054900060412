export const POST_GENERATION_TYPES = {
  AI_CONTENT: 'AI_CONTENT',
  DISCOVER: 'DISCOVER',
  ON_THE_GO: 'ON_THE_GO',
  CUSTOM: 'CUSTOM'
};    

export const postState = {
  postId: '',
  draftPostId: '',
  postContent: '',
  connectionType: 'PUBLIC',
  postType: 'NONE',
  mediaDis: [],
  mediaTitle: [],
  media: [],
  originalUrl:'',
  postSelection:'create-post',
  postGenerationType: POST_GENERATION_TYPES.CUSTOM, // Default to CUSTOM
}