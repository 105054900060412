import React, { useEffect, useState } from 'react'
import './MobileNotification.css'
import smartphone from '../../assets/smartphone.png'
import { useSelector,useDispatch } from 'react-redux'

const MobileNotification = () => {

    const dispatch=useDispatch();
    const user=useSelector(state=>state.user)
    const {isDark}=useSelector(state=>state.common)

  return (
    <div className='flex justify-center items-center h-screen'>
  <div className='text-center'>
    <img src={smartphone} alt='smartphone' className={isDark?'w-20 h-20 mb-4 ml-[40%]':'w-20 h-20 mb-4 ml-[40%] invert'}/>
    <h3 className='text-lg'>Seems like you are on a mobile device.</h3>
    <div className={'text-sm text-gray-600'}>
      <p style={{color: 'rgb(75 85 99 / var(--tw-text-opacity))'}}>This editor isn't supported on mobile (yet).</p>
      <p style={{color: 'rgb(75 85 99 / var(--tw-text-opacity))'}}>Please try again on a computer</p>
    </div>
  </div>
</div>)
}

export default MobileNotification