import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { discoverState } from "./initialState"
import { getApi } from "../../../api/methods"

const discoverSlice = createSlice({
    name: 'Discover',
    initialState : discoverState,
    reducers: {
        // add discover data to store based on page store all discover datas
        addDiscoverData: (state, action) => {

            if(action.payload.isNew){
                return { ...state, discovers : action.payload.data }
            } else {
                return { ...state, discovers : [ ...state.discovers, ...action.payload.data] }

            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDiscoverCategory.fulfilled, (state,action)=>{
            console.log('user/fetchDiscoverCategory success');

            return { ...state, category:action.payload }
        })
    }
})

// fetch all discover category that store in the database
export const fetchDiscoverCategory = createAsyncThunk('discover/get-discover-category',async ()=>{
    const response = await getApi(`discover/get-discover-category`)
    return  response.data
})

export const { addDiscoverData } = discoverSlice.actions
export default discoverSlice.reducer