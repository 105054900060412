import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { userState } from "./initialState"
import { getApi } from "../../../api/methods";
import axios from "axios";
import Cookies  from 'universal-cookie';
const cookies = new Cookies();
import { selectToken } from './authSlice';

const userSlice = createSlice({
    name: 'User',
    initialState : userState,
    reducers: {
        // here add topics for otg category
        addTopics: (state,action)=>{
            return { ...state, userChosenTopics:{topics: [action.payload]}}
        },

        // here manupulate notification for read status that is filter out from state
        deleteNotification: (state,action)=>{
            const filteredPersonal = state.notifications.personalizedNotifications.filter((item)=>item._id !== action.payload)
            const filteredCommon = state.notifications.commonNotifications.filter((item)=>item._id !== action.payload)

            return { ...state, notifications: { personalizedNotifications:filteredPersonal, commonNotifications:filteredCommon }}
        },
        deleteAllNotifications: (state,action)=>{
            return { 
                ...state, 
                notifications: { 
                    personalizedNotifications: [], 
                    commonNotifications: [] 
                } 
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.fulfilled, (state,action)=>{
            console.log('fetchUserInfo success');
            state = { ...state, teamMembers: [], userChosenTopics: { topics:[], timeSlote:[] } }
            return { ...state, ...action.payload }
        })
        builder.addCase(fetchUserInfo.rejected, (state,action)=>{
            console.warn('fetchUserInfo error');
            localStorage.removeItem('token')
            // window.location.href = '/login'
            return userState
        })
        builder.addCase(fetchUserPhoto.fulfilled, (state,action)=>{
            console.log('user/getPhoto success');
            return { ...state, userPhoto: action.payload }
        })
        builder.addCase(fetchUserPhoto.rejected, (state,action)=>{
            console.warn('user/getPhoto error');
            return { ...state }
        })
        builder.addCase(fetchDashboard.fulfilled, (state, action) => {
            console.log('Updating dashboard state with:', action.payload);
            return { ...state, counter: action.payload };
          });
        builder.addCase(fetchDashboard.rejected, (state,action)=>{
            console.warn('fetchDashboard error');
            return userState
        })
        builder.addCase(fetchUserTopics.fulfilled, (state,action)=>{
            console.log('fetchUserTopics success');
            return { ...state, userChosenTopics:action.payload }
        })
        builder.addCase(fetchUserTopics.rejected, (state,action)=>{
            console.warn('fetchUserTopics error');
            return userState
        })
        builder.addCase(fetchUserNotifications.fulfilled, (state,action)=>{
            console.log('fetchUserNotifications success');
            return { ...state, notifications:action.payload  }
        })
        builder.addCase(fetchUserNotifications.rejected, (state,action)=>{
            console.warn('fetchUserNotifications error');
            return userState
        })
    }
})

// fetch user info by given token
export const fetchUserInfo = createAsyncThunk('user/getInfo', async (_, thunkAPI) => {
    try {
      const token = selectToken(thunkAPI.getState());
      const response = await getApi(`user/get-user-by-token/${token}`);
      console.log(response);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
  

// fetch user photo 
export const fetchUserPhoto = createAsyncThunk('user/getPhoto',async (_,thunkAPI)=>{
    const token = selectToken(thunkAPI.getState());
    const response = await getApi(`user/photo`,{
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        }
    })

    return  response.url
})

// all the data that required to show count number in dashboard page
export const fetchDashboard = createAsyncThunk('user/dashboard', async (_, thunkAPI) => {
    try {
      const response = await getApi(`dashboard/user-dashboard-counter`);
      console.log('Dashboard API response:', response);
      return response.data;
    } catch (error) {
      console.error('Dashboard API error:', error);
      return thunkAPI.rejectWithValue(error);
    }
  });

// get user topics of by their linkedin id
export const fetchUserTopics = createAsyncThunk('user/userTopics',async (_,thunkAPI)=>{
    const linkedinId = _
    if(linkedinId){
    const response = await getApi(`user/get-topics/${linkedinId}`)
    return  response.data
    }
})

// fetch all user personal notification with common notification
export const fetchUserNotifications = createAsyncThunk('user/UserNotifications',async (_,thunkAPI)=>{
    const linkedinId = _
    if(linkedinId){
        const response = await getApi(`notification/user/${linkedinId}`)
        return  response
    }
})

// export const fetchUserInfoByUserId = createAsyncThunk('user/getInfo',async (_,thunkAPI)=>{
//     const userId = localStorage.getItem('userId')
// <<<<<<< HEAD
// //     // userId = '5f3d82d808c9eea57e90b34dd3a59a28'
// =======
//     userId = '5f3d82d808c9eea57e90b34dd3a59a28'
// >>>>>>> 40b536c6132ccc4ab96bb2e81474968e73e66d51
//     const response = await getApi(`user/get-user-by-userid/${userId}`)
//     return  response.data
// })


export const { addTopics,deleteNotification,deleteAllNotifications } = userSlice.actions
export default userSlice.reducer