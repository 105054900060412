import React, { useEffect, useState } from "react";

const HelpDesk = () => {
  return (
    <>
      <div className=" bg-[#212529] h-[100vh]">
        {/* < a href='https://growero.productlift.dev/' target='_blank'><button className='float-right top-[56px] relative bg-[#212529] text-white signupBtn'>Sign Up</button></a> */}
        <iframe
          src="https://growero.productlift.dev"
          width="100%"
          height="800px"
          frameborder="0"
          className=""
        ></iframe>
      </div>
    </>
  );
};

export default HelpDesk;
