import React, { useEffect, useState } from 'react'

export const Testing = () => {
    const columnsCount = 10;
    const columns = [];

    for (let i = 0; i < columnsCount; i++) {
        columns.push(
            <div key={i} className="column">
                Column {i + 1}
            </div>
        );
    }
    return (
        <div className='container my-5'>
            <div className="row">
                <div className="col">
                    <div className="custom-row">
                        {       columns}
                    </div>
                </div>
            </div>
        </div>
    )
}
