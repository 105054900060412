import { templates } from "../../../../lib/templates";

const initialState = {
  headShot: true, //whether to put headshot or not
  introOutro: false,
  imgSize: "36px",
  nameSize: "sm",
  handleSize: "xs",
  headShotColor: "#666666",
  colorPicker: "false",
};

const headShotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HEADSHOT_SET": {
      console.log(action.payload.data);
      return {
        ...state,
        headShot: action.payload.data,
      };
    }
    case "HEADSHOT_INTRO_OUTRO": {
      return {
        ...state,
        introOutro: action.payload.data,
      };
    }
    case "HEADSHOT_SIZE": {
      if (
        action.payload.nameSize &&
        action.payload.handleSize &&
        action.payload.imgSize
      ) {
        return {
          ...state,
          nameSize: action.payload.nameSize,
          handleSize: action.payload.handleSize,
          imgSize: action.payload.imgSize,
        };
      }
    }
    case "HEADSHOT_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          headShotColor: action.payload.data,
        };
      }
    }
    case "HEADSHOT_COLORPICKER": {
      return {
        ...state,
        colorPicker: action.payload.data,
      };
    }
    case "RESET_HEADSHOT": {
      return initialState;
    }
    case "TEMPLATE_HEADSHOT": {
      const id = action.payload;
      const matchedTemplate = templates.find((template) => template.id === id);
      if (matchedTemplate) {
        return {
          headShot: matchedTemplate.headShot,
          introOutro: matchedTemplate.introOutro,
          imgSize: matchedTemplate.imgSize,
          nameSize: matchedTemplate.nameSize,
          handleSize: matchedTemplate.handleSize,
          headShotColor: matchedTemplate.headShotColor,
          colorPicker: matchedTemplate.colorPicker,
        };
      } else return state;
    }
    default:
      return state;
  }
};

export default headShotReducer;
