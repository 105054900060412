import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSavedCarousels, setActiveCarousel } from '../../../store/features/carousel/action/saveCarouselAction';

function SavedBar() {
  const dispatch = useDispatch();
  const entireState = useSelector((state) => state);
  console.log('Entire Redux State:', entireState);

  const saveCarouselsState = entireState.saveCarouselsReducer;
  const isDark = entireState.common?.isDark;

  console.log('saveCarouselsState in SavedBar:', saveCarouselsState);

  const savedCarousels = saveCarouselsState?.savedCarousels || [];
  

  useEffect(() => {
    dispatch(fetchSavedCarousels());
  }, [dispatch]);


  const handleCarouselClick = (carousel) => {
    dispatch(setActiveCarousel(carousel));
  };

  return (
    <div className="py-6 px-3 flex flex-col gap-3 w-[325px]">
      <p className="text-lg font-semibold mb-4">Saved Carousels</p>
      {savedCarousels.length === 0 ? (
        <p>No saved carousels found.</p>
      ) : (
        <ul className="space-y-2">
          {savedCarousels.map((carousel) => (
            <li
              key={carousel.id}
              className={`p-2 rounded cursor-pointer ${
                isDark ? 'hover:bg-gray-100' : 'hover:bg-gray-700'
              }`}
              onClick={() => handleCarouselClick(carousel)}
            >
              <p className="font-medium">{carousel.slides[0].title}</p>
              <p className="text-sm text-gray-500">
                Last edited: {new Date(carousel.updatedAt).toLocaleDateString()}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SavedBar;