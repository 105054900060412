import axios from "axios";

const Pixabay_URL = import.meta.env.VITE_PIXABAY_BASE_URL;
const Pixabay_API_KEY = import.meta.env.VITE_PIXABAY_API_KEY;

const Pexels_URL = import.meta.env.VITE_PEXELS_BASE_URL;
const Pexels_API_KEY = import.meta.env.VITE_PEXELS_API_KEY;

const Unsplash_URL = import.meta.env.VITE_UNSPLASH_BASE_URL;
const Unsplash_ACCESS_KEY = import.meta.env.VITE_UNSPLASH_ACCESS_KEY;

export const imageUploadAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_IMAGE",
      payload: data,
    });
  };
};

export const clearImages = () => ({
  type: "CLEAR_IMAGES",
});

export const deleteRecentImage = (imageUrl) => {
  return {
    type: "DELETE_RECENT_IMAGE",
    payload: imageUrl,
  };
};

export const pixabayImageData = (q) => async (dispatch) => {
  await axios
    .get(`${Pixabay_URL}?key=${Pixabay_API_KEY}&q=${q}&image_type=photo`)
    .then((res) => {
      dispatch({ type: "PIXABAY_IMAGE", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "PIXABAY_IMAGE", payload: err });
    });
};

export const pexelImageData = (q) => async (dispatch) => {
  await axios
    .get(`${Pexels_URL}search?query=${q}`, {
      headers: {
        Authorization: Pexels_API_KEY,
      },
    })
    .then((res) => {
      dispatch({ type: "PEXELS_IMAGE", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "PEXELS_IMAGE", payload: err });
    });
};

export const unsplashImageData = (q) => async (dispatch) => {
  await axios
    .get(`${Unsplash_URL}search/photos?page=1&query=${q}`, {
      headers: {
        Authorization: `Client-ID ${Unsplash_ACCESS_KEY}`,
      },
    })
    .then((res) => {
      dispatch({ type: "UNSPLASH_IMAGE", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "UNSPLASH_IMAGE", payload: err });
    });
};