import { useState } from "react";
import text from "../../../../src/assets/text.svg";
import headshot from "../../../../src/assets/headshot.svg";
import settings from "../../../../src/assets/settings.svg";
import canva from "../../../../src/assets/canva.svg";
import savedImg from "../../../../src/assets/savedImg.svg";
import colorImg from "../../../../src/assets/colorImg.svg";
import ai from "../../../../src/assets/ai.svg";
import order from "../../../../src/assets/order.svg";
import templateImg from "../../../../src/assets/templatesImg.svg";
import textWhite from "../../../../src/assets/textWhite.svg";
import savedImgWhite from "../../../../src/assets/savedImgWhite.svg";
import headshotWhite from "../../../../src/assets/headShotWhite.svg";
import settingsWhite from "../../../../src/assets/settingsWhite.svg";
import canvaWhite from "../../../../src/assets/canvaWhite.svg";
import colorImgWhite from "../../../../src/assets/colorImgWhite.svg";
import aiWhites from "../../../../src/assets/aiWhites.svg";
import templateWhite from "../../../../src/assets/templatesWhite.svg";
import orderWhite from "../../../../src/assets/orderWhite.svg";
import TextBar from "./textBar";
import CanvaBar from "./canvaBar";
import SettingsBar from "./settingsBar";
import HeadShotBar from "./HeadShotBar";
import OrderBar from "./OrderBar";
import SavedBar from "./savedBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { showSavedCarouselSet } from "../../redux/action/saveCarouselAction";
import { resetAdjust } from "../../../store/features/carousel/action/adjustAction";
import { resetText } from "../../../store/features/carousel/action/textAction";
import { resetCanava } from "../../../store/features/carousel/action/canvaAction";
import { resetHeadshot } from "../../../store/features/carousel/action/headShotAction";
import { resetSettings } from "../../../store/features/carousel/action/settingsAction";
import TemplateBar from "./templatesBar";
import ColorBar from "./colorBar";
import AiBar from "./aiBar";

function SideBar() {
  const { isDark } = useSelector((state) => state.common);
  const [active, setActive] = useState("AI");
  // const {showSavedCarousel} = useSelector((s)=>s.saveCarouselsReducer);
  const dispatch = useDispatch();

  const handleReset = () => {
    // dispatch(showSavedCarouselSet("1" , false));
    dispatch(resetAdjust());
    dispatch(resetText());
    dispatch(resetCanava());
    dispatch(resetHeadshot());
    dispatch(resetSettings());
  };

  //changed the gap from from 4 to 20px
  return (
    <>
      <div
        className="w-[392px] flex z-0 h-[110vh] mx-1 mt-1 "
        style={{ backgroundColor: isDark ? "white" : "#1E1E1E" }}
      >
        <div

          className="w-[70px] border-gray-200 border-r-[1px] flex flex-col items-center py-6 gap-[20px] overflow-y-scroll"
          style={{ boxSizing: "border-box" }}
        >
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("AI")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "AI"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? ai : aiWhites} width="16px"></img>
            </div>
            <p>AI</p>
          </div>
          <div
            className="flex flex-col text-[10px] font-semibold items-center gap-1 cursor-pointer"
            onClick={() => setActive("template")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "template"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img
                src={isDark ? templateImg : templateWhite}
                width="16px"
              ></img>
            </div>
            <p>Templates</p>
          </div>
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("headShot")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "headShot"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? headshot : headshotWhite} width="16px"></img>
            </div>
            <p>Headshot</p>
          </div>
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold  items-center cursor-pointer"
            onClick={() => setActive("canva")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "canva"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? canva : canvaWhite} width="16px"></img>
            </div>
            <p>Design</p>
          </div>
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("colors")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "colors"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? colorImg : colorImgWhite} width="16px"></img>
            </div>
            <p>Colors</p>
          </div>
          <div
            className="flex flex-col text-[10px] font-semibold gap-1 items-center cursor-pointer"
            onClick={() => setActive("text")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "text"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? text : textWhite} width="16px"></img>
            </div>
            <p>Text</p>
          </div>
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("settings")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "settings"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? settings : settingsWhite} width="16px"></img>
            </div>
            <p>Settings</p>
          </div>
          <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("order")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "order"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? order : orderWhite} width="16px"></img>
            </div>
            <p>Order</p>
          </div>

          {/* <div
            className="flex flex-col text-[10px] gap-1 font-semibold items-center cursor-pointer"
            onClick={() => setActive("saved")}
          >
            <div
              className={`p-2 rounded-lg ${
                active === "saved"
                  ? isDark
                    ? "bg-gray-200"
                    : "bg-gray-400"
                  : isDark
                  ? "hover:bg-gray-200"
                  : "hover:bg-gray-400"
              }`}
            >
              <img src={isDark ? savedImg : savedImgWhite} width="16px"></img>
            </div>
            <p>Saved</p>
          </div> */}
        </div>
        <div
          className="overflow-x-hidden overflow-y-auto pt-2"
          id="hide-scrollbar"
        >
          {active == "text" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <TextBar />
            </div>
          ) : null}
          {active == "canva" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <CanvaBar />
            </div>
          ) : null}
          {active == "settings" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <SettingsBar />
            </div>
          ) : null}
          {active == "headShot" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <HeadShotBar />
            </div>
          ) : null}
          {active == "order" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <OrderBar />
            </div>
          ) : null}
          {active == "saved" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <SavedBar />
            </div>
          ) : null}
          {active == "template" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <TemplateBar />
            </div>
          ) : null}
          {active == "colors" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <ColorBar />
            </div>
          ) : null}
          {active == "AI" ? (
            <div className="overflow-x-hidden overflow-y-auto">
              <AiBar />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SideBar;