import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { previewPostState } from "./initialState"

const previewPostSlice = createSlice({
    name: 'post',
    initialState : previewPostState,
    reducers: {
        // add post which is to be displayed
        addPreviewPost : ( state, action ) => {
            return { ...state, ...action.payload}
        },
        // here manupulate the post side bar is show or hide
        togglePreview : ( state, action ) => {
            return { ...state, isPreview:action.payload }
        }
    },
    extraReducers: (builder) => {
        
    }
})

export const { addPreviewPost,togglePreview,toggleReadMore } = previewPostSlice.actions
export default previewPostSlice.reducer