import { createSlice } from "@reduxjs/toolkit";
import { commonState } from "./initialState";

const commonSlice = createSlice({
    name: 'common',
    initialState: commonState,
    reducers: {
        // change asidebar layout
        changeLayoutShift: (state,action)=>{
            return { ...state, layoutShift: action.payload}
        },
        // manupulate loading status
        isLoading: (state,action)=>{
            return { ...state, loading: action.payload}
        },
        // manupulate change theme
        changeTheme: (state,action)=>{
            return { ...state, isDark: action.payload}
            },
        changeActiveTemplate: (state, action) => {
            return { ...state, defaultTemplate: action.payload}
        }
    }
})

export const { changeLayoutShift,isLoading,changeTheme, changeActiveTemplate } = commonSlice.actions
export default commonSlice.reducer