const initialState = {
  savedCarousels: [],
  activeCarousel: null,
};

const saveCarouselsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SAVED_CAROUSELS_SUCCESS':
      return { ...state, savedCarousels: action.payload };
    case 'SET_ACTIVE_CAROUSEL':
      return { ...state, activeCarousel: action.payload };
    case 'FETCH_SAVED_CAROUSELS_FAILURE':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default saveCarouselsReducer;