// TODO: Add fontSizeSubtitle for all templates

export const templates = [
  {
    name: "Template 1",
    id: "1",
    layout: "boxed",
    pattern: "grid",
    bgOpacity: "0.2",
    bgColor: "#ffffff",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#666666",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Oswald",
    bodyFontType: "Rubik",
    fontSizeSubtitle: "sm",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#666666",
    titleColor: "#666666",
    subTitleColor: "#666666",
    swipeColor: "#006838",
    bookmarkColor: "#006838",
    pageNoColor: "#006838",
    textColorPicker: true,
    boxDivColor: "#F2F2F2",
  },
  {
    name: "Template 2",
    id: "2",
    layout: "boxed",
    pattern: "dots",
    bgOpacity: "0.2",
    bgColor: "#111111",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#FFFFFF",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "plain",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "3xl",
    fontSizeDesc: "xl",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#FFFFFF",
    titleColor: "#FFFFFF",
    subTitleColor: "#FFFFFF",
    swipeColor: "#D93F3C",
    bookmarkColor: "#D93F3C",
    pageNoColor: "#D93F3C",
    textColorPicker: true,
    boxDivColor: "#333333",
  },
  {
    name: "Template 3",
    id: "3",
    layout: "classic",
    pattern: "stripped",
    bgOpacity: "0.2",
    bgColor: "rgb(225, 239, 215)",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "rgb(114, 138, 100)",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "circle",
    titleFontType: "Yellowtail",
    bodyFontType: "Playfair Display",
    fontSizeTitle: "xl",
    fontSizeDesc: "base",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "rgb(114, 138, 100)",
    titleColor: "rgb(114, 138, 100)",
    subTitleColor: "rgb(114, 138, 100)",
    swipeColor: "rgb(75, 130, 93)",
    bookmarkColor: "rgb(75, 130, 93)",
    pageNoColor: "rgb(75, 130, 93)",
    textColorPicker: true,
    boxDivColor: "rgb(237, 239, 238)",
  },
  {
    name: "Template 4",
    id: "4",
    layout: "boxed",
    pattern: "cage",
    bgOpacity: "0.2",
    bgColor: "rgb(17, 0, 77)",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "rgb(255, 255, 255)",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "plain",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Ultra",
    bodyFontType: "Stint Ultra Expanded",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "left",
    verticalAlign: "end",
    customPair: false,
    descColor: "rgb(255, 255, 255)",
    titleColor: "rgb(255, 255, 255)",
    subTitleColor: "rgb(255, 255, 255)",
    pageNoColor: " rgb(187, 0, 136)",
    swipeColor: " rgb(187, 0, 136)",
    bookmarkColor: " rgb(187, 0, 136)",
    textColorPicker: true,
    boxDivColor: "rgb(51, 34, 136)",
  },
  {
    name: "Template 5",
    id: "5",
    layout: "classic",
    pattern: "dots",
    bgOpacity: "0.2",
    bgColor: "#ffffff",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#666666",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Rubik",
    bodyFontType: "Rubik",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "left",
    verticalAlign: "start",
    customPair: false,
    descColor: "#666666",
    titleColor: "#666666",
    subTitleColor: "#666666",
    pageNoColor: "#3F48CC",
    swipeColor: "#3F48CC",
    bookmarkColor: "#3F48CC",
    textColorPicker: true,
    boxDivColor: "#F2F2F2",
  },
  {
    name: "Template 6",
    id: "6",
    layout: "classic",
    pattern: "grid",
    bgOpacity: "0.2",
    bgColor: "#FFF2E4",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#7A552B",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Montserrat",
    bodyFontType: "Montserrat",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#7A552B",
    titleColor: "#7A552B",
    subTitleColor: "#7A552B",
    pageNoColor: "#C65911",
    swipeColor: "#C65911",
    bookmarkColor: "#C65911",
    textColorPicker: true,
    boxDivColor: "#FFEDDA",
  },
  {
    name: "Template 7",
    id: "7",
    layout: "classic",
    pattern: "none",
    bgOpacity: "0.2",
    bgColor: "#771144",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#FFFFFF",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Cardo",
    bodyFontType: "Cardo",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "left",
    verticalAlign: "start",
    customPair: false,
    descColor: "#FFFFFF",
    titleColor: "#FFFFFF",
    subTitleColor: "#FFFFFF",
    pageNoColor: "#EE3377",
    swipeColor: "#EE3377",
    bookmarkColor: "#EE3377",
    textColorPicker: true,
    boxDivColor: "#992255",
  },
  {
    name: "Template 8",
    id: "8",
    layout: "classic",
    pattern: "stripped",
    bgOpacity: "0.2",
    bgColor: "#FEDCE7",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#9A5271",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Syne",
    bodyFontType: "Syne",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "left",
    verticalAlign: "start",
    customPair: false,
    descColor: "#9A5271",
    titleColor: "#9A5271",
    subTitleColor: "#9A5271",
    pageNoColor: "#D462A0",
    swipeColor: "#D462A0",
    bookmarkColor: "#D462A0",
    textColorPicker: true,
    boxDivColor: "#FFECF8",
  },
  {
    name: "Template 9",
    id: "9",
    layout: "classic",
    pattern: "dots",
    bgOpacity: "0.2",
    bgColor: "#DAF8E7",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#2E583D",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Oswald",
    bodyFontType: "Oswald",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "left",
    verticalAlign: "start",
    customPair: false,
    descColor: "#2E583D",
    titleColor: "#2E583D",
    subTitleColor: "#2E583D",
    pageNoColor: "#2C9955",
    swipeColor: "#2C9955",
    bookmarkColor: "#2C9955",
    textColorPicker: true,
    boxDivColor: "#EBFFF8",
  },
  {
    name: "Template 10",
    id: "10",
    layout: "boxed",
    pattern: "none",
    bgOpacity: "0.2",
    bgColor: "#DAF8E7",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#2E583D",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#2E583D",
    titleColor: "#2E583D",
    subTitleColor: "#2E583D",
    pageNoColor: "#2C9955",
    swipeColor: "#2C9955",
    bookmarkColor: "#2C9955",
    textColorPicker: true,
    boxDivColor: "#EBFFF8",
  },
  {
    name: "Template 11",
    id: "11",
    layout: "boxed",
    pattern: "none",
    bgOpacity: "0.2",
    bgColor: "#516BCC",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#FFFFFF",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#394A7B",
    titleColor: "#394A7B",
    subTitleColor: "#394A7B",
    pageNoColor: "#CCDAFF",
    swipeColor: "#CCDAFF",
    bookmarkColor: "#CCDAFF",
    textColorPicker: true,
    boxDivColor: "#F3F6FA",
  },
  {
    name: "Template 12",
    id: "12",
    layout: "classic",
    pattern: "stripped",
    bgOpacity: "0.2",
    bgColor: "#EFDDFF",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#695273",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#695273",
    titleColor: "#695273",
    subTitleColor: "#695273",
    pageNoColor: "#926CAE",
    swipeColor: "#926CAE",
    bookmarkColor: "#926CAE",
    textColorPicker: true,
    boxDivColor: "#F3E9FF",
  },
  {
    name: "Template 13",
    id: "13",
    layout: "boxed",
    pattern: "grid",
    bgOpacity: "0.2",
    bgColor: "#E7EEDE",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#7B956D",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#7B956D",
    titleColor: "#7B956D",
    subTitleColor: "#7B956D",
    pageNoColor: "#53917A",
    swipeColor: "#53917A",
    bookmarkColor: "#53917A",
    textColorPicker: true,
    boxDivColor: "#F1F4EC",
  },
  {
    name: "Template 14",
    id: "14",
    layout: "boxed",
    pattern: "stripped",
    bgOpacity: "0.2",
    bgColor: "#222BBB",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#FFFFFF",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#FFFFFF",
    titleColor: "#FFFFFF",
    subTitleColor: "#FFFFFF",
    pageNoColor: "#AA77CC",
    swipeColor: "#AA77CC",
    bookmarkColor: "#AA77CC",
    textColorPicker: true,
    boxDivColor: "#4444DD",
  },
  {
    name: "Template 15",
    id: "15",
    layout: "classic",
    pattern: "grid",
    bgOpacity: "0.2",
    bgColor: "#004D00",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#FFFFFF",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "start",
    customPair: false,
    descColor: "#FFFFFF",
    titleColor: "#FFFFFF",
    subTitleColor: "#FFFFFF",
    pageNoColor: "#00BB00",
    swipeColor: "#00BB00",
    bookmarkColor: "#00BB00",
    textColorPicker: true,
    boxDivColor: "#225811",
  },
  {
    name: "Template 16",
    id: "16",
    layout: "boxed",
    pattern: "dots",
    bgOpacity: "0.2",
    bgColor: "#FCFAC4",
    bgColorPicker: true,
    aspectRatio: "1:1",
    headShot: true,
    introOutro: false,
    imgSize: "36px",
    nameSize: "sm",
    handleSize: "xs",
    headShotColor: "#A68751",
    colorPicker: "false",
    slideNo: true,
    slideNoStyle: "round",
    round: "0.5",
    swipe: true,
    bookmark: true,
    swipeText: "",
    swipeIcon: "swipeArr",
    titleFontType: "Poppins",
    bodyFontType: "Poppins",
    fontSizeTitle: "2xl",
    fontSizeDesc: "lg",
    textAlign: "center",
    verticalAlign: "center",
    customPair: false,
    descColor: "#A68751",
    titleColor: "#A68751",
    subTitleColor: "#A68751",
    pageNoColor: "#D4A253",
    swipeColor: "#D4A253",
    bookmarkColor: "#D4A253",
    textColorPicker: true,
    boxDivColor: "#FFFADB",
  },
];
