import React, { useEffect, useState } from "react";
import Headers from "../../components/partials/Header/Headers";
import Aside from "../../components/partials/AsideBar/Aside";
import { Outlet, useNavigate } from "react-router-dom";
import "./Home.css";
import { useSelector, useDispatch } from "react-redux";
import First from "../../components/AfterLogin/First";
import axios from "axios";
import { apibase } from "../../api/API";
import {
  fetchUserInfo,
  fetchUserTopics,
} from "../../store/features/user/userSlice";
import { getApi, deleteApi, postApi } from "../../api/methods";
import { isLoading } from "../../store/features/common/commonSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { IMAGE_URL, IMAGE_URL_BACKEND } from "../../api/API";
import { togglePreview } from "../../store/features/previewPost/previewPostSlice";
import { addPostForPreview } from "../../store/features/post/postSlice";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const previewPost = useSelector((state) => state.previewPost);
  // console.log({user});
  const { layoutShift, isDark } = useSelector((state) => state.common);
  const [notifiyModal, setNotifiyModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [switchProfileModal, setSwitchProfileModal] = useState(false);
  const [url, setUrl] = useState("");

  // this is auto close of notification model, switch profile model, preview model whenever click any part of body part
  const panelManupulate = () => {
    if (notifiyModal) setNotifiyModal(false);
    if (switchProfileModal) setSwitchProfileModal(false);
    if (previewPost.isPreview) dispatch(togglePreview(false));
  };

  // here if user can edit the preview post then this function handle it
  const onComposeHandler = () => {
    // add post to preview post store
    dispatch(addPostForPreview(previewPost));
    // console.log(deleteApi(`schedule/delete-schedule-post/${previewPost._id}`));
    // navigate to post-idea page
    navigate("/ai-content");
    // hide the preview post component
    dispatch(togglePreview(false));
  };

  useEffect(() => {
    if (previewPost.postType === "ARTICLE") {
      setUrl(previewPost.originalUrl);
    }
  }, [previewPost.postType, previewPost.originalUrl]);

  useEffect(() => {
    if (url !== "") {
      fetchMetaData();
    }
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url,
      });
      // console.log(response.data);
      setModalData(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  console.log(previewPost);

  return (
    <>
      <div>
        <div
          className={`main-container ${isDark ? "daymode" : "isdark"} `}
          style={{ width: "100%" }}
          id="hide-scrollbar"
        >
          <div
            className={`left-sidebar ${isDark ? " " : "left-sidebar-dark"} ${
              layoutShift ? "contract" : "expand max-md:w-0"

            } ` }
            style={{
              // width: layoutShift ? "5%" : "12.5%",
              paddingRight: layoutShift ? "" : "",
            }}
            id="hide-scrollbar"
          >
            <div
              className="left-sidebar-container"
              style={{}}
              id="hide-scrollbar"   
            >
              {/* Aside bar component */}
              <Aside />
            </div>
          </div>
          <div
            className={`right-sidebar ${
              layoutShift ? "body-toggle" : "right-sidebar-body-toggle"
            } `}
            id="hide-scrollbar"
          >
            {/* Header component */}
            <Headers
              notifiyModal={notifiyModal}
              setNotifiyModal={setNotifiyModal}
              switchProfileModal={switchProfileModal}
              setSwitchProfileModal={setSwitchProfileModal}
              style={{ paddingRight: layoutShift ? "7rem" : "14rem" }}
              id="hide-scrollbar"
            />
            <div
              className={isDark ? "body-container" : "body-container-dark"}
              style={{ paddingTop: "4rem" }}
              id="hide-scrollbar"
              onClick={panelManupulate}
            >
              {/* Other pages comes here */}
              <Outlet />
              {
                // Global preview component that showing preview post on every pages
                previewPost.isPreview && (
                  <div
                    className={`preview-post-section ${
                      isDark
                        ? "preview-post-section-light"
                        : "preview-post-section-dark"
                    } `}
                    id="hide-scrollbar"
                  >
                    <div
                      className="preview-post-box-global"
                      id="hide-scrollbar"
                    >
                      <div className="preview-header" id="hide-scrollbar">
                        <h3>Preview</h3>
                        {/* <button className='btn btn-outline-danger' >Cancel</button> */}
                        <i
                          className="fa-regular fa-eye-slash"
                          style={isDark ? { color: "#000" } : { color: "#fff" }}
                          onClick={() => dispatch(togglePreview(false))}
                        ></i>
                      </div>

                      {/* { previewPost.postContent && */}
                      <div
                        className={`${
                          isDark ? "card-tems-preview" : "card-tems-dark"
                        } preview-card mt-4 grediant-border`}
                        style={{
                          height: "auto",
                          maxHeight: "75vh",
                          padding: "1rem 0",
                          margin: 0,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <div
                          className="header-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            className="user-info"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div style={{ position: "relative", zIndex: 1 }}>
                              {user?.userPhoto?.length > 0 ? (
                                <img
                                  src={user.userPhoto}
                                  className="user-logo avatar-img"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={`${IMAGE_URL}/images/Icons/avatar.jpg`}
                                  className="user-logo avatar-img"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              )}
                            </div>
                            {user?.userData?.userName ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "45px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <b>
                                  {" "}
                                  <p
                                    style={{
                                      margin: 0,
                                      fontSize: "0.9em",
                                      lineHeight: "1.2",
                                    }}
                                  >
                                    {user.userData.userName.length > 30
                                      ? user.userData.userName.slice(0, 30) +
                                        "..."
                                      : user.userData.userName}
                                  </p>
                                </b>
                                <span
                                  style={{
                                    fontSize: "0.75em",
                                    lineHeight: "1.2",
                                  }}
                                >
                                  {user.userData?.localizedHeadline > 30
                                    ? user.userData?.localizedHeadline.slice(
                                        0,
                                        30
                                      ) + "..."
                                    : user.userData?.localizedHeadline}
                                </span>
                              </div>
                            ) : (
                              <p style={{ margin: 0 }}>Loading...</p>
                            )}
                          </div>
                          <div
                            className="action-btn"
                            style={{
                              position: "absolute",
                              right: "0px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            {previewPost.platformPost?.twitter && (
                              <img
                                src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`}
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  margin: "0 2px",
                                }}
                              />
                            )}
                            {previewPost.platformPost?.facebook && (
                              <img
                                src={`${IMAGE_URL}/images/socialIcon/Facebook.png`}
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  margin: "0 2px",
                                }}
                              />
                            )}
                            {previewPost.platformPost?.instagram && (
                              <img
                                src={`${IMAGE_URL}/images/socialIcon/Instagram.png`}
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  margin: "0 2px",
                                }}
                              />
                            )}
                            {previewPost.platformPost?.linkedin && (
                              <img
                                src={`${IMAGE_URL}/images/socialIcon/linkedin.png`}
                                style={{
                                  height: "18px",
                                  width: "18px",
                                  margin: "0 55px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="card-item-body">
                          <p
                            className={`m-3  ${isDark ? "" : "dark-para"}`}
                            style={{
                              height: "auto",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {previewPost.postContent
                              .split(/#\s*/)
                              .map((part, index) => (
                                <React.Fragment key={index}>
                                  {index != 0 ? "#" : ""}
                                  {part}{" "}
                                  {index === 0 &&
                                  part.trim().length > 0 &&
                                  previewPost.postContent.includes("#")
                                    ? "\n\n"
                                    : ""}{" "}
                                </React.Fragment>
                              ))}
                          </p>
                          <br />
                          {previewPost.mediaDis &&
                            previewPost.postType === "IMAGE" && (
                              <div className="pl-3 pr-3">
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                  marginBottom: "30px",
                                }}
                              >
                                {previewPost.mediaDis.length === 1 ? (
                                  // Single image case
                                  <div style={{ width: "100%", position: "relative" }}>
                                    <img
                                      src={`${IMAGE_URL_BACKEND}/uploads/${previewPost.mediaDis[0]}`}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        objectFit: "contain",
                                        borderRadius: "10px",
                                      }}
                                      alt="Preview Image 1"
                                    />
                                  </div>
                                ) : previewPost.mediaDis.length === 2 ? (
                                  // Two images case
                                  <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                                    {previewPost.mediaDis.map((url, index) => (
                                      <div
                                        key={index}
                                        style={{ flex: 1, position: "relative" }}
                                      >
                                        <img
                                          src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                            objectFit: "cover",
                                            borderRadius: "10px",
                                          }}
                                          alt={`Preview Image ${index + 1}`}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  // Three or more images case
                                  <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                                    <div style={{ flex: 2, position: "relative" }}>
                                      <img
                                        src={`${IMAGE_URL_BACKEND}/uploads/${previewPost.mediaDis[0]}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "10px",
                                        }}
                                        alt="Preview Image 1"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      {previewPost.mediaDis.slice(1, 4).map((url, index) => (
                                        <div
                                          key={index + 1}
                                          style={{ flex: 1, position: "relative" }}
                                        >
                                          <img
                                            src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              borderRadius: "10px",
                                            }}
                                            alt={`Preview Image ${index + 2}`}
                                          />
                                          {index === 2 && previewPost.mediaDis.length > 4 && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "white",
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              +{previewPost.mediaDis.length - 4}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                              </div>
                            )}
                          {previewPost.originalUrl &&
                            previewPost.postType === "ARTICLE" && modalData && (
                              <div
                                className="card mb-10 bg-[#e4e4e7]"
                                style={{
                                  margin: "10px 10px 30px 10px",
                                  position: "relative",
                                }}
                              >
                                <p style={{ margin: "4% 4% 8% 3%" }}>
                                  <a
                                    href={previewPost.originalUrl}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    {previewPost.originalUrl}
                                  </a>{" "}
                                  <img
                  src={modalData.data.ogImage[0].url}
                  className="card-img-top"
                  style={{ margin: "5% 5% 5% 5%", height: "20%", width: "90%" }}
                  alt="Preview Image"
                />
                                </p>

                              </div>
                            )}
                          {previewPost.originalUrl &&
                            previewPost.postType === "VIDEO" && (
                              <div style={{ position: "relative" }}>
                                <video
                                  src={`${IMAGE_URL_BACKEND}/uploads/${previewPost.originalUrl}`}
                                  style={{
                                    margin: "0px 0px 8% 2.5%",
                                    height: "20%",
                                    width: "100%",
                                  }}
                                  controls
                                />
                              </div>
                            )}
                          {previewPost.originalUrl &&
                            previewPost.postType === "URL" && (
                              <div style={{ position: "relative" }}></div>
                            )}
                        </div>
                        <div className="card-item-footer d-flex justify-content-between">
                          <span className="p-2">
                            <i
                              className="fa fa-thumbs-up p-2"
                              aria-hidden="true"
                              style={{ color: "#4267B2" }}
                            ></i>{" "}
                            0
                          </span>
                          <span className="p-2">0 comments . 0 reposts</span>
                        </div>
                      </div>
                      {/* } */}
                    </div>
                    <button
                      className="btn btn-primary w-100 mt-2 grediant-button"
                      onClick={onComposeHandler}
                    >
                      Edit with AI
                    </button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {user?.userChosenTopics?.topics?.length === 0 ||
        (user?.userChosenTopics === null && <First />)}
      <ToastContainer />
    </>
  );
};

export default Home;
