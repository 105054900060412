import {
  bgColorChange,
  bgColorPickerSet,
  bgOpacityChange,
  layoutChange,
  patternChange,
  setAspectRatio,
} from "../../../store/features/carousel/action/canvaAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { IMAGE_URL } from "../../../api/API";

function CanvaBar() {
  const { pattern, layout, bgOpacity, bgColor, bgColorPicker, aspectRatio } =
    useSelector((s) => s.canvaReducer);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const handleLayout = (data) => {
    dispatch(layoutChange(data));
  };

  const handlePattern = (data) => {
    dispatch(patternChange(data));
  };

  const handleBgOpacity = (data) => {
    dispatch(bgOpacityChange(data));
    console.log(data);
  };

  const handleBgColorPicker = (data) => {
    dispatch(bgColorPickerSet(data));
  };
  const handleBgColor = (data) => {
    dispatch(bgColorChange(data));
  };
  const handleAspectRatio = (data) => {
    dispatch(setAspectRatio(data));
  };
  useEffect(() => {
    const colorInp = document.getElementById("colorInp");
    const colorPick = document.getElementById("colorPicker");
    if (colorInp && colorPick) {
      colorInp.disabled = !bgColorPicker;
      colorPick.disabled = !bgColorPicker;
    }
    if (!bgColorPicker) {
      dispatch(bgColorChange(bgColor));
    }
  }, [bgColorPicker]);

  return (
    <>
      <div className="py-6 px-3 flex flex-col justify-center gap-3">
        <p className="font-bold text-left">Design</p>
        <div className="flex flex-col w-full gap-1">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            Layout
          </label>
          <Select onValueChange={handleLayout} value={layout}>
            <SelectTrigger
              className={`${isDark ? " text-black" : " text-white"}`}
            >
              <SelectValue placeholder="Layout" />
            </SelectTrigger>
            <SelectContent
              className={`${
                isDark ? "bg-white text-black" : "bg-black text-white"
              }`}
            >
              <SelectItem value="classic">Classic</SelectItem>
              <SelectItem value="boxed">Boxed</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col w-full gap-1">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            Background Pattern
          </label>
          <Select onValueChange={handlePattern} value={pattern}>
            <SelectTrigger
              className={`${isDark ? " text-black" : " text-white"}`}
            >
              <SelectValue placeholder="Background Pattern" />
            </SelectTrigger>
            <SelectContent
              className={`${
                isDark ? "bg-white text-black" : "bg-black text-white"
              }`}
            >
              <SelectItem value="none">None</SelectItem>
              <SelectItem value="dots">Dots</SelectItem>
              <SelectItem value="grid">Grid</SelectItem>
              <SelectItem value="cage">Cage</SelectItem>
              <SelectItem value="stripped">Stripped</SelectItem>
              <SelectItem value="diagonals">Diagonals</SelectItem>
              <SelectItem value="boxes">Boxes</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-1">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            Opacity
          </label>
          <input
            type="range"
            min="0.1"
            max="1"
            step="0.01"
            value={bgOpacity}
            onChange={(e) => handleBgOpacity(parseFloat(e.target.value))}
            className="w-[290px]"
          />
        </div>
        {/* <div className="flex items-center justify-between border-2 border-gray-200 p-2 rounded-lg">
        <p>Custom Background Color</p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={bgColorPicker}
                  onChange={()=>handleBgColorPicker(!bgColorPicker)}
                />
                <span class="slider round"></span>
              </label>
              </div> */}
        {/* <div className="relative">
                        <input type='text' value={bgColor} onChange={(e)=>handleBgColor(e.target.value)} id='colorInp' className="w-[300px] border rounded-md px-3 py-2" placeholder="Enter Hex Code"></input>
                            <div className="relative -top-[34px] left-60">
                                <input type='color' id='colorPicker' value={bgColor} onChange={(e)=>handleBgColor(e.target.value)}></input>
                            </div>
                    </div> */}
        <div className="flex flex-col w-full gap-1">
          <label
            className="text-sm font-semibold"
            style={{ color: isDark ? "black" : "white" }}
          >
            Aspect Ratio
          </label>
          <Select onValueChange={handleAspectRatio} value={aspectRatio}>
            <SelectTrigger
              className={`${isDark ? " text-black" : " text-white"}`}
            >
              <SelectValue placeholder="Aspect Ratio" />
            </SelectTrigger>
            <SelectContent
              className={`${
                isDark ? "bg-white text-black" : "bg-black text-white"
              }`}
            >
              <SelectItem value="1:1">
                <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/linkedin.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0px 3px",
                  }}
                />
                <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/Instagram.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0px 3px",
                  }}
                />
                <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/Facebook.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0px 3px",
                  }}
                />
                
                1:1
              </SelectItem>
              <SelectItem value="4:5">
              <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/linkedin.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0 3px",
                  }}
                />
                <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/Instagram.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0 3px",
                  }}
                />4:5</SelectItem>
              <SelectItem value="3:4"> <img
                 className="mt-1 float-left "
                  src={`${IMAGE_URL}/images/socialIcon/linkedin.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0 3px",
                  }}
                />
                <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/Instagram.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0 3px",
                  }}
                />3:4</SelectItem>
              <SelectItem value="4:3">
              <img
                 className="mt-1 float-left"
                  src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`}
                  style={{
                    height: "16px",
                    width: "16px",
                    margin: "0 3px",
                  }}
                />
              4:3</SelectItem>
              <SelectItem value="16:9">
              16:9</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </>
  );
}

export default CanvaBar;
