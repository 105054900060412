import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const ResponsiveSchedule = ({ filterdata, isDark, handleItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(filterdata[0] || '');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onItemClick = (item) => {
    setSelectedItem(item);
    handleItemClick(item);
    if (isMobile) setIsOpen(false);
  };

  if (!isMobile) {
    return (
      <div className="schedule-left">
        {filterdata?.map((item, index) => (
          <span
            key={index}
            className={selectedItem === item ? `isActive-list ${isDark ? 'isActive-list-light' : 'isActive-list-dark'}` : ''}
            onClick={() => onItemClick(item)}
          >
            {item}
          </span>
        ))}
      </div>
    );
  }

  return (
    <div className="relative inline-block text-left w-30%">
      <div>
        <button
          type="button"
          className={`inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 ${
            isDark ? 'bg-gray-800 text-white' : ''
          }`}
          onClick={toggleDropdown}
        >
          {selectedItem}
          <ChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{zIndex:1000}}>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {filterdata?.map((item, index) => (
              <a
                key={index}
                href="#"
                className={`${
                  selectedItem === item
                    ? `bg-gray-100 text-gray-900 ${isDark ? 'bg-gray-700 text-white' : ''}`
                    : 'text-gray-700'
                } block px-4 py-2 text-sm`}
                onClick={() => onItemClick(item)}
                role="menuitem"
              >
                {item}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveSchedule;