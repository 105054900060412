import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import Calendar from "./Calender";
import { useSelector } from "react-redux";
import { useState } from "react";
import { getApi } from "../../api/methods";
import { useEffect } from "react";

function StreakComp() {
  const textStyle = {
    background: "linear-gradient(126.01deg, #8A51FF -9.26%, #39B2FF 79.86%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
  const currentYear = new Date().getFullYear();
  const { userData } = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleSelectedYear = (year) => {
    setSelectedYear(year);
  };

  const [streakData, setStreakData] = useState({
    currentStreak: 0,
  });


  useEffect(() => {
    const fetchStreakData = async () => {
      try {
        const response = await getApi(`daily-post-tracker/current-streak/${userData.linkedinId}`);
        setStreakData(response);
      } catch (error) {
        console.error("Error fetching streak data:", error);
      }
    };

    fetchStreakData();
  }, [userData]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <p className="max-[400px]:text-[11px]">
            Current Streak:{" "}
            <span style={textStyle}>{streakData.currentStreak} days</span>
          </p>
          <Select className="h-full">
            <SelectTrigger
              className={`${isDark ? " text-black" : " text-white"} w-[130px]`}
              value={selectedYear}
              onValueChange={handleSelectedYear}
            >
              <SelectValue placeholder="2024" className="" />
            </SelectTrigger>
            <SelectContent
              className={`${
                isDark ? "bg-white text-black" : "bg-black text-white"
              }`}
            >
              <SelectItem value="2024">2024</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Calendar></Calendar>
        </div>
        <div className="flex max-md:pl-[30px] mt-2 gap-3">
          <div className="flex items-center gap-1">
            <div
              style={{
                background:
                  "linear-gradient(126.01deg, #8A51FF -9.26%, #39B2FF 79.86%)",
              }}
              className="h-[15px] w-[15px] rounded-sm"
            ></div>
            <p className="text-sm">High</p>
          </div>
          <div className="flex items-center gap-1">
            <div
              style={{
                background:
                  "linear-gradient(126.01deg, rgba(138, 81, 255, 0.5) -9.26%, rgba(57, 178, 255, 0.5) 79.86%)",
              }}
              className="h-[15px] w-[15px] rounded-sm"
            ></div>
            <p className="text-sm">Average</p>
          </div>
          <div className="flex items-center gap-1">
            <div
              style={{
                background:
                  "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)",
              }}
              className="h-[15px] w-[15px] rounded-sm"
            ></div>
            <p className="text-sm">Low</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default StreakComp;
