import { templates } from "../../../../lib/templates";

const initialState = {
  titleFontType: "Oswald",
  bodyFontType: "Rubik",
  fontSizeSubtitle: "sm",
  fontSizeTitle: "2xl",
  fontSizeDesc: "lg",
  textAlign: "center",
  verticalAlign: "center",
  customPair: false,
  descColor: "#666666",
  titleColor: "#666666",
  subTitleColor: "#666666",
  pageNoColor: "#006838",
  textColorPicker: true,
};

const textReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FONT_STYLE": {
      if (action.payload.titleFont && action.payload.bodyFont) {
        return {
          ...state,
          titleFontType: action.payload.titleFont,
          bodyFontType: action.payload.bodyFont,
        };
      }
    }
    case "FONT_SIZE": {
      if (action.payload.fontSizeSubtitle && action.payload.fontSizeTitle && action.payload.fontSizeDesc) {
        console.log(action.payload.fontSizeSubtitle, action.payload.fontSizeTitle, action.payload.fontSizeDesc);
        return {
          ...state,
          fontSizeSubtitle: action.payload.fontSizeSubtitle,
          fontSizeTitle: action.payload.fontSizeTitle,
          fontSizeDesc: action.payload.fontSizeDesc,
        };
      }
    }
    case "TEXT_ALIGN": {
      if (action.payload.data)
        return {
          ...state,
          textAlign: action.payload.data,
        };
    }
    case "VERTICAL_ALIGN": {
      if (action.payload.data)
        return {
          ...state,
          verticalAlign: action.payload.data,
        };
    }
    case "CUSTOM_PAIR": {
      return {
        ...state,
        customPair: action.payload.data,
      };
    }
    case "DESC_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          descColor: action.payload.data,
        };
      }
    }
    case "TITLE_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          titleColor: action.payload.data,
        };
      }
    }
    case "SUBTITLE_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          subTitleColor: action.payload.data,
        };
      }
    }
    case "PAGE_NO_COLOR": {
      if (action.payload.data) {
        return {
          ...state,
          pageNoColor: action.payload.data,
        };
      }
    }
    case "TEXT_COLORPICKER": {
      return {
        ...state,
        textColorPicker: action.payload.data,
      };
    }
    case "RESET_TEXT": {
      return initialState;
    }
    case "TEMPLATE_TEXT": {
      const id = action.payload;
      const matchedTemplate = templates.find((template) => template.id === id);
      if (matchedTemplate) {
        return {
          titleFontType: matchedTemplate.titleFontType,
          bodyFontType: matchedTemplate.bodyFontType,
          fontSizeSubtitle: matchedTemplate.fontSizeSubtitle,
          fontSizeTitle: matchedTemplate.fontSizeTitle,
          fontSizeDesc: matchedTemplate.fontSizeDesc,
          textAlign: matchedTemplate.textAlign,
          verticalAlign: matchedTemplate.verticalAlign,
          customPair: matchedTemplate.customPair,
          descColor: matchedTemplate.descColor,
          titleColor: matchedTemplate.titleColor,
          subTitleColor: matchedTemplate.subTitleColor,
          pageNoColor: matchedTemplate.pageNoColor,
          textColorPicker: matchedTemplate.textColorPicker,
        };
      } else return state;
    }
    default:
      return state;
  }
};

export default textReducer;
