import React, { useState } from 'react'
import Subscription from '../../components/partials/subscription/Subscription'

const BySubscription = () => {
  const [isShowSubs, setIsShowSubs]=useState(false)

  return (
    <>
    <div className='py-10'>
      <Subscription setIsShowSubs={setIsShowSubs}  />
      </div>
    </>
  )
}

export default BySubscription