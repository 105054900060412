import { templates } from "../../../../lib/templates";

const initialState = {
  layout: "boxed",
  pattern: "dots",
  bgOpacity: "0.2", //pattern Opacity
  bgColor: "#ffffff",
  bgColorPicker: true,
  aspectRatio: "1:1",
  boxDivColor: "#F2F2F2",
};

const canvaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LAYOUT_CHANGE": {
      if (action.payload.data) {
        return {
          ...state,
          layout: action.payload.data,
        };
      }
    }
    case "PATTERN_CHANGE": {
      if (action.payload.data) {
        return {
          ...state,
          pattern: action.payload.data,
        };
      }
    }
    case "OPACITY_BG_CHANGE": {
      if (action.payload.data) {
        return {
          ...state,
          bgOpacity: action.payload.data,
        };
      }
    }
    case "BG_COLOR_CHANGE": {
      if (action.payload.data) {
        return {
          ...state,
          bgColor: action.payload.data,
        };
      }
    }
    case "BOX_COLOR_CHANGE": {
      if (action.payload.data) {
        return {
          ...state,
          boxDivColor: action.payload.data,
        };
      }
    }
    case "BACKGROUND_COLORPICKER": {
      return {
        ...state,
        bgColorPicker: action.payload.data,
      };
    }
    case "ASPECT_RATIO": {
      return {
        ...state,
        aspectRatio: action.payload.data,
      };
    }
    case "RESET_CANVA": {
      return initialState;
    }
    case "TEMPLATE_CANVA": {
      const id = action.payload;
      const matchedTemplate = templates.find((template) => template.id === id);
      if (matchedTemplate) {
        return {
          layout: matchedTemplate.layout,
          pattern: matchedTemplate.pattern,
          bgOpacity: matchedTemplate.bgOpacity,
          bgColor: matchedTemplate.bgColor,
          bgColorPicker: matchedTemplate.bgColorPicker,
          aspectRatio: matchedTemplate.aspectRatio,
          boxDivColor: matchedTemplate.boxDivColor,
        };
      } else return state;
    }
    default:
      return state;
  }
};

export default canvaReducer;
