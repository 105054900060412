import React from 'react'
import { useSelector } from 'react-redux'
import './creditHistory.css'

const CreditHistoryData = ( { item,memberColorClass } ) => {
  const { isDark } = useSelector(state=>state.common)

  // proper format of date to show like dd-mm-yyyy
  let dateFormat = item.createdAt.split('T')[0]
  dateFormat = dateFormat.split('-')
  dateFormat = [ dateFormat[2], dateFormat[1], dateFormat[0] ].join('-')
  // const memberColorClass = `member-color-${item.userName}`;
  // console.log(item);
  // console.log("item");
  return (
    <>
      
      {

        // if consume credit points are positive means buy plan or add extra credit point assign by admin render in special color
        Number(item.crpoint) > 0 ?
        <tr className={`mt-2`} style={{width:'100%' ,  background:isDark? 'linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)':'#1E1E1E'}}>
            <th scope="row" style={{ background: 'transparent', color:isDark?'black':'white'}}>{dateFormat}</th>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.name}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.userName}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.transaction}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.crpoint}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.balance}</td>
        </tr>
        :
        <tr className={`mt-2`} style={{width:'100%', background: isDark?'linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)':'#1E1E1E'}}>
            <th scope="row" style={{ background: 'transparent', color:isDark?'black':'white'}}>{dateFormat}</th>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.name}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.userName}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.transaction}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.crpoint}</td>
            <td style={{ background: 'transparent', color:isDark?'black':'white'}}>{item.balance}</td>
        </tr>
      }
    </>
    
  )
}

export default CreditHistoryData
