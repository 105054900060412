import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPreviewPost,togglePreview } from '../../../../store/features/previewPost/previewPostSlice'
import { IMAGE_URL } from '../../../../api/API'

const QueueItems = (props) => {
    const { isDark } = useSelector(state=>state.common)
    const previewPost = useSelector(state=>state.previewPost)
    const dispatch = useDispatch()

  return (
    <>
        <div className={`queue-post-items ${ !props.data?.postTag ? ( isDark ? 'normal-bg-color-light' : 'normal-bg-color-dark') : (props.data?.postTag === 'EVERGREEN' ? 'evergreen-bg-color' : 'schedule-bg-color')} ${isDark ? '' : (props.data?.postTag !== 'EVERGREEN') && 'queue-text-color-dark'}` }>
            <div className="queue-post-left">
                {
                    !props.data?.postTag ? <i className="fa-regular fa-clock" style={{color: "grey"}}></i>
                    : (props.data?.postTag === 'EVERGREEN'
                        ? <i className="fa-solid fa-star" style={{color: "#2b5eb6"}} title='Evergreen'></i> 
                        : <i className="fa-regular fa-clock"></i>)
                }
                {/* <div className='queue-time'>{new Date(localDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</div> */}
                <div className='queue-time max-sm:text-[8px]'>{props.actualTime}</div>
                <div className={`queue-content ${ (props.data.postContent === ' You have not scheduled Any Post') && 'queue-content-no-post'} `}>{props.data?.postContent?.length > 50 ? `${props.data?.postContent?.slice(0,50)}...` : props.data?.postContent }</div>
            </div>
            <div className="queue-post-right">
                <div className='d-flex align-items-center'>
                    {
                        props.data?.platformPost?.linkedin && <img src={`${IMAGE_URL}/images/socialIcon/linkedin.png`} style={{height:'18px', width:'18px'}}></img>
                    }
                    {
                        props.data?.platformPost?.twitter && <img src={`${IMAGE_URL}/images/socialIcon/tweeter-dark.png`} style={{height:'18px', width:'18px',}}></img>
                    }
                    {
                        props.data?.platformPost?.facebook && <img src={`${IMAGE_URL}/images/socialIcon/Facebook.png`} style={{height:'18px', width:'18px',}}></img>
                    }
                    {
                        props.data?.platformPost?.instagram && <img src={`${IMAGE_URL}/images/socialIcon/Instagram.png`} style={{height:'18px', width:'18px',}}></img>
                    }
                </div>
                {
    props.data?.status && (
        props.data.status === 'PUBLISH' ? 
        (
            <div className={`queue-status evergreen-status-color`}>
                <i className="fa-solid fa-check-double fa-beat-fade"></i>Published
            </div>
        ) : props.data.status === 'FAILED' ? 
        (
            <div className={`queue-status failed-status-color`}>
                <i className="fa-solid fa-exclamation-triangle" style={{color: "#ffffff"}}></i>Failed
            </div>
        ) :
        (
            <div className={`queue-status schedule-status-color`}>
                <i className="fa-regular fa-clock fa-spin" style={{color: "#f7f7f8"}}></i>Scheduled
            </div>
        )
    )
}

                {
                    props.data.status && <>
                        <i className="fa-regular fa-eye" onClick={()=>{ dispatch(addPreviewPost(props.data)); dispatch(togglePreview(true)) }}></i>
                        <i className="fa-solid fa-pencil" onClick={()=>props.editPost(props.data)}></i>
                        <i className="fa-regular fa-trash-can" onClick={()=>props.deleteQueueConfirm(props.data)}></i>
                    </>
                }
            </div>
        </div>
    </>
  )
}

export default QueueItems