import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, IMAGE_URL } from '../../api/API';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/features/user/authSlice';
import { fetchUserInfo } from '../../store/features/user/userSlice';
import { Eye, EyeOff } from 'lucide-react';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const img_src = [
    `${IMAGE_URL}/images/loginbgicons/calendar.jpg`,
    `${IMAGE_URL}/images/loginbgicons/star.jpg`,
    `${IMAGE_URL}/images/loginbgicons/discover.jpg`,
    `${IMAGE_URL}/images/loginbgicons/star.jpg`,
    `${IMAGE_URL}/images/loginbgicons/calendar.jpg`
  ];

  const forgetPasswordHandler = () => {
    navigate('/forget-password');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${BASE_URL}userprofile/login`, {
        email,
        password
      }, { withCredentials: true });

      if (response.data.authenticated) {
        dispatch(setToken(response.data.token));
        dispatch(fetchUserInfo(response.data.token));

        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        setError('Authentication failed');
      }
    } catch (error) {
       if (error.response) {
        if (error.response.status === 401) {
          setError(error.response.data.message);
          setPassword('');
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else if (error.response.status === 403 && error.response.data.redirectUrl) {
          console.log(error.response.data);
          // console.log(response.data);
          // console.log(response.data);
          dispatch(setToken(error.response.data.token));
          // Handle redirection to LinkedIn authentication
          window.location.href = error.response.data.redirectUrl;
        } else {
          setError('An error occurred during login');
        }
      } else {
        setError('Network error occurred');
      }
      console.error('Login error:', error);
    }
  };

  return (
    <div className='login-container'>
      <div className="login-box max-md:pl-10 max-md:pr-10">
        <img src={`${IMAGE_URL}/images/logo.svg`} alt="" />
        <h3>An AI-fueled Professional growth awaits you!</h3>
        <form onSubmit={handleLogin}>
          <div className="form-group md:mt-20">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center relative">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
                className='absolute right-3 top-2 cursor-pointer'
                type='button'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Eye /> : <EyeOff />}
              </span>
          </div>
          <button type="submit" className="btn btn-primary gradient-button mt-5" style={{ width: "100%" }}>Sign In</button>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
        <div className='mt-4 max-md:text-[15px]'>

          <a href='/forget-password' onClick={forgetPasswordHandler}>
            Forgot your password?
          </a>
        </div>
        <p className="mt-3 max-md:text-[15px]">Don't have an account? <a href="/register">Sign Up</a></p>
      </div>
    </div>
  );
};

export default Login;