import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { bookmarkState } from "./initialState"
import { getApi } from "../../../api/methods";

const bookmarkSlice = createSlice({
    name: 'Bookmark',
    initialState : bookmarkState,
    reducers: {
        // currently no use in this project
        addBookmarkData: (state,action) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserBookmark.fulfilled, (state,action)=>{
            console.log('user/getBookmark success');

            return { ...state, bookmarkData:[...action.payload] }
        })
        builder.addCase(fetchUserBookmark.rejected, (state,action)=>{
            console.warn('user/getBookmark error');
            return { bookmarkData:[] }
        })
    }
})

// fetch all bookmark posts of perticular user as per page of 12 items
export const fetchUserBookmark = createAsyncThunk('user/getBookmark',async (_,thunkAPI)=>{
    const {userId, page} = _
    const response = await getApi(`book-marks/bookmark-posts/${userId}?page=${page}`)
    return  response.data
})

export const { addBookmarkData } = bookmarkSlice.actions
export default bookmarkSlice.reducer