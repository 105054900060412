export const colorPalette = [
  {
    name: "Light",
    colorsBox: [
      {
        c1: "#ffffff",
        c2: "#666666",
        c3: "#D93F3C",
        c4: "#F2F2F2",
      },
      {
        c1: "#ffffff",
        c2: "#666666",
        c3: "#006838",
        c4: "#F2F2F2",
      },
      {
        c1: "#ffffff",
        c2: "#666666",
        c3: "#3F48CC",
        c4: "#F2F2F2",
      },
      {
        c1: "#ffffff",
        c2: "#666666",
        c3: "#FBC02D",
        c4: "#F2F2F2",
      },
    ],
  },
  {
    name: "Dark",
    colorsBox: [
      {
        c1: "#111111",
        c2: "#FFFFFF",
        c3: "#D93F3C",
        c4: "#333333",
      },
      {
        c1: "#111111",
        c2: "#FFFFFF",
        c3: "#2C9955",
        c4: "#333333",
      },
      {
        c1: "#111111",
        c2: "#FFFFFF",
        c3: "#516BCC",
        c4: "#333333",
      },
      {
        c1: "#111111",
        c2: "#FFFFFF",
        c3: "#F9C80E",
        c4: "#333333",
      },
    ],
  },
  {
    name: "Vibrant",
    colorsBox: [
      {
        c1: "#FFF2E4",
        c2: "#7A552B",
        c3: "#C65911",
        c4: "#FFEDDA",
      },
      {
        c1: "#DAF8E7",
        c2: "#2E583D",
        c3: "#2C9955",
        c4: "#EBFFF8",
      },
      {
        c1: "#CCDAFF",
        c2: "#394A7B",
        c3: "#516BCC",
        c4: "#DEE7FF",
      },
      {
        c1: "#FDF6BA",
        c2: "#927A49",
        c3: "#DBAE37",
        c4: "#FDFBE0",
      },
    ],
  },
  {
    name: "Earthy",
    colorsBox: [
      {
        c1: "#F2DEC8",
        c2: "#847459",
        c3: "#C08552",
        c4: "#F8E8D9",
      },
      {
        c1: "#E1EFD7",
        c2: "#728A64",
        c3: "#4B825D",
        c4: "#EDEFEE",
      },
      {
        c1: "#EFDDFF",
        c2: "#695273",
        c3: "#926CAE",
        c4: "#F3E9FF",
      },
      {
        c1: "#FFEDD9",
        c2: "#B66328",
        c3: "#DF842D",
        c4: "#FFF4E5",
      },
    ],
  },
  {
    name: "Professional",
    colorsBox: [
      {
        c1: "#EEF0F4",
        c2: "#5B6F7F",
        c3: "#2D58A9",
        c4: "#F3F6FA",
      },
      {
        c1: "#E7EEDE",
        c2: "#7B956D",
        c3: "#53917A",
        c4: "#F1F4EC",
      },
      {
        c1: "#FAE4E4",
        c2: "#993D3D",
        c3: "#D13D44",
        c4: "#FFEAEA",
      },
      {
        c1: "#F7F6FF",
        c2: "#684C91",
        c3: "#9D71B9",
        c4: "#FDFDFF",
      },
    ],
  },
  {
    name: "Playful",
    colorsBox: [
      {
        c1: "#FEF1DA",
        c2: "#CE8035",
        c3: "#C26F27",
        c4: "#FFF8E8",
      },
      {
        c1: "#E4F7FC",
        c2: "#2DA3B0",
        c3: "#22B6B0",
        c4: "#E8FAFC",
      },
      {
        c1: "#F3EBF5",
        c2: "#513871",
        c3: "#7B5291",
        c4: "#F8ECFA",
      },
      {
        c1: "#FEFDF8",
        c2: "#8B786D",
        c3: "#AC8179",
        c4: "#FFFEF9",
      },
    ],
  },
  {
    name: "Bold",
    colorsBox: [
      {
        c1: "#11004D",
        c2: "#FFFFFF",
        c3: "#BB0088",
        c4: "#332288",
      },
      {
        c1: "#004D00",
        c2: "#FFFFFF",
        c3: "#00BB00",
        c4: "#225811",
      },
      {
        c1: "#222BBB",
        c2: "#FFFFFF",
        c3: "#AA77CC",
        c4: "#4444DD",
      },
      {
        c1: "#771144",
        c2: "#FFFFFF",
        c3: "#EE3377",
        c4: "#992255",
      },
    ],
  },
  {
    name: "Pastel",
    colorsBox: [
      {
        c1: "#F9E9F4",
        c2: "#B782AF",
        c3: "#D88CB4",
        c4: "#FDF4FC",
      },
      {
        c1: "#E8F9EF",
        c2: "#31A2A6",
        c3: "#0ABCD3",
        c4: "#EDFDFC",
      },
      {
        c1: "#FEDCE7",
        c2: "#9A5271",
        c3: "#D462A0",
        c4: "#FFECF8",
      },
      {
        c1: "#FCFAC4",
        c2: "#A68751",
        c3: "#D4A253",
        c4: "#FFFADB",
      },
    ],
  },
];
