import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function TopInteractors() {
  const analyticsData = useSelector((s) => s.analytics);
  const [active, setActive] = useState(0);
  const [activeUser, setActiveUser] = useState();
  const [filteredInteractions, setFilteredInteractions] = useState([]);
  const image = "/images/pp.jpeg";
  const { isDark } = useSelector((state) => state.common);

  const handleClick = (index, userId) => {
    setActive(index);
    const obj = filteredInteractions.find((s) => s.userId === userId);
    setActiveUser(obj);
    console.log(obj);
  };

  useEffect(() => {
    const newFilteredInteractions = analyticsData.interactions
      ? analyticsData.interactions.filter((interaction) => interaction.name)
      : [];
    setFilteredInteractions(newFilteredInteractions);

    if (newFilteredInteractions.length > 0) {
      setActiveUser(newFilteredInteractions[0]);
    } else {
      setActiveUser(null); // Set activeUser to null if there are no interactions
    }
  }, [analyticsData.interactions]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-nowrap items-center">
        {filteredInteractions.map((s, index) => (
          <img
            src={s.profilePicture ? s.profilePicture : image}
            key={index}
            className={`cursor-pointer ${
              active === index
                ? isDark
                  ? "border-[3px] border-solid border-transparent w-28 h-28 bg-gradient-to-t from-[#8A51FF] via-[#39B2FF] to-[#39B2FF] bg-clip-border"
                  : "border-white w-28 h-28"
                : isDark
                ? "border-white w-20 h-20"
                : "border-[#2D2D2D] w-20 h-20"
            } ${
              index !== 0 ? "-ml-[20px] z-[index]" : "-ml-0"
            } w-20 h-20 border-4 relative`}
            style={{
              borderRadius: "50%",
              zIndex: active === index ? "1" : "0",
            }}
            onClick={() => handleClick(index, s.userId)}
            alt="Profile"
          />
        ))}
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          {activeUser ? (
            <>
              <p className="text-lg">{activeUser.name}</p>
              <p className="text-base" style={{ color: "#939BA5" }}>
                {activeUser.designation}
              </p>
            </>
          ) : (
            <div className="flex justify-center items-center mb-4">
              <p>No Interactions</p>
            </div> // Show a message if there are no interactions
          )}
        </div>
        <div className="flex gap-4">
          {activeUser ? (
            <div className="flex items-center gap-2">
              <p>{activeUser.totalLikesOnAllPosts}</p>
              <img src="/images/likeIcon.svg" alt="Like" />
            </div>
          ) : null}
          {activeUser ? (
            <div className="flex items-center gap-2">
              <p>{activeUser.totalCommentsOnAllPosts}</p>
              <img src="/images/commentIcon.svg" alt="Comment" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TopInteractors;
