export const generateAiPostState = {
  generationType: { value: "", label: "Select..." },
  postTopic: "",
  isDisabledInput: false,
  AIState: {
    AItopic: "",
    AIcatagory: "",
    AICarouselTitle: "",
    AICarouselNumber: 1,
    AIlength: "",
  },
};
