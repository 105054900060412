import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { scheduleDataState } from './initialState'
import { getApi } from "../../../api/methods";

const scheduleDataSlice = createSlice({
    name: 'schedueData',
    initialState: scheduleDataState,
    reducers: {
        // change queue data according to date
        changeMyQueueDate: (state, action) => {
            return { ...state, myQueueDate: action.payload }
        },
        // if we delete queue data from existing datas so filter it out
        deleteMyQueueData: (state, action) => {
            const id = action.payload
            const data = { ...state.myQueueData }
            const extractkeys = Object.keys(data)
            extractkeys.forEach(item => {
                data[item] = data[item].filter(i => {
                    return i._id !== id
                })
            })

            return { ...state, myQueueData: data }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchScheduleData.fulfilled, (state, action) => {
            console.log('fetchscheduleData success');
            return { ...state, scheduledData: [action.payload] }
        })
        builder.addCase(fetchScheduleData.rejected, (state, action) => {
            console.warn('fetchscheduleData rejected');
            return scheduleDataState
        })
        builder.addCase(fetchQueueData.fulfilled, (state, action) => {

            let data = []

            // if fetch data is not coming then queue data is empty with manage all previous datas 
            if (action.payload?.length < 0) {
                return { ...state, myQueueData: {} }
            } else {
                data = [...action.payload]
            }

            // Create an empty object to store grouped data
            const groupedData = {};

            data.forEach(item => {
                // Extract the date part (YYYY-MM-DD) from the date_time
                // Function to format a date/time string in a specific time zone
                function formatTimeInTimeZone(date, timeZone) {
                    const options = { timeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
                    return date.toLocaleString('en-US', options).replace(',', '');
                }

                // Function to format a UTC date/time string in a specific time zone
                function formatUtcTimeInTimeZone(date, timeZone) {
                    const year = date.getUTCFullYear();
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const hours = String(date.getUTCHours()).padStart(2, '0');
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

                    const localDate = new Date(Date.UTC(year, date.getUTCMonth(), day, hours, minutes));
                    return formatTimeInTimeZone(localDate, timeZone);
                }

                // Get the current date/time in ISO format
                const standardData = new Date().toISOString()

                // Combine the item's date_time with the current time's milliseconds to get a full ISO date/time string
                const currentDate = new Date(item.date_time + '.' + standardData.split('.')[1]);

                // Get the user's time zone
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                // Format the current date/time in the user's time zone
                const formattedTimeInUserTimeZone = formatUtcTimeInTimeZone(currentDate, userTimeZone);

                // Extract the date part from the formatted date/time string
                let date = formattedTimeInUserTimeZone.split(' ')[0];
                date = date.split('/')
                date = [date[2], date[0], date[1]].join('-')

                // If the date is not in the groupedData object, create an empty array for it
                if (!groupedData[date]) {
                    groupedData[date] = [];
                }

                // Push the item to the corresponding group based on the date
                groupedData[date].push(item);
            });

            return { ...state, myQueueData: groupedData }
        })
        builder.addCase(fetchQueueData.rejected, (state, action) => {
            console.warn('user/QueueData rejected');
            return scheduleDataState
        })
    }
})

// fetch all schedule posts
export const fetchScheduleData = createAsyncThunk('user/scheduleData', async (_, thunkAPI) => {
    const { userId, startDate, endDate } = _
    if (userId && startDate && endDate) {
        const response = await getApi(`schedule/get-scheduledata/${userId}?startDate=${startDate}&endDate=${endDate}`);
        return response.data
    }
})

// fetch all queue posts
export const fetchQueueData = createAsyncThunk('user/QueueData', async (_, thunkAPI) => {
    const { userId, startDate, endDate } = _
    if (userId && startDate && endDate) {
        const response = await getApi(`schedule/get-queuedata/${userId}?startDate=${startDate}&endDate=${endDate}`);
        return response.data
    }
})
export const { changeMyQueueDate, deleteMyQueueData } = scheduleDataSlice.actions
export default scheduleDataSlice.reducer
