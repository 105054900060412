import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PostCard from '../../components/PostCard/PostCard'
import './OTG.css'
import { fetchUserOTG, fetchUserOTGById } from '../../store/features/otg/otgSlice'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useRef } from 'react'
import PreviewPost from '../../components/PostIdea/PreviewPost/PreviewPost'
import { Link } from 'react-router-dom'
import { changeLayoutShift } from '../../store/features/common/commonSlice'

const OTG = () => {
  const user = useSelector(state=>state.user)
  const otgs = useSelector(state=>state.otgs)
  const post = useSelector(state=>state.post)
  const common = useSelector(state=>state.common)
  const dispatch = useDispatch()
  const {layoutShift, isDark} = useSelector((state) => state.common);

  // useEffect(()=>{
  //   dispatch(changeLayoutShift(false));
  // },[]);

  // fetch 10 otg posts of the date 
  useEffect(()=>{
    if(otgs.otgData?.length < 10) {
      dispatch(fetchUserOTGById({ userId:user.userData?.linkedinId }))
    }
  },[])
  useEffect(()=>{
    dispatch(fetchUserOTGById({ userId:user.userData?.linkedinId }))

    // if(otgs.otgData?.length < 10) {
    //   dispatch(fetchUserOTG({ userId:user.userData?.linkedinId }))
    // }
  },[user.userData.linkedinId])
  useEffect(()=>{
    if(common.loading){
      dispatch(fetchUserOTGById({ userId:user.userData?.linkedinId }))

    }
  },[common.loading])

  return (
    <>
    {/* <div className="row" > */}
    {/* <div className={`mb-5 ${"col-md-12"}`} style={{overflowY: "auto"}}> */}
    {/* <div className="otg-container" > */}
        {/* <div className="mb-4"> */}
          <div className={`flex flex-col gap-4 py-12 ${layoutShift?'pl-24 pr-20':'px-9 xs:px-20'}`} id='hide-scrollbar'>
            <div className='flex flex-col'>
              <h3 className='text-sm sm:text-lg'>A daily dose of AI posts personalized to your interest.</h3>
              <p className='mt-2 text-base'>Manage your <u> <Link to='/my-accounts?aiConfig=1' className='text-blue-600'> interests</Link></u></p>
            </div>

        <div className="masonry-grid"  >
          {
              otgs.otgData?.length === 0 && <h3 className='flex justify-center items-center w-100 mt-5' style={{textAlign:"center"}}>Generating, please allow a moment</h3>
          }
            {
              otgs.otgData?.map((item,i)=>{

                const data = {
                  userId: item?.userId,
                  postId: item?.otg_id,
                  postContent : item?.otgContent,
                  postType: 'NONE',
                  createdAt: item?.createdAt,
                  Bookmark: item?.Bookmark,
                  Evergreen: item?.Evergreen
                }
                return <div className=' masonry-grid-item' key={i}>
                  <PostCard key={i} topic={item?.postCategoryName} des={item?.otgContent} image={item?.originalUrl} postype={item?.postType} data={data} isEvergreen={true} isBookmark={true}/>
                  </div>
              })
            }
        </div>
        </div>
    {/* </div> */}
    {/* </div> */}
    {/* </div> */}
        {/* {
          post.postContent && <div className="col-xxl-3 col-lg-4 col-md-5 mt-4" style={{transitionDelay: '5s', boxShadow: '6px 8px 10px rgba(128, 128, 128,0.5)'}}>
              <PreviewPost isPreview={true} isDeleteBtnHide={true} />
            </div>
        } */}
    
    {/* </div> */}
  </>
  )
}

export default OTG