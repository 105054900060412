import React, { useState, useEffect } from 'react';
import './CreditHistory.css';
import CreditHistoryData from '../../components/creditHistory/CreditHistoryData';
import { getApi } from '../../api/methods';
import { useSelector } from 'react-redux';
import MobileNotification from '../../components/Mobile/MobileNotification';

const CreditHistory = () => {
  const { userData } = useSelector(state => state.user);
  const { isDark } = useSelector(state => state.common);
  const [creditData, setCreditData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchCreditHistory();
  }, [userData.linkedinId]);

  // fetch all credit history if users team id, all team members credit history
  const fetchCreditHistory = async () => {
    try {
      const response = await getApi(`credit/get-credit-history/${userData.teamId}`);
      setCreditData(response.data);
      console.log(response.data);
    } catch (error) {
      console.warn(`fetchCreditHistory ${error}`);
    }
  };

  // Pagination implemented
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  // Calculate the range of items to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = creditData?.slice(startIndex, endIndex);
  console.log(currentData);

  // Logic for assigning member color scheme
  const memberColorsMap = new Map();

  if (isMobile) {
    return <MobileNotification />;
  }

  return (
    <>
      <div className='table-container'>
        <div className='credit-history-heading'>
          <h3 className='text-xl'>Your Credits for the Use</h3>
        </div>
        <table className="table table-credit mt-2 " style={{width:'100%'}}>
          <thead className='' style={{width:'100%', backgroundColor:'#4B78F9', borderRadius:'20px'}}>
            <tr style={{width:'100%', backgroundColor:'#4B78F9', fontWeight:300, borderRadius:'20px'}}>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Date</th>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Account Type</th>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Team Member</th>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Transaction</th>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Credit</th>
              <th scope="col" style={{backgroundColor:'#4B78F9', color:'white', fontWeight:300}}>Balance</th>
            </tr>
          </thead>
          <tbody style={{width:'100%'}}>
            {creditData?.length === 0 && (
              <tr style={{ textAlign: 'center',width:'100%'  }}>
                <td colSpan={7} style={isDark ? { color: 'red', background: '#fff' } : { color: 'red', background: '#000' }}>You don't have any plan, please buy it.</td>
              </tr>
            )}
            {currentData?.map((item, i) => {
              let memberColorClass = memberColorsMap.get(item.userName);
              if (!memberColorClass) {
                memberColorClass = `member-color-${memberColorsMap.size + 1}`;
                memberColorsMap.set(item.userName, memberColorClass);
              }
              console.log(memberColorClass);

              return <CreditHistoryData item={item} key={i} memberColorClass={memberColorClass} />;
            })}
          </tbody>
        </table>
        <div className='d-flex justify-content-center align-items-center'>
          <button className={isDark? 'btn grediant-button':'btn text-white'} style={{ border: 'none' }} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <span className='m-1'>{`Page ${currentPage} of ${Math.ceil(creditData.length / itemsPerPage)}`}</span>
          {currentPage < Math.ceil(creditData.length / itemsPerPage) && (
            <button className={isDark? 'btn grediant-button':'btn text-white'} style={{ border: 'none' }} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(creditData.length / itemsPerPage)}>Next</button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreditHistory;
