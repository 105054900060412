import { axiosInstance } from "./API";

const options = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const getApi = async (url, options) => {
    const response = await axiosInstance.get(url, options = options);
    return response.data;
};

export const postApi = async (url, body, options) => {
    const response = await axiosInstance.post(url, body, options = options);
    return response.data;
};

export const patchApi = async (url, data, options) => {
    const response = await axiosInstance.patch(url, data, options = options);
    return response.data;
};

export const deleteApi = async (url, body, options) => {
    const response = await axiosInstance.delete(url, {
        data: body
    }, options = options);
    return response.data;
};

export const putApi = async (url, body, options) => {
    const response = await axiosInstance.put(url, body, options = options);
    return response.data;
};
