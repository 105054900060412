import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { draftState } from "./initialState"
import { deleteApi, getApi } from "../../../api/methods";

const draftSlice = createSlice({
    name: 'Draft',
    initialState : draftState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserDraft.fulfilled, (state,action)=>{
            console.log('user/getDraft success');

            return { ...state, draftData:[...action.payload] }
        })
        builder.addCase(fetchUserDraft.rejected, (state,action)=>{
            console.warn('user/getDraft error');
            return { draftData:[] }
        })
        builder.addCase(deleteUserDraft.fulfilled, (state,action)=>{
            console.log('user/deleteDraft success');
            const deleteDraft = state.draftData.filter(item=>item._id !== action.payload._id)
            return { ...state, draftData:deleteDraft }
        })
        builder.addCase(deleteUserDraft.rejected, (state,action)=>{
            console.warn('user/deleteDraft error');
            return { draftData:[] }
        })
    }
})

// get all draft posts of perticular user by page of 12 items 
export const fetchUserDraft = createAsyncThunk('user/getDraft',async (_,thunkAPI)=>{
    const {userId, page} = _
    const response = await getApi(`draft/draft-posts/${userId}?page=${page}`)
    return  response.data
})

// delete a draft post 
export const deleteUserDraft = createAsyncThunk('user/deleteDraft',async (_,thunkAPI)=>{
    const postId = _
    const response = await deleteApi(`draft/draft-post-delete`,{ postId })
    return  response.data
})

// export const { deleteDraftPost } = draftSlice.actions
export default draftSlice.reducer