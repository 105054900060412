import React, { useEffect, useState } from 'react'
import FlagSelect from 'react-flags-select';
import CreatableSelect from 'react-select/creatable';
import TimezoneSelect from 'react-timezone-select';
const DescribeYourSelf = (props) => {
  const [getProfess, setGetProfess] = useState(props.getUserTopics.userPromtAIProfession)
  const [selectedOptions, setSelectedOptions] = useState(props.getUserTopics.userAIPromtTopics);
  const [selectedCountry, setSelectedCountry] = useState(props.getUserTopics.language || 'US');
  const [selectedTimezone, setSelectedTimezone] = useState( props.getUserTopics.timeZone ? JSON.parse(props.getUserTopics.timeZone) : '' );
  // console.log({ selectedOptions });
  const handleSelectChange = (newValue) => {
    // Map the selected options to an array of string values
    const stringValues = newValue?.map(option => option.value);
    setSelectedOptions(stringValues);
  };
  
  useEffect(() => {
    // Update setUserTopics with new user information
    props.setUserTpoics((prev) => {
      return {
        ...prev,
        language: selectedCountry,
        timeZone: JSON.stringify(selectedTimezone),
        userPromtAIProfession: getProfess,
        userAIPromtTopics:selectedOptions
      }
    });
  }, [selectedCountry, selectedTimezone, getProfess, selectedOptions]);
  
  const handleTimezoneChange = (timezone) => {
    // Update the selected timezone
    setSelectedTimezone(timezone);
  };
  
  const handleSelect = (country) => {
    // Update the selected country
    setSelectedCountry(country);
  };
  
  const formatLabel = (country) => {
    // Format the country label with flag, label, and code
    return `${country.flag} ${country.label} (${country.code})`;
  };

  // console.log({ selectedTimezone });

  return (
    <>
      <div className="container container-margin-yourSelf">
        <div className="row">
          <div className="col-10 mx-auto ai-container">
            <p className='P1' style={{color:"rgb(105,115,129)"}}>Configure your personal AI</p>
            <h3 className='mb-4 mt-4 H2'> How would you describe yourself on Linkedin?</h3>
            <p className="p2 mb-4" >Who are you? What is it you do and talk about?</p>
            <p  className='label mb-2' >I am a...</p>
            <input type="text" className='form-control mb-3' name='getProfess' 
            value={getProfess} onChange={(e) => setGetProfess(e.target.value)} placeholder='Who are you...' />
            <p className='label mt-4 mb-2'>I usually post about...</p>
            {/* <CreatableSelect
              isMulti
              value={selectedOptions.map(value => ({ label: value, value }))}
              onChange={handleSelectChange}
              className='mb-3'
            /> */}
            <textarea className='form-control' placeholder='Description' value={selectedOptions} onChange={e=>setSelectedOptions(e.target.value)}  />
            <p className='label mt-4 mb-2'>I write in...<span style={{fontSize:'0.6rem'}}>(Optional)</span></p>
            <FlagSelect
              onSelect={handleSelect}
              selected={selectedCountry}
              showSelectedLabel={true}
              // countries={['US', 'FR', 'ES', 'DE']} // Add more country codes as needed
              countries={['US']} // Add more country codes as needed
              customLabels={{
                US: 'English (US)',
                // FR: 'French (FR)',
                // ES: 'Spanish (ES)',
                // DE: 'German (DE)',
              }}
              customLabelsCallback={(code) => `Flag ${code}`} // Add custom label format
              formatLabel={formatLabel} // Customize the label format
              className='mb-3'
              placeholder= 'Select a Language'
            />

            <p className='label mt-4 mb-2'>My timezone is...<span style={{fontSize:'0.6rem'}}>(Optional)</span></p>
            <TimezoneSelect
              // defaultInputValue="Asia/Kolkata"
              value={selectedTimezone}
              onChange={handleTimezoneChange}
              className='mb-5'
            />
            <div className='btn-class'>
            <button onClick={props.click} >Back</button>
            <button onClick={() => props.handleSaveInfo()} >Submit</button>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default DescribeYourSelf