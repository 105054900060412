import { useDispatch } from "react-redux";
import { colorPalette } from "./colors";
import {
  bgColorChange,
  boxColorChange,
} from "../../../store/features/carousel/action/canvaAction";
import {
  descColorSet,
  pageNoColorSet,
  subTitleColorSet,
  titleColorSet,
} from "../../../store/features/carousel/action/textAction";
import { headShotColorSet } from "../../../store/features/carousel/action/headShotAction";
import {
  bookmarkColorSet,
  swipeColorSet,
} from "../../../store/features/carousel/action/settingsAction";
import { useSelector } from "react-redux";

function ColorBar() {
  const { bgColor, boxDivColor } = useSelector((s) => s.canvaReducer);
  const { headShotColor } = useSelector((s) => s.headShotReducer);
  const { swipeColor } = useSelector((s) => s.settingsReducer);

  const dispatch = useDispatch();

  const handleColors = (c1, c2, c3, c4) => {
    dispatch(bgColorChange(c1));
    dispatch(titleColorSet(c2));
    dispatch(subTitleColorSet(c2));
    dispatch(descColorSet(c2));
    dispatch(headShotColorSet(c2));
    dispatch(swipeColorSet(c3));
    dispatch(pageNoColorSet(c3));
    dispatch(bookmarkColorSet(c3));
    dispatch(boxColorChange(c4));
  };

  const handleBgColor = (data) => {
    dispatch(bgColorChange(data));
  };
  const handleHeadShotColor = (data) => {
    dispatch(titleColorSet(data));
    dispatch(subTitleColorSet(data));
    dispatch(descColorSet(data));
    dispatch(headShotColorSet(data));
  };
  const handleSwipeColor = (data) => {
    dispatch(swipeColorSet(data));
    dispatch(pageNoColorSet(data));
    dispatch(bookmarkColorSet(data));
  };
  const handleBoxDivColor = (data) => {
    dispatch(boxColorChange(data));
  };

  return (
    <>
      <div className="pt-6 pb-36 px-3 flex flex-col gap-3 overflow-y-auto">
        <p className="font-bold">Colors</p>
        <div className="flex flex-col border-[1px] p-2 border-gray-200 gap-2 rounded-lg w-[290px] h-[75px]">
          <p className="text-sm font-semibold">Custom</p>
          <div className="flex h-[28px] gap-2">
            <div className="w-1/4 h-full border-[1px] border-gray-300 rounded-md flex">
              <input
                type="color"
                value={bgColor}
                onChange={(e) => handleBgColor(e.target.value)}
                id="color1"
                className="w-full h-[26px]"
              ></input>
            </div>
            <div className="w-1/4 h-full border-[1px] border-gray-300 rounded-md flex">
              <input
                type="color"
                value={headShotColor}
                onChange={(e) => handleHeadShotColor(e.target.value)}
                id="color1"
                className="w-full h-[26px]"
              ></input>
            </div>
            <div className="w-1/4 h-full border-[1px] border-gray-300 rounded-md flex">
              <input
                type="color"
                id="color1"
                value={swipeColor}
                onChange={(e) => handleSwipeColor(e.target.value)}
                className="w-full h-[26px]"
              ></input>
            </div>
            <div className="w-1/4 h-full border-[1px] border-gray-300 rounded-md flex">
              <input
                type="color"
                id="color1"
                value={boxDivColor}
                onChange={(e) => handleBoxDivColor(e.target.value)}
                className="w-full h-[26px]"
              ></input>
            </div>
          </div>
        </div>
        {colorPalette.map((c) => (
          <div className="flex flex-col border-[1px] border-gray-200 p-2 gap-2 rounded-lg w-[290px] h-[75px]">
            <p className="text-sm font-semibold">{c.name}</p>
            <div className="flex h-[28px] gap-2">
              {c.colorsBox.map((color) => (
                <div
                  className="w-1/4 h-full border-[1px] border-gray-300 rounded-md flex p-[2px] cursor-pointer"
                  onClick={() =>
                    handleColors(color.c1, color.c2, color.c3, color.c4)
                  }
                >
                  <div
                    className="w-1/4 h-full rounded-l-sm"
                    style={{ backgroundColor: color.c1 }}
                  ></div>
                  <div
                    className="w-1/4 h-full"
                    style={{ backgroundColor: color.c2 }}
                  ></div>
                  <div
                    className=" w-1/4 h-full"
                    style={{ backgroundColor: color.c3 }}
                  ></div>
                  <div
                    className="w-1/4 h-full rounded-r-sm"
                    style={{ backgroundColor: color.c4 }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ColorBar;
