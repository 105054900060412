import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { historyState } from "./initialState"
import { getApi } from "../../../api/methods";

const historySlice = createSlice({
    name: 'History',
    initialState : historyState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserHistory.fulfilled, (state,action)=>{
            console.log('user/getHistory success');

            return { ...state, historyData:[...action.payload] }
        })
        builder.addCase(fetchUserHistory.rejected, (state,action)=>{
            console.warn('user/getHistory error');
            return { historyData:[] }
        })
    }
})

// fetch all published posts including schedule post that published in given time as per page of 12 items
export const fetchUserHistory = createAsyncThunk('user/getHistory',async (_,thunkAPI)=>{
    const {userId, page} = _
    const response = await getApi(`published/user/${userId}?page=${page}`)
    return  response.data
})

// export const { display,addName } = historySlice.actions
export default historySlice.reducer