import { axiosInstance } from "../../../../api/API";


export const resetSlides = () => ({
  type: "RESET_SLIDES"
});


export const adjustSubtitle = (data) => {
  return (dispatch) => {
    dispatch({ type: "ADJUST_SUBTITLE", payload: data });
  };
};

export const adjustTitle = (data) => {
  return (dispatch) => {
    dispatch({ type: "ADJUST_TITLE", payload: data });
  };
};

export const adjustDesc = (data) => {
  return (dispatch) => {
    dispatch({ type: "ADJUST_DESC", payload: data });
  };
};

export const adjustImage = (data) => {
  return (dispatch) => {
    dispatch({ type: "ADJUST_IMAGE", payload: data });
  };
};

export const addSlideData = (data) => {
  return (dispatch) => {
    dispatch({ type: "ADD_SLIDE", payload: data });
  };
};

export const duplicateSlideData = (data) => {
  return (dispatch) => {
    dispatch({ type: "DUPLICATE_SLIDE", payload: data });
  };
};

export const deleteSlideData = (index) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_SLIDE", payload: index });
  };
};

export const backgroundImageSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_IMAGE",
      payload: data,
    });
  };
};

export const backgroundImageAllSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_ALL_IMAGE",
      payload: data,
    });
  };
};

export const backgroundAllSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_ALL_SET",
      payload: data,
    });
  };
};

export const revertbackgroundImageSet = () => {
  return (dispatch) => {
    dispatch({
      type: "REVERT_BACKGROUND_IMAGE",
    });
  };
};

export const backgroundImageOpacitySet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_IMAGE_OPACITY",
      payload: data,
    });
  };
};

export const backgroundImageSizeSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_IMAGE_SIZE",
      payload: data,
    });
  };
};

export const backgroundImagePositionSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_IMAGE_POSITION",
      payload: data,
    });
  };
};

export const backgroundImageRemove = (id) => {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_IMAGE_REMOVE",
      payload: id,
    });
  };
};

export const imageSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "IMAGE_SET",
      payload: data,
    });
  };
};
export const imageSizeSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "IMAGE_SIZE",
      payload: data,
    });
  };
};

export const imagePositionSet = (data) => {
  return (dispatch) => {
    dispatch({
      type: "IMAGE_POSITION",
      payload: data,
    });
  };
};

export const reorderData = (sourceIndex, destinationIndex) => {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SLIDES",
      payload: {
        sourceIndex,
        destinationIndex,
      },
    });
  };
};

export const changeSubtitle = (data) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_SUBTITLE",
      payload: data,
    });
  };
};
export const changeTitle = (data) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TITLE",
      payload: data,
    });
  };
};
export const changeDesc = (data) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_DESC",
      payload: data,
    });
  };
};
export const resetAdjust = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_ADJUST",
    });
  };
};
export const aiPostTopic = (data, setisLoading) => async (dispatch) => {
  await axiosInstance
    .post("ai-post/generate-carousel-from-topic", data)
    .then((res) => {
      dispatch({ type: "AI_TOPIC", payload: res });
      setisLoading(false);
    })
    .catch((err) => {
      dispatch({ type: "AI_TOPIC", payload: err });
      setisLoading(false);
    });
};

export const aiPostText = (data, setisLoading) => async (dispatch) => {
  await axiosInstance
    .post("ai-post/generate-carousel-from-text", data)
    .then((res) => {
      dispatch({ type: "AI_TEXT", payload: res });
      setisLoading(false);
    })
    .catch((err) => {
      dispatch({ type: "AI_TEXT", payload: err });
      setisLoading(false);
    });
};

export const aiPostArticle = (data, setisLoading) => async (dispatch) => {
  await axiosInstance
    .post("ai-post/generate-carousel-from-article", data)
    .then((res) => {
      dispatch({ type: "AI_ARTICLE", payload: res });
      setisLoading(false);
    })
    .catch((err) => {
      dispatch({ type: "AI_ARTICLE", payload: err });
      setisLoading(false);
    });
};