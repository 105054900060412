import { REHYDRATE } from "redux-persist";

const initialState = {
  totalLikes: "",
  totalComments: "",
  interactions: "",
  firstname: "",
  lastname: "",
  currentPosition: "",
  currentCompanyName: "",
  linkedinProfile: "",
  location: "",
  reaction: "",
  dailyPost: "",
  post: "",
  comments: "",
  impression: "",
  likesByCompany: "",
  likesByRegion: "",
  likesByOccupation: "",
  commentsByCompany: "",
  commentsByRegion: "",
  commentsByOccupation: "",
  team: [],
  userId: "",
  postPost: "",
  summary: null,
  dateRange: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(),
  }
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOTAL_LC": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          totalComments: action.payload.data.totalCommentsOnAllPosts,
          totalLikes: action.payload.data.totalLikesOnAllPosts,
          interactions: action.payload.data.interactions,
        };
      } else {
        return {
          ...state,
          totalComments: 0,
          totalLikes: 0,
          interactions: [],
        };
      }
    }
    case "PROFILE": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          currentPosition: action.payload.data.currentPosition,
          currentCompanyName: action.payload.data.currentCompanyName,
          linkedinProfile: action.payload.data.linkedinProfile,
          location: action.payload.data.location,
        };
      }
    }
    case "REACTION": {
      if (action.payload.data) {
        return {
          ...state,
          reaction: action.payload.data,
        };
      }
    }
    case "POST_TRACKER": {
      if (action.payload.data) {
        return {
          ...state,
          dailyPost: action.payload.data,
        };
      } else {
        return {
          ...state,
          dailyPost: {},
        };
      }
    }
    case "ALL_POST": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          post: action.payload.data,
        };
      }
    }
    case "ALL_COMMENTS": {
      if (action.payload.data) {
        return {
          ...state,
          comments: action.payload.data,
        };
      }
    }
    case "IMPRESSION": {
      console.log(action.payload);
      if (action.payload.data) {
        return {
          ...state,
          impression: action.payload.data,
        };
      }
    }
    case "POST_ANALYTICS": {
      console.log(action.payload.data);
      if (action.payload.data) {
        return {
          ...state,
          likesByCompany: action.payload.data.likesByCompany,
          likesByRegion: action.payload.data.likesByRegion,
          likesByOccupation: action.payload.data.likesByOccupation,
          commentsByCompany: action.payload.data.commentsByCompany,
          commentsByRegion: action.payload.data.commentsByRegion,
          commentsByOccupation: action.payload.data.commentsByOccupation,
          postPost: action.payload.data.post,
        };
      }
    }
    case "TEAM_MEMBERS": {
      console.log(action.payload.data);
      if (action.payload && action.payload.data) {
        return {
          ...state,
          team: action.payload.data,
        };
      }
      return state
    }
    case "SET_USER_ID": {
      console.log(action.payload);
      if (action.payload) {
        return {
          ...state,
          userId: action.payload,
        };
      }
    }
    case 'SET_POST_ANALYTICS_SUMMARY':
      return { ...state, postAnalyticsSummary: action.payload }
      case "SET_DATE_RANGE":
        return {
          ...state,
          dateRange: action.payload
        }  
    case "SET_ANALYTICS_DATA": 
        return {
            ...state,
            summary: action.payload.summary,
            posts: action.payload.posts,
            engagement: action.payload.engagement,
            dailyAnalytics: action.payload.dailyAnalytics,
            // You might want to update these as well, depending on your API response structure
            totalLikes: action.payload.summary.totalLikes,
            totalComments: action.payload.summary.totalComments,
            totalPosts:action.payload.summary.totalComments.postCount,
            post: action.payload.posts
          }
    case REHYDRATE:
      if (action.payload && action.payload.analytics) {
        return {
          ...state,
          ...action.payload.analytics,
        };
      }
      return state;

    default:
      return state;
  }
};

export default analyticsReducer;
