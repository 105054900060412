import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dailyPostTracker } from "../../store/features/analytics/analyticsAction";

const Calendar = () => {
  const [dates, setDates] = useState([]);
  const { isDark } = useSelector((state) => state.common);
  const { dailyPost, userId } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(dailyPostTracker(userData.linkedinId));
  }, [userId, dispatch, userData.linkedinId]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const endDate = new Date(currentYear, 11, 31);
    const datesArray = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      datesArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setDates(datesArray);
  }, []);

  const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1).getDay();

  const getPostCount = (date) => {
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return dailyPost[formattedDate] || 0;
  };

  const getBackgroundStyle = (postCount, isDark) => {
    if (postCount > 4) {
      return "linear-gradient(126.01deg, #8A51FF -9.26%, #39B2FF 79.86%)";
    } else if (postCount > 2) {
      return "linear-gradient(126.01deg, rgba(138, 81, 255, 0.5) -9.26%, rgba(57, 178, 255, 0.5) 79.86%)";
    } else if (postCount > 0) {
      return "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)";
    } else {
      return isDark ? "#11101040" : "#111010";
    }
  };

  return (
    <table className="calendar">
      <tbody className="max-md:flex max-md:justify-evenly">
        {weekdays.map((weekday, index) => (
          <tr key={index} className="max-md:flex max-md:flex-col max-md:w-[25px] max-md:gap-[15px] ">
            <th
              style={{
                backgroundColor: "#FFFFFF00",
                fontWeight: "100",
                color: isDark ? "black" : "white",
                textAlign: "left",
              }}
              className="w-[4%] text-xs"
            >
              {weekday}
            </th>
            {dates
              .filter((date) => date.getDay() === (index + firstDayOfYear) % 7)
              .map((date, dateIndex) => {
                const postCount = getPostCount(date);
                const isToday =
                  date.toDateString() === new Date().toDateString();
                return (
                  <td
                    key={dateIndex}
                    title={`${date.getDate()}-${
                      months[date.getMonth()]
                    }-${date.getFullYear()} , ${postCount}`}
                    style={{
                      background: isDark
                        ? postCount > 0 && postCount < 3
                          ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)"
                          : postCount > 2 && postCount < 5
                          ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.5) -9.26%, rgba(57, 178, 255, 0.5) 79.86%)"
                          : postCount > 4
                          ? "linear-gradient(126.01deg, #8A51FF -9.26%, #39B2FF 79.86%)"
                          : "#11101040"
                        : postCount > 0 && postCount < 3
                        ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.2) -9.26%, rgba(57, 178, 255, 0.2) 79.86%)"
                        : postCount > 2 && postCount < 5
                        ? "linear-gradient(126.01deg, rgba(138, 81, 255, 0.5) -9.26%, rgba(57, 178, 255, 0.5) 79.86%)"
                        : postCount > 4
                        ? "linear-gradient(126.01deg, #8A51FF -9.26%, #39B2FF 79.86%)"
                        : "#111010",
                    }}
                    className="max-md:h-[30px]"
                  >
                    {/* {`${date.getDate()} ${months[date.getMonth()]}`} */}
                  </td>
                );
              })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Calendar;
