import React, { useEffect, useState } from "react";
import { getApi } from "../../api/methods";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserInfo } from "../../store/features/user/userSlice";
import "./style.css";
import { toast } from "react-toastify";
const DefineTopics = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [getPostCate, setGetPostCate] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isEnable, setIsEnable] = useState(false);
  const [newItemName, setNewItemName] = useState("");

  useEffect(() => {
    // Check if props.getUserTopics.topics is not null and has a length greater than 0
    if (props.getUserTopics?.topics?.length > 0) {
      // Set selected items to props.getUserTopics.topics
      setSelectedItems(props.getUserTopics.topics);
    }
  }, []);

  useEffect(() => {
    // Update setUserTopics when selectedItems change
    props.setUserTpoics((prev) => {
      return {
        ...prev,
        loggeduserId: user.userData.linkedinId,
        topics: selectedItems,
      };
    });
  }, [selectedItems]);

  // here handle the select topics of user and make sure it does contain duplicate value
  const handleItemClick = (item) => {
    const isDuplicate = selectedItems.some(
      (selectedItem) => selectedItem === item.name
    );

    if (!isDuplicate) {
      // console.log('Adding:', item.name);
      setSelectedItems([...selectedItems, item.name]);
    } else {
      toast.warning(`Already added "${item.name}" try different one`);
    }
  };

  // remove item from selected topics
  const handleRemoveItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };
  const handleAddItem = () => {
    if (newItemName.trim() !== "") {
      const isDuplicate = selectedItems.some(
        (selectedItem) => selectedItem === newItemName
      );

      if (!isDuplicate) {
        setSelectedItems([...selectedItems, newItemName]);

        setNewItemName("");
        setIsEnable(false);
      } else {
        toast.warning(`Already added "${newItemName}" try a different one`);
      }
    }
  };

  const getPostCategory = () => {
    getApi(`otg-category/get-post-category`)
      .then((res) => {
        setGetPostCate(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    // fetch userinfo that is saved in the database
    // dispatch(fetchUserInfo())

    // fetch suggested category topics
    getPostCategory();
  }, []);

  // when user input a topic and press enter to save and contain topics must be under 6
  const handleUserInput = (e) => {
    if (e.keyCode == 13) {
      if (selectedItems?.length >= 5) {
        toast.warning("Choose topics upto 5 only!");
      } else {
        // add a new topic to state of no duplicate topics
        handleAddItem();
      }
    }
  };

  return (
    <>
      <div className="container container-margin-topic">
        <div className="row">
          <div className="col-12">
            <p className="P1" style={{ color: "rgb(105,115,129)" }}>
              Configure your personal AI
            </p>
            <h2 className="mb-8 mt-8 H2">Define your topics</h2>
            <p
              className="mb-4 P1"
              style={{ color: "rgb(105,115,129)", fontSize: "13px" }}
            >
              What do you want to post about? (Upto 5)
            </p>
          </div>
          <div className="col-12 mb-5">
            <div className="chosen-item-container">
              {/* <p className={`mb-0 ${selectedItems.length>0 ? 'mb-3':''} ${isEnable?'mb-3':''} `}>Type in or select your topics from suggestions</p> */}
              {/* <div className={isEnable ? 'isshow' : "ishide"} > */}
              <div>
                <div
                  className={`input-group ${
                    selectedItems?.length > 0 && "mb-3"
                  } border-1 border-black rounded-sm`}
                >
                  <input
                    type="text"
                    className="form-select first-input-box"
                    list="suggest-topics"
                    value={newItemName}
                    onChange={(e) => setNewItemName(e.target.value)}
                    onKeyDown={(e) => handleUserInput(e)}
                    placeholder="Type in or select your topics from suggestions"
                    autoFocus
                  />
                  <datalist id="suggest-topics" className="w-100">
                    {getPostCate?.map((item, index) => {
                      return <option value={item.name} key={index} />;
                    })}
                  </datalist>
                </div>
              </div>
              {
                selectedItems?.length > 0 ? (
                  <ul type="none">
                    {selectedItems?.map((item, index) => {
                      return (
                        <li key={index} className="mb-2">
                          {item}{" "}
                          <sup onClick={() => handleRemoveItem(index)}>x</sup>{" "}
                        </li>
                      );
                    })}
                    {/* <span className='add-item' onClick={() => setIsEnable(!isEnable)} ><img src="/images/addicon.svg" alt="" /></span> */}
                  </ul>
                ) : (
                  ""
                )
                // <span className='add-item1' onClick={() => setIsEnable(!isEnable)} ><img src="/images/downarrow.svg" alt="" /></span>
              }
            </div>
          </div>
          <div className="col-12 text-end btn-class">
            {selectedItems?.length > 0 && selectedItems?.length < 6 && (
              <button
                className="text-white"
                onClick={props.click}
                disabled={selectedItems?.length <= 0}
              >
                Next
              </button>
            )}
          </div>
          <div className="col-12">
            <div className="suggestions">
              <h4 className="mb-4 mt-2">Suggestions</h4>
              <ul type="none">
                {getPostCate?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleItemClick(item)}
                      className="m-1"
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefineTopics;
