const initialState = {
  images: "",
  keyWord: "",
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PIXABAY_IMAGE": {
      if (action.payload.data.hits) {
        return { images: action.payload.data.hits, keyWord: "Pixabay" };
      }
    }
    case "PEXELS_IMAGE": {
      if (action.payload.data.photos) {
        return { images: action.payload.data.photos, keyWord: "Pexels" };
      }
    }
    case "UNSPLASH_IMAGE": {
      if (action.payload.data.results) {
        return { images: action.payload.data.results, keyWord: "Unsplash" };
      }
    }
    case "CLEAR_IMAGES":
      return {
        ...state,
        images:[],
      };
    default:
      return state;
  }
};

export default mediaReducer;  
